import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: theme.spacing(),
      border: "0.5px solid #ddd",
      "& img": {
        margin: 0,
        height: "100px",
        display: "block",
      },
    },
    list: {
      display: "flex",
      listStyleType: "none",
    },
    image: {
      margin: "0.5rem",
      maxHeight: "10rem",
    },
  }),
  { name: "RaImageField" }
);
