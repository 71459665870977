import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useInput, useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(0.5),
  },
}));

const CheckboxInput = ({
  label,
  className,
  disabled,
  fullWidth,
  source,
  name,
  validate,
}) => {
  const classes = useStyles();

  const translate = useTranslate();
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    name,
    source,
    label,
    className,
    disabled,
    fullWidth,
    validate,
    type: "checkbox",
  });

  const handleOnChange = (e) => {
    const checked = e.target.checked;

    input.onChange(checked || undefined);
  };

  const inputError = touched && error;

  return (
    <FormControl
      error={!!inputError}
      disabled={disabled}
      required={isRequired}
      fullWidth={fullWidth}
      className={classNames(classes.formControl, { [className]: !!className })}
    >
      <FormControlLabel
        control={<Checkbox {...input} onChange={handleOnChange} id={id} />}
        label={
          typeof label === "string" ? translate(label, { _: label }) : label
        }
      />
      {inputError && <FormHelperText>{translate(inputError)}</FormHelperText>}
    </FormControl>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default CheckboxInput;
