import makeStyles from "@material-ui/core/styles/makeStyles";

export const useMoreInfoButtonStyles = makeStyles((theme) => ({
  root: {
    minWidth: "unset",
  },
  label: {
    whiteSpace: "noWrap",
    "& svg + span": {
      marginLeft: theme.spacing(),
    },
  },
}));
