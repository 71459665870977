import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React from "react";
import { useTranslate } from "react-admin";
import { useDispatch } from "react-redux";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import {
  update_pause_state_uploader_image,
  remove_uploader_images,
} from "../../../../../redux/actions/uploader";
import { formatFileSize } from "../../../../../utils";
import { useStyles } from "./styles";

const PhotoBlock = ({ image, imagesKey, index }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <div className={classes.container}>
      {imagesKey === "processingImages" && (
        <div className={classes.loading}>
          <CircularProgress
            variant="static"
            value={Math.round(
              (image.fragmentProcess / image.totalFragments) * 100
            )}
            size={16}
          />
        </div>
      )}
      <div className={classes.image}>
        <img src={image.preview} alt={image.name} title={image.name} />
      </div>
      <div className={classes.text}>
        <Typography noWrap>{image.name}</Typography>
        {image.title && (
          <Typography color="textSecondary" noWrap>
            {translate("commons.title")}: {image.title}
          </Typography>
        )}
        <Typography color="textSecondary" noWrap>
          {translate("commons.size")}: {formatFileSize(image.image.size)}
        </Typography>
      </div>
      {(imagesKey === "processingImages" || imagesKey === "failedImages") && (
        <div
          className={classes.button}
          onClick={() =>
            dispatch(update_pause_state_uploader_image(imagesKey, index))
          }
        >
          {image.paused || imagesKey === "failedImages" ? (
            <PlayArrowIcon />
          ) : (
            <PauseIcon />
          )}
        </div>
      )}
      <div
        className={classes.button}
        onClick={() => dispatch(remove_uploader_images(imagesKey, index))}
      >
        <Close color="inherit" />
      </div>
    </div>
  );
};

PhotoBlock.propTypes = {
  image: PropTypes.object,
  imagesKey: PropTypes.string,
  index: PropTypes.number,
};

export default PhotoBlock;
