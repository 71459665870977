import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => {
    return {
      mainContainer: {
        flex: "1 0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "50px 20px",
        [theme.breakpoints.up("sm")]: {
          "& form": {
            padding: theme.spacing(0, 2),
          },
        },
      },
      displayNone: {
        display: "none",
      },
      card: {
        width: "100%",
        maxWidth: "600px",
        borderLeft: `${theme.spacing()}px solid ${theme.palette.primary.main}`,
        textAlign: "center",
      },
      passwordActions: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(),
        "& a": {
          cursor: "pointer",
        },
      },
      buttons: {
        margin: "0 auto",
        textAlign: "center",
        paddingTop: theme.spacing(2),
        "& + &": {
          paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.up("sm")]: {
          "& .MuiButton-root": {
            fontSize: "16px",
          },
        },
      },
      loginButtons: {
        maxWidth: "265px",
      },
      checkbox: {
        "& .MuiTypography-body1": {
          fontSize: "12px",
        },
        [theme.breakpoints.up("sm")]: {
          "& .MuiTypography-body1": {
            fontSize: "16px",
          },
        },
      },
      actionWrapper: {
        "& .MuiButton-root": {
          "& + .MuiButton-root": {
            marginLeft: theme.spacing(2),
          },
        },
        [theme.breakpoints.up("sm")]: {
          "& .MuiButton-root": {
            minWidth: "130px",
          },
        },
      },
      localeContainer: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: 10,
      },
      termsLink: {
        color: theme.palette.primary.main,
        "&:hover": {
          textDecoration: "underline",
        },
      },
      termsContent: {
        lineHeight: 1.5,
        "& li": {
          marginTop: theme.spacing(2),
        },
        "& > .terms-modal-title": {
          padding: theme.spacing(0, 4, 2),
          fontSize: 24,
          textAlign: "center",
        },
      },
      termsModalClose: {
        position: "absolute",
        top: 0,
        right: 0,
      },
      logo: {
        height: 36,
        margin: theme.spacing(2),
      },
    };
  },
  { name: "WBOLogin" }
);
