import { Input, makeStyles, useMediaQuery } from "@material-ui/core";
import FirstPageRounded from "@material-ui/icons/FirstPageRounded";
import LastPageRounded from "@material-ui/icons/LastPageRounded";
import NavigateBeforeRounded from "@material-ui/icons/NavigateBeforeRounded";
import NavigateNextRounded from "@material-ui/icons/NavigateNextRounded";
import classNames from "classnames";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  ITEMS_PER_PAGE,
  ITEMS_PER_PAGE_DEFAULT,
} from "../../../../../config/constants";
import styles from "./styles";

const useStyles = makeStyles(styles, { name: "WBOPaginationControls" });

const PaginationControls = ({ total, page, perPage, setPage }) => {
  const [localPage, setLocalPage] = useState(page);
  const [valueInput, setValueInput] = useState(page);
  const [valueError, setValueError] = useState(false);

  const classes = useStyles();
  const realPerPage = ITEMS_PER_PAGE.includes(perPage)
    ? perPage
    : ITEMS_PER_PAGE_DEFAULT;
  const totalPages = Math.ceil(total / realPerPage);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  useEffect(() => {
    if (page !== valueInput) {
      setValueInput(page);
      setValueError(false);
    }
    if (page !== localPage) {
      setLocalPage(page);
    }
    // eslint-disable-next-line
  }, [page]);

  const getPreviusItems = () => {
    const items = [];
    if (!isMobile) {
      if (localPage - 3 > 0) {
        items.push(localPage - 3);
      }
      if (localPage - 2 > 0) {
        items.push(localPage - 2);
      }
    }
    if (localPage - 1 > 0) {
      items.push(localPage - 1);
    }

    return (
      <div className={classes.previusItems}>
        {items.map((value) => (
          <span
            onClick={() => handleChangePage(value)}
            className={classes.pageItem}
            key={value}
          >
            {value}
          </span>
        ))}
      </div>
    );
  };

  const getNextsItems = () => {
    let showLastPage;
    const items = [];

    if (isMobile) {
      showLastPage = localPage + 2 <= totalPages ? true : false;
    } else {
      showLastPage = localPage + 4 <= totalPages ? true : false;
    }
    if (localPage + 1 <= totalPages) {
      items.push(localPage + 1);
    }

    if (!isMobile) {
      if (localPage + 2 <= totalPages) {
        items.push(localPage + 2);
      }
      if (localPage + 3 <= totalPages) {
        items.push(localPage + 3);
      }
    }
    return (
      <div className={classes.nextItems}>
        {items.map((value) => (
          <span
            onClick={() => handleChangePage(value)}
            className={classes.pageItem}
            key={value}
          >
            {value}
          </span>
        ))}
        {showLastPage && (
          <>
            <span>...</span>
            <span
              onClick={() => handleChangePage(totalPages)}
              className={classes.pageItem}
            >
              {totalPages}
            </span>
          </>
        )}
      </div>
    );
  };

  const handleChangePage = useCallback(
    (newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const debouncedHandleChangePage = useMemo(
    () => _debounce(handleChangePage, 800),
    [handleChangePage]
  );

  const handleChangeInput = (value) => {
    if (!isNaN(value)) {
      setValueInput(value);
      if (value > 0 && value <= totalPages) {
        if (localPage !== parseInt(value)) {
          debouncedHandleChangePage(value);
        } else if (valueError) {
          setValueError(false);
        }
      } else {
        if (value !== localPage) {
          setValueError(true);
        }
      }
    }
  };

  const handleClick = (newPage) => {
    if (newPage !== localPage && newPage > 0 && newPage <= totalPages) {
      setLocalPage(newPage);
      setValueInput(newPage);
      debouncedHandleChangePage(newPage);
    }
  };

  return (
    <div className={classes.boxControls}>
      <span
        onClick={() => handleClick(1)}
        className={classNames(classes.btnPag, "prev", {
          disabled: localPage === 1,
        })}
      >
        <FirstPageRounded />
      </span>
      <span
        onClick={() => handleClick(localPage - 1)}
        className={classNames(classes.btnPag, "prev", {
          disabled: localPage === 1,
        })}
      >
        <NavigateBeforeRounded />
      </span>
      {getPreviusItems()}
      <Input
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
        inputProps={{ size: totalPages.toString().length }}
        className={classNames(classes.inputPage, {
          [classes.inputError]: valueError,
        })}
        value={valueInput}
        onChange={(e) => handleChangeInput(e.target.value)}
      />
      {getNextsItems()}
      <div className={classes.itemsPage}></div>
      <span
        onClick={() => handleClick(localPage + 1)}
        className={classNames(classes.btnPag, "next", {
          disabled: localPage === totalPages,
        })}
      >
        <NavigateNextRounded />
      </span>
      <span
        onClick={() => handleClick(totalPages)}
        className={classNames(classes.btnPag, "next", {
          disabled: localPage === totalPages,
        })}
      >
        <LastPageRounded />
      </span>
    </div>
  );
};

PaginationControls.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  setPage: PropTypes.func,
};

export default PaginationControls;
