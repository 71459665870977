import makeStyles from "@material-ui/core/styles/makeStyles";

export const useDrawerStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    maxWidth: "542px",
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
}));

export const useDrawerContentStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    position: "relative",
    borderBottom: `${theme.spacing(0.25)}px solid #E0E0E0`,
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: "40px",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    "& h3": {
      margin: 0,
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: 500,
    },
  },
  closeButton: {
    minWidth: "unset",
    width: "40px",
    height: "40px",
    borderRadius: "100%",
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  content: {
    "& img": {
      width: "100%",
      marginBottom: theme.spacing(),
    },
  },
  text: {
    marginBottom: theme.spacing(),
    "& h6": {
      fontSize: "16px",
      fontWeight: 600,
    },
  },
  table: {
    width: "100%",
    "& th,& td": {
      padding: theme.spacing(),
      borderBottom: "1px solid black",
      "&:first-child": {
        width: "150px",
      },
    },
  },
  url: {
    wordBreak: "break-all",
  },
}));
