import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { dataProvider } from "../../providers";
import { formatMetadatas, toBase64 } from "../../utils";
import {
  ADD_NEW_UPLOADER_IMAGES,
  REMOVE_UPLOADER_IMAGES,
  START_UPLOAD_UPLOADER_IMAGE,
  start_upload_uploader_image,
  START_UPLOAD_UPLOADER_IMAGES,
  start_upload_uploader_images,
  UPDATE_PAUSE_STATE_UPLOADER_IMAGE,
  update_uploader_images,
  UPLOAD_FRAGMENT_UPLOADER_IMAGES,
  upload_fragment_uploader_images,
} from "../actions/uploader";

function* removeImages(action) {
  const { imagesKey, index } = action.payload;
  const images = yield select((state) => state.uploader[imagesKey]);

  if (index || index === 0) {
    images[index].paused = true;
    images[index].remove = true;

    images.splice(index, 1);
    yield put(update_uploader_images({ [imagesKey]: [...images] }));
  } else {
    images.forEach((image) => {
      image.paused = true;
      image.remove = true;
    });
    yield put(update_uploader_images({ [imagesKey]: [] }));
  }
}

function* addNewUploaderImages(action) {
  const queueImages = yield select((state) => state.uploader.queueImages);
  const {
    images,
    formData: { title, type, ...formData },
  } = action.payload;

  let newImages = [...queueImages];
  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    let base64 = yield call(toBase64, image.image);
    const size = 131000;
    const fragments = [];

    for (let i = 0; i <= Math.ceil(base64.length / size) - 1; i++) {
      const fragment = base64.slice(size * i, size * i + size);
      fragments.push(fragment);
    }

    image.fragments = fragments;
    image.paused = false;
    image.fragmentProcess = 0;
    image.totalFragments = fragments.length;
    image.formData = formatMetadatas(formData);
    image.title = title;
    image.id = null;
    image.errors = 0;
    newImages.push(image);
  }

  yield put(update_uploader_images({ queueImages: [...newImages] }));
}

function* startUploadImages() {
  const queueImages = yield select((state) => state.uploader.queueImages);
  const processingImages = yield select(
    (state) => state.uploader.processingImages
  );

  let image = processingImages[0];
  if (processingImages.length === 0 && queueImages.length > 0) {
    image = queueImages.shift();
    yield put(
      update_uploader_images({
        processingImages: [...processingImages, image],
        queueImages: [...queueImages],
      })
    );
  }

  if (image) {
    yield put(start_upload_uploader_image());
  }
}

function* startUploadImage() {
  const processingImages = yield select(
    (state) => state.uploader.processingImages
  );
  let image = processingImages[0];

  try {
    if (!image.id) {
      const {
        data: { id },
      } = yield call(dataProvider.create, "chunk-images", {
        pagination: {},
        sort: {},
        data: {
          metadatas: image.formData,
          title: image.title,
          name: image.image.name,
          total: image.totalFragments,
        },
      });
      image.id = id;
      yield put(update_uploader_images({ processingImages: [image] }));
    }

    if (image) {
      yield put(upload_fragment_uploader_images());
    }
  } catch (error) {
    const failedImages = yield select((state) => state.uploader.failedImages);
    image.errors = 0;
    image.paused = true;
    yield put(
      update_uploader_images({
        processingImages: [],
        failedImages: [...failedImages, image],
      })
    );
    yield put(start_upload_uploader_images());
  }
}

function* uploadFragment() {
  const processingImages = yield select(
    (state) => state.uploader.processingImages
  );
  const readyImages = yield select((state) => state.uploader.readyImages);
  const image = processingImages[0];

  if (image.remove) {
    const queueImages = yield select((state) => state.uploader.queueImages);
    let newProcessingImages = [];
    if (queueImages.length > 0) {
      newProcessingImages.push(queueImages.shift());
    }
    yield put(
      update_uploader_images({
        processingImages: newProcessingImages,
        queueImages,
      })
    );

    if (newProcessingImages.length > 0) {
      yield put(start_upload_uploader_images());
    }
  } else if (!image.paused) {
    const fragment = image.fragments[image.fragmentProcess];
    try {
      const result = yield call(dataProvider.patch, "chunk-images", {
        pagination: {},
        sort: {},
        id: image.id,
        data: {
          chunks: [{ slice: fragment, position: image.fragmentProcess }],
        },
      });
      if (result.data) {
        image.fragmentProcess += 1;
        image.errors = 0;
        yield put(update_uploader_images({ processingImages: [image] }));
        if (image.fragmentProcess < image.totalFragments) {
          yield put(upload_fragment_uploader_images());
        } else {
          yield put(
            update_uploader_images({
              processingImages: [],
              readyImages: [...readyImages, image],
            })
          );
          yield put(start_upload_uploader_images());
        }
      } else {
        throw Error;
      }
    } catch (error) {
      image.errors++;
      yield put(update_uploader_images({ processingImages: [image] }));
      if (image.errors < 5) {
        yield put(upload_fragment_uploader_images());
      } else {
        const failedImages = yield select(
          (state) => state.uploader.failedImages
        );
        image.errors = 0;
        image.paused = true;
        yield put(
          update_uploader_images({
            processingImages: [],
            failedImages: [...failedImages, image],
          })
        );
        yield put(start_upload_uploader_images());
      }
    }
  }
}

function* updatePauseStateUploaderImage(action) {
  const { imagesKey, index } = action.payload;
  const images = yield select((state) => state.uploader[imagesKey]);
  images[index].paused = !images[index].paused;

  yield put(update_uploader_images({ [imagesKey]: [...images] }));

  if (imagesKey === "failedImages") {
    const image = images[index];
    images.splice(index, 1);
    const processingImages = yield select(
      (state) => state.uploader.processingImages
    );
    const queueImages = yield select((state) => state.uploader.queueImages);
    const shouldStartUpload = processingImages.length === 0;

    yield put(
      update_uploader_images({
        queueImages: [image, ...queueImages],
        failedImages: [...images],
      })
    );

    if (shouldStartUpload) {
      yield put(start_upload_uploader_images());
    }
  } else if (!images[index].paused) {
    yield put(start_upload_uploader_images());
  }
}

export default function* rootSaga() {
  return yield all([
    takeEvery(REMOVE_UPLOADER_IMAGES, removeImages),
    takeEvery(ADD_NEW_UPLOADER_IMAGES, addNewUploaderImages),
    takeEvery(START_UPLOAD_UPLOADER_IMAGES, startUploadImages),
    takeEvery(START_UPLOAD_UPLOADER_IMAGE, startUploadImage),
    takeEvery(UPDATE_PAUSE_STATE_UPLOADER_IMAGE, updatePauseStateUploaderImage),
    takeEvery(UPLOAD_FRAGMENT_UPLOADER_IMAGES, uploadFragment),
  ]);
}
