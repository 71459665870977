import { CHANGE_LANGUAGE } from "../actions/language";
import { setUserConfig } from "../../utils/userConfig";

export const languageReducer = (state = "es", action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE: {
      if (action.payload) {
        setUserConfig(action.payload, "language");
        return action.payload;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
