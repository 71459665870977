import { fetchUtils } from "react-admin";
import { getToken } from "./authProviderToken";
import { getUserConfig } from "../utils/userConfig";

const httpClient = (url, options = {}) => {
  let myOptions = { ...options };
  const token = getToken();
  myOptions.headers = new Headers({
    Accept: "application/ld+json",
    Authorization: "Bearer " + token,
    "Content-Language": getUserConfig("language") || "en",
    ...options.headers,
  });

  return fetchUtils.fetchJson(url, myOptions).catch((err) => err);
};

export default httpClient;
