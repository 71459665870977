import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  inputBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(0.5),
    "& label": {
      transform: "translate(0, 1.5px) scale(0.75)",
      margin: "0",
      transition:
        "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      "&:not(.Mui-focused)": {
        opacity: theme.palette.type === "dark" ? 0.7 : 0.54,
      },
    },
  },
  itemsList: {
    position: "absolute",
    top: "58px",
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
    listStyle: "none",
    margin: 0,
    padding: 0,
    zIndex: 99,
    boxShadow: theme.shadows[8],
    maxHeight: "400px",
    overflowY: "auto",
    color: theme.palette.common.black,
  },
  inputWrapper: {
    position: "relative",
    "& svg": {
      fontSize: "22px",
      color: theme.palette.text.secondary,
    },
  },
  item: {
    padding: theme.spacing(1, 2),
    cursor: "pointer",
  },
  selectedItems: {
    marginLeft: `-${theme.spacing()}px`,
    display: "flex",
    flexWrap: "wrap",
  },
  selectedItem: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(),
    backgroundColor: "#ddd",
    borderRadius: theme.spacing(2),
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    transition: "background-color 0.3s",
    userSelect: "none",
    "&:hover": {
      backgroundColor: "#ccc",
    },
    "& svg": {
      marginLeft: theme.spacing(),
      fontSize: "1rem",
    },
  },
  linearProgress: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "3px",
  },
}));
