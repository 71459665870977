import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import {
  Button,
  IconButton,
  ButtonGroup,
  useMediaQuery,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import React, { useEffect, useCallback } from "react";
import { useTranslate, refreshView, useListContext } from "react-admin";
import { useDispatch, useSelector } from "react-redux";

import { useStyles } from "./styles";
import { BulkActionsMenuButton } from "../../../../components/buttons";

export default ({
  filtersValues,
  setfiltersValues,
  data,
  selectedIds,
  selectAll,
  deselectAll,
  onSelect,
  children,
  ...rest
}) => {
  const listContext = useListContext();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const loading = useSelector((state) => state.admin.loading);

  useEffect(() => {
    deselectAll();
    return () => deselectAll();
    // eslint-disable-next-line
  }, [filtersValues]);

  const deselectTagged = useCallback(() => {
    const newSelectedIds = selectedIds.filter((id) => !data[id].tagged);
    onSelect(newSelectedIds);
  }, [data, selectedIds, onSelect]);

  const onTaggedSwitchChange = useCallback(
    (e) => {
      const addTagged = e.target.checked;
      const newFiltersValues = { ...filtersValues };

      if (addTagged) {
        delete newFiltersValues.tagged;
      } else {
        newFiltersValues.tagged = false;
        deselectTagged();
      }

      setfiltersValues(newFiltersValues);
    },
    [filtersValues, setfiltersValues, deselectTagged]
  );

  const onRefreshClick = useCallback(() => {
    deselectTagged();
    dispatch(refreshView());
  }, [dispatch, deselectTagged]);

  return (
    <div className={classes.root}>
      {children}
      <div className={classes.buttonPanel}>
        <div className={classes.actionsLeft}>
          <IconButton
            onClick={onRefreshClick}
            color="primary"
            aria-label="refrest"
            edge="start"
            size="small"
          >
            <RefreshRoundedIcon fontSize="small" />
          </IconButton>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={onTaggedSwitchChange}
                disabled={!!loading}
              />
            }
            // label={translate("resource.uploader.show_tagged")}
            label={<CheckCircleSharpIcon color="primary" fontSize="small" />}
            labelPlacement="start"
            className={classes.showTaggedRoot}
          />
          <div className={classes.textResult}>
            {translate("components.filters.result_count", {
              smart_count: listContext.total,
            })}
          </div>
        </div>
        {isDesktop ? (
          <ButtonGroup variant="contained" color="primary" size="small">
            <Button onClick={selectAll} variant="contained" color="primary">
              {translate("commons.select_all")}
            </Button>
            <Button
              onClick={deselectAll}
              disabled={selectedIds.length === 0}
              color="primary"
            >
              {translate("commons.deselect_count", {
                count: selectedIds.length,
              })}
            </Button>
          </ButtonGroup>
        ) : (
          <BulkActionsMenuButton
            actions={[]}
            selectAll={selectAll}
            deselectAll={deselectAll}
          />
        )}
      </div>
    </div>
  );
};
