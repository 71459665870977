import React from "react";

export default {
  en: (
    <>
      <div className="terms-modal-title">Access requested</div>
      <p>
        Access to the Multimedia Panel application has been requested, it will
        be reviewed by LALIGA, and you will be notified via email when the
        request is approved.
      </p>
    </>
  ),
  es: (
    <>
      <div className="terms-modal-title">Acceso solicitado</div>
      <p>
        Se ha solicitado el acceso a la aplicación Panel Multimedia, será
        revisada por LALIGA, y se le notificará vía correo electrónico cuando se
        apruebe la solicitud.
      </p>
    </>
  ),
};
