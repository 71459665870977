import React from "react";
import { Authenticated } from "react-admin";
import { Route } from "react-router-dom";
import LoginFailure from "../pages/loginFailure";
import Register from "../pages/register";

import { checkPermission } from "./roles";

const customRoutesConfig = {
  [`login-failure`]: {
    path: "/login/failure",
    component: LoginFailure,
    noLayout: true,
    noAuth: true,
  },
  [`register`]: {
    path: "/register",
    component: Register,
    noLayout: true,
    noAuth: true,
  },
};

export const customRoutes = Object.keys(customRoutesConfig).map((item) => {
  const Component = customRoutesConfig[item].component;

  let render = () => <Component />;

  if (!customRoutesConfig[item].noAuth) {
    if (checkPermission(item)) {
      render = () => (
        <Authenticated>
          <Component />
        </Authenticated>
      );
    } else {
      render = () => <div></div>;
    }
  }

  return (
    <Route
      exact
      path={customRoutesConfig[item].path}
      render={render}
      noLayout={customRoutesConfig[item].noLayout}
    />
  );
});

export default customRoutesConfig;
