import {
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import Downshift from "downshift";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useChoices,
  useDataProvider,
  useInput,
  useTranslate,
} from "react-admin";

import { IS } from "../../../utils";
import { useStyles } from "./styles";

const AutoCompleteMultipleInput = ({
  optionText,
  optionValue,
  searchResource,
  searchProperty,
  translateChoice,
  choices: propChoices,
  ...rest
}) => {
  const [value, setValue] = useState(rest.value || "");
  const [choices, setChoices] = useState(propChoices || []);
  const [selectedItems, setSelectedItems] = useState([]);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const mountedRef = useRef(true);
  const inputRef = useRef();

  let { disabled, label, name, source } = rest;
  name = name || source;
  label =
    (label && translate(label, { _: label })) ||
    (name && translate(name, { _: name }));

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({ ...rest, name });

  const inputError = touched && error;

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  useEffect(() => {
    if (input.value) {
      if (!Array.isArray(input.value)) {
        input.value = [input.value];
      }

      if (propChoices) {
        const selectedItems = propChoices.filter((choice) =>
          input.value.includes(getChoiceValue(choice))
        );

        setSelectedItems(selectedItems);
      } else {
        if (input.value) {
          setLoading(true);
          dataProvider
            .getMany(searchResource, { ids: input.value })
            .then(({ data }) => {
              if (mountedRef.current) {
                setSelectedItems(data);
                setLoading(false);
              }
            });
        }
      }
    }

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!propChoices && Array.isArray(input.value) && input.value.length > 0) {
      setLoading(true);
      dataProvider
        .getMany(searchResource, { ids: input.value })
        .then(({ data }) => {
          if (mountedRef.current) {
            setSelectedItems(data);
            setLoading(false);
          }
        });
    }

    if (!(Array.isArray(input.value) && input.value.length > 0)) {
      setSelectedItems([]);
    }
    // eslint-disable-next-line
  }, [input.value]);

  const getChoicesValue = useCallback(
    (choices) => {
      return choices.map((choice) => getChoiceValue(choice));
    },
    [getChoiceValue]
  );

  const renderMenuItemOption = useCallback((choice) => getChoiceText(choice), [
    getChoiceText,
  ]);

  const searchValue = useCallback(
    (value) => {
      if (!propChoices) {
        setLoading(true);
        dataProvider
          .getList(searchResource, {
            sort: {},
            pagination: { page: 1, perPage: 10 },
            filter: { [searchProperty]: value },
          })
          .then((result) => {
            setChoices(result.data);
            setLoading(false);
          });
      }
    },
    [dataProvider, searchProperty, searchResource, propChoices]
  );
  const searchValueDebounced = useMemo(() => _debounce(searchValue, 300), [
    searchValue,
  ]);

  const onSelect = useCallback(
    (selectedItem) => {
      inputRef.current.blur();

      let newSelectedItems = [...selectedItems, selectedItem];
      setSelectedItems(newSelectedItems);
      setValue("");
      input.onChange(getChoicesValue(newSelectedItems));
    },
    [input, selectedItems, getChoicesValue]
  );

  const onInputValueChange = useCallback(
    (newValue) => {
      if (newValue !== "[object Object]") {
        if (value !== newValue) {
          setValue(newValue);

          if (newValue && newValue.length >= 3) searchValueDebounced(newValue);
        }
      }
    },
    [value, searchValueDebounced]
  );

  const onFocus = useCallback(() => {
    if (!focused) {
      setFocused(true);
    }

    if (input.onFocus) {
      input.onFocus();
    }
  }, [focused, input]);

  const onBlur = useCallback(() => {
    if (focused) {
      setFocused(false);
    }

    if (!propChoices) {
      setChoices([]);
    }

    if (input.onBlur) {
      input.onBlur();
    }
  }, [focused, input, propChoices]);

  const deleteItem = useCallback(
    (index) => {
      let newSelectedItems = [...selectedItems];
      newSelectedItems.splice(index, 1);
      setSelectedItems(newSelectedItems);
      input.onChange(getChoicesValue(newSelectedItems));
    },
    [selectedItems, getChoicesValue, input]
  );

  const getChoices = useCallback(
    ({ getItemProps, highlightedIndex }) => {
      let realChoices = choices.filter((item) => {
        for (const selectedItem of selectedItems) {
          if (
            getChoiceValue(selectedItem) === getChoiceValue(item) ||
            (propChoices && value && !getChoiceText(item).includes(value))
          ) {
            return false;
          }
        }

        if (
          propChoices &&
          value &&
          !getChoiceText(item).toLowerCase().includes(value.toLowerCase())
        ) {
          return false;
        }
        return true;
      });

      if (realChoices.length > 0) {
        return realChoices.map((item, index) => {
          const label = renderMenuItemOption(item);
          return (
            <ListItem
              key={getChoiceValue(item)}
              className={classes.item}
              title={label}
              {...getItemProps({
                key: getChoiceValue(item),
                index,
                item,
                style: {
                  backgroundColor:
                    highlightedIndex === index && "rgba(0, 0, 0, 0.08)",
                },
              })}
            >
              <Typography noWrap>{label}</Typography>
            </ListItem>
          );
        });
      } else {
        return [
          <ListItem className={classes.item} title={label}>
            <Typography noWrap>
              {translate("commons.no_coincidences")}
            </Typography>
          </ListItem>,
        ];
      }
    },
    [
      choices,
      translate,
      classes.item,
      getChoiceValue,
      getChoiceText,
      label,
      renderMenuItemOption,
      selectedItems,
      propChoices,
      value,
    ]
  );

  return (
    <Downshift
      onChange={onSelect}
      onInputValueChange={onInputValueChange}
      inputValue={!IS.number(value) ? value : ""}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        getRootProps,
      }) => (
        <div className={classes.inputBox}>
          <div>
            <FormControl
              {...getRootProps(
                {
                  className: classes.inputWrapper,
                  error: !!inputError,
                  disabled,
                  required: isRequired,
                },
                { suppressRefError: true }
              )}
            >
              {label && (
                <InputLabel htmlFor={input.name}>
                  {translate(label, { _: label })}
                </InputLabel>
              )}
              <TextField
                {...getInputProps({
                  id,
                  label,
                  name,
                  disabled,
                  style: { width: "100%" },
                  inputRef,
                  onFocus,
                  onBlur,
                  InputProps: {
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  },
                })}
              />
              {loading && <LinearProgress className={classes.linearProgress} />}
            </FormControl>
          </div>
          <div className={classes.selectedItems}>
            {selectedItems.map((item, index) => (
              <div
                key={getChoiceValue(item)}
                className={classes.selectedItem}
                onClick={() => deleteItem(index)}
              >
                {typeof optionText === "function"
                  ? optionText(item)
                  : item[optionText]}
                <Close />
              </div>
            ))}
          </div>
          {(isOpen || focused) && choices && choices.length > 0 && (
            <List className={classes.itemsList} {...getMenuProps()}>
              {getChoices({ getItemProps, highlightedIndex })}
            </List>
          )}
        </div>
      )}
    </Downshift>
  );
};

AutoCompleteMultipleInput.defaultProps = {
  onSelect: () => {},
  onChange: () => {},
  optionText: "name",
  optionValue: "id",
  selectedItems: [],
  translateChoice: true,
  value: "",
};

AutoCompleteMultipleInput.propTypes = {
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
  searchResource: PropTypes.string,
  searchProperty: PropTypes.string,
  translateChoice: PropTypes.bool,
};

export default AutoCompleteMultipleInput;
