import PropTypes from "prop-types";
import React from "react";
import { LinearProgress } from "@material-ui/core";

import AutoCompleteMultipleInput from "./multiple";
import AutoCompleteSingleInput from "./single";
import { useEffect } from "react";
import { useState } from "react";
import { useDataProvider } from "react-admin";

const AutoCompleteInput = ({
  multiple,
  choices: propChoices,
  offline,
  ...rest
}) => {
  const [choices, setChoices] = useState(propChoices);
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (offline && !choices) {
      dataProvider
        .getList(rest.searchResource, {
          sort: {
            field: "name",
            order: "ASC",
          },
        })
        .then(({ data }) => {
          setChoices(data);
        });
    }
  }, [offline, choices, dataProvider, rest.searchResource]);

  if (offline && !choices) {
    return <LinearProgress />;
  }

  if (multiple) {
    return <AutoCompleteMultipleInput choices={choices} {...rest} />;
  }
  return <AutoCompleteSingleInput choices={choices} {...rest} />;
};

AutoCompleteInput.defaultProps = {
  offline: false,
  multiple: false,
};

AutoCompleteInput.propTypes = {
  multiple: PropTypes.bool,
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
  searchResource: PropTypes.string,
  searchProperty: PropTypes.string,
  translateChoice: PropTypes.bool,
  offline: PropTypes.bool,
  choices: PropTypes.array,
};

export default AutoCompleteInput;
