import { Button } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { refreshView, useMutation, useNotify, useTranslate } from "react-admin";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Dialog from "../../../custom/Dialog";

const RecoverButton = ({ record, resource, recoverResource }) => {
  if (!recoverResource) recoverResource = resource;

  const dispatch = useDispatch();
  const notify = useNotify();
  const translate = useTranslate();

  const [isOpen, setOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback((e) => {
    setOpen(true);
    e.stopPropagation();
  }, []);

  const [recoverItem] = useMutation(
    {
      type: "delete",
      resource: recoverResource || resource,
      payload: {
        id: record.id,
        data: { trashed: false },
      },
    },
    {
      onSuccess: () => {
        dispatch(refreshView());
        notify(`resource.${resource}.notify.recovered`, "info");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const onConfirm = useCallback(() => {
    recoverItem();
    setOpen(false);
  }, [recoverItem]);

  return (
    <>
      <Button
        onClick={handleClick}
        size="small"
        variant="contained"
        color="primary"
      >
        {translate("commons.recover")}
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleDialogClose}
        onConfirm={onConfirm}
        dialogContent={
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: translate(
                  `resource.${resource}.dialog_recover.counter_html`,
                  {
                    smart_count: 1,
                  }
                ),
              }}
            ></p>
          </div>
        }
        cancelButton={<Button>{translate("commons.cancel")}</Button>}
        confirmButton={
          <Button variant="contained" color="primary">
            {translate("commons.recover")}
          </Button>
        }
      />
    </>
  );
};

RecoverButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  recoverResource: PropTypes.string,
};

export default RecoverButton;
