import React from "react";
import { useListContext, useTranslate } from "react-admin";

const ResultCount = (props) => {
  const listContext = useListContext();
  const translate = useTranslate();

  return (
    <div>
      {translate("components.filters.result_count", {
        smart_count: listContext.total,
      })}
    </div>
  );
};

export default ResultCount;
