import makeStyles from "@material-ui/core/styles/makeStyles";

export const useButtonStyles = makeStyles((theme) => ({
  disabled: {
    color: `${theme.palette.common.white}!important`,
    backgroundColor: "#BDBDBD!important",
  },
  label: {
    fontWeight: "bold",
  },
}));

export const useStyles = makeStyles(
  {
    hidden: {
      visibility: "hidden",
    },
    loading: {
      position: "absolute",
    },
  },
  {
    name: "WBODeleteButton",
  }
);
