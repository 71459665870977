import { CHANGE_GALLERY_FILTER_TYPE } from "../actions/filterTypeGallery";

export const filterTypeGalleryReducer = (state = "match", action) => {
  switch (action.type) {
    case CHANGE_GALLERY_FILTER_TYPE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
