import PropTypes from "prop-types";
import React from "react";
import { PasswordInput as RaPasswordInput } from "react-admin";

const PasswordInput = ({ variant = "standard", fullWidth = true, ...rest }) => (
  <RaPasswordInput variant={variant} fullWidth={fullWidth} {...rest} />
);

PasswordInput.propTypes = {
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default PasswordInput;
