import React from "react";

export default {
  en: (
    <>
      <div className="terms-modal-title">
        Conditions of use of the images of LALIGA.
      </div>
      <ol>
        <li>
          These terms and conditions (hereinafter, "<b>Terms of use</b>") govern
          the use of the images and/or photographs (hereinafter, "<b>Images</b>
          ") that LIGA NACIONAL DE FÚTBOL (hereinafter "<b>LALIGA</b>") puts at
          the disposal of the duly accredited users (hereinafter, "<b>User</b>"
          or "<b>Users</b>") that access to the repository of images of LALIGA.
        </li>
        <li>
          The User acknowledges that images:
          <ol type="i">
            <li>
              are the exclusive property of LALIGA or of third parties, whose
              rights as the case recognizes the user, and as such, which are
              subject to intellectual and industrial property rights protected
              by national and international law;
            </li>
            <li>
              they collect moments and snapshots of the matches of the Spanish
              National First and Second Division League Championship, so the
              exploitation in accordance with the terms described herein
              described can affect the rights to honour, personal and family
              privacy and self-image of the people.
            </li>
          </ol>
        </li>
        <li>
          Users can only access the images through the means or procedures that
          LALIGA has put at your disposal.
        </li>
        <li>
          By accepting these terms of use, LALIGA grants to the User a
          non-exclusive images, non-transferable, for global territorial and
          temporal indefinitely for the sole purpose of playing them, distribute
          them or communicate them publicly by any means with character
          exclusively informative and, in particular, as graphic information on
          matches of the Spanish National First and Second Division League
          Championship.
        </li>
        <li>
          Modalities of use or non-existent or unknown media at the time of the
          granting of the same are excluded from the scope of the license.
        </li>
        <li>
          The transformation or modification of the images as well as their use
          with any purpose other than the one mentioned in section 4, in
          special, promotional and/or commercial, requires obtaining prior
          written validation by the User of LALIGA’s Marketing Department.
        </li>
        <li>
          Users undertake to use the image in a diligent, legal, correct and
          lawful manner and, in particular, the User commits, without
          limitation, to refrain from:
          <ol type="i">
            <li>
              use the images in a manner and for purposes contrary to law,
              morality and generally accepted good customs or public order;
            </li>
            <li>
              infringe intellectual or industrial property rights belonging to
              LALIGA or third parties;
            </li>
            <li>
              use the images so it may lead to damage or overload for the
              operation of the website;
            </li>
            <li>
              use images for purposes other than those included in the present
              terms and conditions;
            </li>
            <li>
              transmit or distribute the images to any outside third party
              outside the scope of the license;
            </li>
            <li>
              use images that affect the right to self-image of people which
              have not obtained consent for exploitation, and/or
            </li>
            <li>
              breaching any other particular conditions imposed by LALIGA.
            </li>
          </ol>
        </li>
        <li>
          Likewise, the User will refrain from making any use of those images
          that contain close-ups or which allow to clearly recognise people
          different from players and technical team of the of the Spanish
          National First and Second Division League Championship (without
          limitation fans, safety team, ball boys).
          <br />
          In this regard, LALIGA will make its best efforts to remove such
          images in the repository, for which the User shall inform the LALIGA
          of the existence of such images when you have knowledge of this, via
          email.
        </li>
        <li>
          LALIGA is entitled to audit compliance by the User of the obligations
          set forth in these terms and conditions, and may in particular, check
          the exploitation of images in accordance with these terms of use.
        </li>
        <li>
          LALIGA will retain full ownership and domain rights on the images. For
          this purpose, LALIGA reserves the right to exploit, license or
          transfer images to third parties, not constituting these terms an
          exclusive license in favour of the User, being necessary the prior
          express consent and in writing by LALIGA if the User intends to
          convey, assign or sublicense, direct or indirectly in whole or in
          part, the rights and obligations that hereby are granted to the User
          for the exploitation of the images.
        </li>
        <li>
          The user agrees to leave unharmed to LALIGA for any damage, injury,
          expense (including, without limitation, Attorney's fees) civil
          liability, sanctions, or fines arising from any court order for claims
          filed against LALIGA, derived or related to the breach by the User of
          the obligations and guarantees provided in these terms of use or the
          applicable legislation.
        </li>
        <li>
          The interpretation of these terms of use are subject to Spanish
          legislation. To resolve any differences or discrepancies that may
          arise regarding the interpretation and/or enforcement of this
          document, both parties will be submitted, with renounce to the
          jurisdiction that may correspond to them, to the jurisdiction and
          competence of the courts of Madrid City.
        </li>
        <li>
          This Terms and Conditions are published both in English and Spanish.
          If there are any discrepancies or interpretation problems between the
          two versions, the Spanish version will prevail over the English
          version.
        </li>
      </ol>
    </>
  ),
  es: (
    <>
      <div className="terms-modal-title">
        Condiciones de uso de las imágenes de LALIGA.
      </div>
      <ol>
        <li>
          Los presentes términos y condiciones (en adelante, los "
          <b>Términos de Uso</b>") regulan el uso de las imágenes y/o
          fotografías (en adelante, las "<b>Imágenes</b>") que LIGA NACIONAL DE
          FÚTBOL PROFESIONAL (en adelante "<b>LALIGA</b>") pone a disposición de
          los usuarios debidamente acreditados (en adelante, el "<b>Usuario</b>"
          o "<b>Usuarios</b>") que accedan al repositorio de Imágenes de LALIGA.
        </li>
        <li>
          El Usuario reconoce que las Imágenes:
          <ol type="i">
            <li>
              son de la exclusiva propiedad de LALIGA o de terceros, cuyos
              derechos en su caso reconoce el Usuario, y como tal, que están
              sujetas a derechos de propiedad intelectual e industrial
              protegidos por la legislación nacional e internacional;
            </li>
            <li>
              recogen momentos e instantáneas de los partidos del campeonato
              nacional de liga profesional de primera división o de segunda
              división, por lo que su explotación conforme a los términos aquí
              descritos puede afectar a los derechos al honor, a la intimidad
              personal y familiar y a la propia imagen de las personas.
            </li>
          </ol>
        </li>
        <li>
          Los Usuarios únicamente podrán acceder a las Imágenes mediante los
          medios o procedimientos que LALIGA haya puesto a su disposición.
        </li>
        <li>
          Por medio de la aceptación de los presentes Términos de Uso, LALIGA
          otorga al Usuario una licencia no exclusiva sobre las Imágenes,
          intransferible, para el ámbito territorial mundial y con carácter
          temporal indefinido con la única finalidad de reproducirlas,
          distribuirlas y/o comunicarlas públicamente por cualquier medio con
          carácter exclusivamente informativo y, en particular, como información
          gráfica sobre los partidos del campeonato nacional de liga profesional
          de primera división o segunda división.
        </li>
        <li>
          Queda excluida del ámbito de la licencia las modalidades de
          utilización o medios de difusión inexistentes o desconocidos al tiempo
          del otorgamiento de la misma.
        </li>
        <li>
          La transformación o modificación de las Imágenes así como su
          utilización con cualquier tipo de finalidad diferente a la mencionada
          en el punto 4, en especial, promocional y/o comercial,{" "}
          <b>
            requerirá la previa obtención por parte del Usuario de la validación
            por escrito del departamento de Marketing de LALIGA.
          </b>
        </li>
        <li>
          Los Usuarios se obligan a usar las Imágenes de forma diligente, legal,
          correcta y lícita y, en particular, se comprometen a título meramente
          enunciativo y no exhaustivo a abstenerse de:
          <ol type="i">
            <li>
              utilizar las Imágenes de forma y con fines contrarios a la ley, a
              la moral y a las buenas costumbres use images for purposes other
              than those included in the present terms and conditions.aceptadas
              o al orden público;
            </li>
            <li>
              vulnerar derechos de propiedad intelectual o industrial
              pertenecientes a LALIGA o de terceros;
            </li>
            <li>
              utilizar las Imágenes de manera que pueda suponer un perjuicio o
              sobrecarga para el funcionamiento de la página web;
            </li>
            <li>
              utilizar las Imágenes para fines distintos a los recogidos en los
              presentes términos y condiciones.
            </li>
            <li>
              transmitir o distribuir las Imágenes a cualquier tercero ajeno
              fuera del ámbito de la licencia,
            </li>
            <li>
              utilizar Imágenes que afecten al derecho a la propia imagen de
              personas sobre las que no se hayan obtenido el consentimiento para
              su explotación, y/o
            </li>
            <li>
              incumplir cualquier otra condición particular impuesta por LALIGA.
            </li>
          </ol>
        </li>
        <li>
          Asimismo, el Usuario se abstendrá de hacer cualquier uso de aquellas
          Imágenes que contengan primeros planos o en las que se permita
          reconocer claramente a personas diferentes al colectivo de jugadores y
          equipo técnico de los equipos de primera y segunda división del
          campeonato nacional de liga profesional (a título enunciativo que no
          limitativo, aficionados, equipo de seguridad, recogepelotas…)
          <br />A este respecto, la LALIGA hará sus mejores esfuerzos para
          retirar este tipo de Imágenes del repositorio, para los cual el
          Usuario deberá informar a la LALIGA de la existencia de este tipo de
          Imágenes cuando tenga conocimiento de ello, a través del correo
          electrónico.
        </li>
        <li>
          LALIGA podrá auditar el cumplimiento por parte del Usuario de las
          obligaciones establecidas en los presentes términos y condiciones,
          pudiendo en concreto, verificar la explotación de las Imágenes
          conforme a los presentes Términos de Uso.
        </li>
        <li>
          LALIGA conservará la plena propiedad, dominio y derechos sobre las
          Imágenes. A estos efectos, LALIGA se reserva el derecho a explotar,
          licenciar y/o ceder las Imágenes a terceros, no constituyendo los
          presentes términos una licencia en exclusiva a favor del Usuario,
          siendo necesario contar con el previo consentimiento, expreso y por
          escrito de LALIGA en caso de que el Usuario tenga intención de
          transmitir, ceder o sublicenciar, de forma directa o indirecta, en
          todo o en parte, los derechos y obligaciones que aquí le son otorgados
          para la explotación de las Imágenes.
        </li>
        <li>
          El Usuario se compromete a dejar indemne a LALIGA por cualquier daño,
          perjuicio, gasto (incluyendo, sin limitación, honorarios de abogados)
          responsabilidad civil, sanciones o multas derivadas de cualquier orden
          jurisdiccional por reclamaciones incoadas contra LALIGA, que se
          deriven o guarden relación con el incumplimiento por parte del Usuario
          de las obligaciones y garantías dispuestas en los presentes Términos
          de Uso o en la normativa aplicable.
        </li>
        <li>
          La interpretación de los presentes Términos de Uso se someten
          legislación española. Para resolver cuantas divergencias o
          discrepancias que pudieran surgir en relación con la interpretación
          y/o cumplimiento del presente documento, ambas partes se someterán con
          renuncia al fuero que pudiera corresponderles, a la jurisdicción y
          competencia de los Juzgados de la ciudad Madrid.
        </li>
        <li>
          Estas Condiciones de Uso se publican en Español e Inglés. En caso de
          discrepancias o problemas de interpretación entre ambas versiones, la
          versión Española prevalecerá sobre la versión Inglesa.
        </li>
      </ol>
    </>
  ),
};
