import {
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReferenceField, useMutation, useTranslate } from "react-admin";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../../../components/custom/Modal";
import Slider from "../../../components/custom/Slider";
import { getImageResize, getInfoFromMetadata } from "../../../utils";
import MetadataCatalog from "./MetadataCatalog";
import { useTaggerStyles } from "./styles";
import Tag from "./Tag";
import Wrapper from "./Wrapper";

const AdvancedTagger = ({ filtersValues }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const classes = useTaggerStyles();
  const [mutate] = useMutation();
  const sliderRef = useRef({});
  const idImageSelectedRef = useRef(false);
  const selectedIds = useSelector(
    (state) => state.admin.resources.tagger.list.selectedIds
  );
  const dataImages = useSelector((state) => state.admin.resources.tagger.data);
  const [indexImage, setIndexImage] = useState(false);
  const idMatch = filtersValues.match || false;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const onKeyDown = useCallback(
    (e) => {
      if (selectedIds.length > 0) {
        if (e.key === "ArrowLeft") {
          sliderRef.current.sliderPrev();
        } else if (e.key === "ArrowRight") {
          sliderRef.current.sliderNext();
        }
      }
    },
    [selectedIds.length]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    if (selectedIds.length > 0) {
      if (indexImage === false) {
        setIndexImage(0);
      } else {
        const index = selectedIds.findIndex(
          (element) => element === idImageSelectedRef.current
        );
        if (index !== -1) {
          sliderRef.current.sliderGoTo(index);
        } else {
          if (indexImage - 1 >= 0) {
            sliderRef.current.sliderGoTo(indexImage - 1);
          } else {
            sliderRef.current.sliderGoTo(0);
          }
        }
      }
    } else {
      setIndexImage(false);
    }
    // eslint-disable-next-line
  }, [selectedIds]);

  let images = [];
  if (indexImage !== false && selectedIds.length > 0) {
    images = selectedIds.map((each) => dataImages[each]);
  }

  const imageSelected = images[indexImage] ? images[indexImage] : {};

  useEffect(() => {
    if (indexImage === false) {
      idImageSelectedRef.current = false;
    } else {
      idImageSelectedRef.current = imageSelected.id;
    }
    // eslint-disable-next-line
  }, [indexImage]);

  const deleteMetadata = (metadataIndex) => {
    const metadata = imageSelected.metadatas[metadataIndex];
    let data = imageSelected;

    data.metadatas.splice(metadataIndex, 1);

    if (!imageSelected.tagged) {
      updateTagged(true);
    }

    mutate(
      {
        type: "removeMetadata",
        resource: "tagger",
        payload: {
          id: imageSelected.id,
          data: imageSelected,
          metadata: metadata,
        },
      },
      {
        onSuccess: ({ data }) => refreshList("tagger", data),
      }
    );
  };

  const addMetadata = (newMetadata) => {
    if (!imageSelected.tagged) {
      updateTagged(true);
    }

    if (Array.isArray(newMetadata)) {
      newMetadata.forEach((metadata) => callAddMetadata(metadata));
    } else {
      callAddMetadata(newMetadata);
    }
  };

  const callAddMetadata = (newMetadata) => {
    const index = imageSelected.metadatas.findIndex(
      (element) =>
        element.value === newMetadata.value &&
        element.metadataType.id === newMetadata.metadataType.id
    );
    if (index === -1) {
      let data = { ...imageSelected };
      data.metadatas.push(newMetadata);

      mutate(
        {
          type: "addMetadata",
          resource: "tagger",
          payload: {
            id: imageSelected.id,
            data: data,
            metadata: newMetadata,
          },
        },
        {
          onSuccess: ({ data }) => refreshList("tagger", data),
        }
      );
    }
  };

  const refreshList = (data) => {
    dispatch({
      type: "CUSTOM_FETCH_SUCCESS",
      payload: {
        data: data,
      },
      requestPayload: {
        id: data.id,
        data: {},
      },
      meta: {
        resource: "tagger",
        fetchResponse: "UPDATE",
        fetchStatus: "RA/FETCH_END",
      },
    });
  };

  const updateTagged = (isTagged) => {
    mutate({
      type: "update",
      resource: "tagger",
      payload: {
        id: imageSelected.id,
        data: {
          tagged: isTagged,
        },
      },
    });
  };

  const handleClickSwitch = (e) => {
    updateTagged(e.target.checked);
  };

  return (
    <Wrapper>
      <div className={classes.root}>
        <div className={classes.rowInfo}>
          {images.length > 0 ? (
            <>
              <div className={classes.headImage}>
                <Typography align="right">{`${indexImage + 1}/${
                  images.length
                }`}</Typography>
                <FormControlLabel
                  labelPlacement="start"
                  label={translate("resource.tagger.advancedTagger.tagged")}
                  control={
                    <Switch
                      checked={imageSelected.tagged}
                      onChange={handleClickSwitch}
                      color="primary"
                      name="tagged"
                    />
                  }
                />
              </div>
              <Slider
                onChange={(value) => setIndexImage(value)}
                onClick={() => handleImageClick()}
                ref={sliderRef}
              >
                {images.map((item) => {
                  return (
                    <img
                      src={getImageResize(item.publicImageResizes, "medium")}
                      alt={item.name}
                      key={item.id}
                    />
                  );
                })}
              </Slider>
              <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                scroll="body"
                maxWidth="lg"
              >
                <img
                  className={classes.modalImg}
                  src={getImageResize(
                    imageSelected.publicImageResizes,
                    "large"
                  )}
                  alt={imageSelected.name}
                />
                <IconButton
                  className={classes.modalClose}
                  onClick={() => setIsModalOpen(false)}
                >
                  <Close />
                </IconButton>
              </Modal>
              <div className={classes.selectedTags}>
                <div className="tags">
                  {imageSelected.metadatas &&
                    imageSelected.metadatas.map((metadata, index) => {
                      if (
                        metadata.metadataType &&
                        ["other", "player", "team"].includes(
                          metadata.metadataType.name
                        )
                      ) {
                        const { resource, source } = getInfoFromMetadata(
                          metadata
                        );
                        return (
                          <ReferenceField
                            key={index}
                            record={metadata}
                            source="value"
                            reference={resource}
                            basePath={resource}
                            link={false}
                          >
                            <Tag
                              source={source}
                              onDelete={() => deleteMetadata(index)}
                            />
                          </ReferenceField>
                        );
                      } else {
                        return false;
                      }
                    })}
                </div>
              </div>
            </>
          ) : (
            <div className={classes.notImages}>
              <Typography align="center">
                {translate("resource.tagger.advancedTagger.notImagesSelected")}
              </Typography>
            </div>
          )}
        </div>
        <div
          className={classNames(classes.rowMetadatas, {
            [classes.rowMetadatasHidden]: indexImage === false,
          })}
        >
          <MetadataCatalog
            idMatch={idMatch}
            selecteds={
              indexImage !== false && selectedIds.length > 0
                ? imageSelected.metadatas
                : []
            }
            addMetadata={addMetadata}
          />
        </div>
      </div>
    </Wrapper>
  );
};

AdvancedTagger.defaultProps = {
  filtersValues: {},
};

export default AdvancedTagger;
