import {
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import _get from "lodash/get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm, useFormState } from "react-final-form";

import BodyGroupChecks from "./BodyGroupChecks";
import { addPermission, config, isChecked, removePermission } from "./utils";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing()}px`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  iconCollapse: {
    transform: "rotate(180deg)",
    transition: theme.transitions.create("rotate", {
      duration: theme.transitions.duration.shorter,
    }),
  },
  body: {
    paddingLeft: "36px",
  },
  loading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px",
    background: "#F2f2f2",
  },
}));

const GroupChecks = ({ type, permission, parentIsChecked }) => {
  const classes = useStyle();
  const form = useForm();
  const { values } = useFormState({ subscription: { values: true } });

  const [isCollapse, setIsCollapse] = useState(null);

  const handleChange = (e) => {
    let arrayPermissions = _get(values, "permissions");
    if (e.target.checked) {
      arrayPermissions = addPermission(permission, arrayPermissions, type);
    } else {
      arrayPermissions = removePermission(permission, arrayPermissions);
    }

    form.change("permissions", arrayPermissions);
  };
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                parentIsChecked ||
                isChecked(permission.id, _get(values, "permissions"))
              }
              disabled={parentIsChecked}
              onChange={handleChange}
              name={`permission-${permission.id}`}
              color="primary"
            />
          }
          label={permission.name}
        />
        {config[type].children && (
          <IconButton
            aria-label="collapse"
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <ExpandMoreRoundedIcon
              className={isCollapse ? classes.iconCollapse : ""}
            />
          </IconButton>
        )}
      </div>
      <div className={classes.body}>
        <Collapse in={isCollapse}>
          {isCollapse !== null &&
            config[type].children.map((each) => {
              let filter = {
                [each.filter]: _get(permission, each.filterValue),
              };
              if (each.aditionalFilter) {
                filter = { ...filter, ...each.aditionalFilter };
              }
              return (
                <BodyGroupChecks
                  type={each.type}
                  filter={filter}
                  permission={permission}
                  isChecked={
                    parentIsChecked ||
                    isChecked(permission.id, _get(values, "permissions"))
                  }
                  key={each.type}
                />
              );
            })}
        </Collapse>
      </div>
    </div>
  );
};

GroupChecks.propTypes = {
  type: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  parentIsChecked: PropTypes.bool,
};

export default GroupChecks;
