import { useQuery, useDataProvider } from "react-admin";
import { useSelector, shallowEqual } from "react-redux";
import { useMemo, useCallback } from "react";
import _get from "lodash/get";
import { DOWNLOAD_NOTIFICATION_FILTER_STATUS } from "../../../config/constants";

const zipsQueryParams = {
  type: "getList",
  resource: "downloads",
  payload: {
    filter: {
      status: DOWNLOAD_NOTIFICATION_FILTER_STATUS,
    },
    sort: {
      field: "createdAt",
      order: "DESC",
    },
  },
};

const zipDataSelector = (state) =>
  _get(state, "admin.resources.downloads.data");

export function useGetDownloadURL() {
  const dataProvider = useDataProvider();
  const getDownloadURL = useCallback(
    (hash) => {
      return dataProvider.getOne("download_zip", { id: hash });
    },
    [dataProvider]
  );
  return getDownloadURL;
}

export function useDownloadsUpdate() {
  const dataProvider = useDataProvider();
  const update = useCallback(
    (idArray) => {
      const filter = {};
      if (idArray) filter.id = idArray;
      else filter.status = DOWNLOAD_NOTIFICATION_FILTER_STATUS;
      dataProvider.getList("downloads", {
        filter,
        sort: {
          field: "createdAt",
          order: "DESC",
        },
      });
    },
    [dataProvider]
  );

  return update;
}

export function useDownloadsData() {
  useQuery(zipsQueryParams);
  const data = useSelector(zipDataSelector, shallowEqual);

  const orderedData = useMemo(() => {
    if (!data) return;

    const newOrderedData = {
      queued: [],
      ready: [],
      downloaded: [],
      expired: [],
      error: [],
    };

    Object.values(data).forEach((value) =>
      newOrderedData[value.status].push({ ...value })
    );
    return newOrderedData;
  }, [data]);

  return orderedData;
}
