import { makeStyles } from "@material-ui/core";

export const useDesktopClasses = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2),
      paddingBottom: 0,
      "& form": {
        marginTop: 0,
      },
      color: theme.palette.text.primary,
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1, 0, 0),
    },
    header: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& > svg": {
        transition: "transform 0.3s",
      },
    },
    headerOpen: {
      "& > svg": {
        transform: "rotate(180deg)",
      },
    },
    headerTitle: {
      display: "flex",
      alignItems: "center",
      "& > svg": {
        marginRight: theme.spacing(),
      },
    },
  }),
  { name: "wbo-Filters" }
);

export const useMobileClasses = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.primary,
    },
    filtersBar: {
      backgroundColor: theme.palette.background.sidebar,
      display: "flex",
      alignItems: "center",
    },
    filterString: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(0, 1),
      alignSelf: "stretch",
      flexGrow: 1,
    },
    noRadius: {
      borderRadius: 0,
    },
    drawerTitleBar: {
      backgroundColor: theme.palette.background.sidebar,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    drawerTitle: {
      display: "flex",
      alignItems: "center",
    },
    drawerTitleText: {
      fontWeight: "bold",
    },
    drawerContent: {
      flexGrow: 1,
      overflow: "auto",
      padding: theme.spacing(0, 1),
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(0, 2),
      },
    },
    drawerClose: {
      padding: theme.spacing(0.5, 1),
      cursor: "pointer",
    },
    drawerCleanFiltersBar: {
      backgroundColor: theme.palette.background.sidebar,
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(0, 1),
      display: "flex",
    },
    filterConfirmButton: {
      borderRadius: 0,
      fontWeight: "bold",
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: theme.spacing(1),
      [theme.breakpoints.up("lg")]: {
        margin: theme.spacing(1, 2),
      },
    },
  }),
  { name: "wbo-FiltersM" }
);

export const usePaperClasses = makeStyles(
  (theme) => ({
    root: {
      borderLeft: `${theme.spacing(1)}px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(1),
      background: theme.palette.background.darkPaper,
    },
  }),
  { name: "wbo-Filters-Paper" }
);

export const useDrawerClasses = makeStyles(
  (theme) => ({
    root: {
      top: `${
        theme.values.toolbar.height.xs + theme.values.toolbar.lineHeight.xs
      }px!important`,
      [theme.breakpoints.up("sm")]: {
        top: `${
          theme.values.toolbar.height.sm + theme.values.toolbar.lineHeight.sm
        }px!important`,
      },
      [theme.breakpoints.up("md")]: {
        top: `${
          theme.values.toolbar.height.md + theme.values.toolbar.lineHeight.md
        }px!important`,
      },
      color: theme.palette.text.primary,
    },
    paper: {
      top: theme.values.toolbar.height.xs + theme.values.toolbar.lineHeight.xs,
      [theme.breakpoints.up("sm")]: {
        top:
          theme.values.toolbar.height.sm + theme.values.toolbar.lineHeight.sm,
      },
      [theme.breakpoints.up("md")]: {
        top:
          theme.values.toolbar.height.md + theme.values.toolbar.lineHeight.md,
      },
      width: "100%",
      height: "auto",
      bottom: 0,
    },
  }),
  { name: "wbo-Filters-Drawer" }
);

export const useDrawerBackdropClasses = makeStyles(
  (theme) => ({
    root: {
      top: theme.values.toolbar.height.xs + theme.values.toolbar.lineHeight.xs,
      [theme.breakpoints.up("sm")]: {
        top:
          theme.values.toolbar.height.sm + theme.values.toolbar.lineHeight.sm,
      },
      [theme.breakpoints.up("md")]: {
        top:
          theme.values.toolbar.height.md + theme.values.toolbar.lineHeight.md,
      },
    },
  }),
  { name: "wbo-Filters-DrawerBackdrop" }
);

export const useHorizontalTextClasses = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    position: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      overflow: "auto",
    },
    text: {
      whiteSpace: "nowrap",
    },
  }),
  { name: "wbo-Filters-HorizontalText" }
);
