import CircularProgress from "@material-ui/core/CircularProgress";
import PauseIcon from "@material-ui/icons/Pause";
import React from "react";

export function getLengthImages(images) {
  const processingLength = images.processingImages.length;
  const queueLength = images.queueImages.length;
  const readyLength = images.readyImages.length;
  const failedLength = images.failedImages.length;
  const total = processingLength + queueLength + failedLength + readyLength;

  return {
    processingLength,
    queueLength,
    failedLength,
    readyLength,
    total,
  };
}

export function getHeaderTitle(images, translate) {
  const {
    processingLength,
    queueLength,
    readyLength,
    failedLength,
    total,
  } = getLengthImages(images);
  let title = "";

  if (queueLength > 0) {
    title = translate("resource.uploader.photos_in_queue", {
      smart_count: queueLength,
    });
  }
  if (readyLength > 0) {
    if (title !== "") {
      title += " / ";
    }
    title += translate("resource.uploader.ready_photos", {
      smart_count: readyLength,
    });
  }
  if (failedLength > 0) {
    if (title !== "") {
      title += " / ";
    }
    title += translate("resource.uploader.failed_photos", {
      smart_count: failedLength,
    });
  }
  if (processingLength > 0 && total === 1) {
    title = (
      <>
        {images.processingImages[0].paused ? (
          <PauseIcon size={16} color="inherit" />
        ) : (
          <CircularProgress size={16} color="inherit" />
        )}{" "}
        Subiendo: {images.processingImages[0]?.name}
      </>
    );
  }

  return title;
}
