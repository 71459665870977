import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(
  (theme) => ({
    xs: {
      width: "24px",
      left: "24px",
    },
    sm: {
      width: "32px",
      left: "32px",
    },
  }),
  { name: "wbo-shield" }
);

const Shield = ({ className, idTeam, size }) => {
  const classes = useStyles();
  const url = `${process.env.REACT_APP_STORAGE_DMZ}escudos/${idTeam}.png`;
  return (
    <img
      className={classNames(className, classes[size])}
      src={url}
      alt={idTeam}
    />
  );
};

Shield.defaultProps = {
  size: "sm",
};

Shield.propTypes = {
  idTeam: PropTypes.number.isRequired,
  size: PropTypes.oneOf(["xs", "sm"]),
  className: PropTypes.string,
};

export default Shield;
