import { makeStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import React, { useCallback } from "react";
import {
  CreateButton,
  useListContext,
  useTranslate,
  useNotify,
} from "react-admin";

const useToolbarStyles = makeStyles(
  (theme) => ({
    root: {
      justifyContent: "space-between",
      padding: theme.spacing(0, 1),
      marginTop: theme.spacing(1),
      minHeight: 0,
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(0, 2),
      },
    },
  }),
  { name: "wbo-CustomActionToolbar" }
);

const CustomActionToolbar = (props) => {
  const {
    selectedIds,
    resource,
    data,
    onSelect,
    onToggleItem,
    hasCreate,
    ids,
  } = useListContext();
  const classes = useToolbarStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const { actions, bulkActions, selectLimit } = props;

  const handleSelectWithLimit = useCallback(() => {
    if (selectLimit) {
      if (selectedIds.length === selectLimit) {
        notify("Sólo se pueden seleccionar 40 imágenes como máximo", "warning");
      } else {
        const items = ids
          .filter((item) => !selectedIds.includes(item))
          .slice(0, selectLimit - selectedIds.length);
        onSelect([...selectedIds, ...items]);

        if (items.length !== ids.length) {
          notify(
            "Sólo se pueden seleccionar 40 imágenes como máximo",
            "warning"
          );
        }
      }
    } else {
      onSelect([
        ...selectedIds,
        ...ids.filter((item) => !selectedIds.includes(item)),
      ]);
    }
  }, [ids, notify, onSelect, selectLimit, selectedIds]);

  let myActions = actions;
  if (myActions === undefined) {
    if (hasCreate) {
      myActions = (
        <CreateButton
          basePath={props.basePath}
          variant="contained"
          label={translate(`resource.${resource}.action_create`, {
            _: "commons.action_create",
          })}
        />
      );
    }
  }

  return (
    <Toolbar classes={classes} disableGutters>
      <div className="wbo-toolbar-actions">
        {actions !== false && myActions}
      </div>
      <div className="wbo-toolbar-bulkActions">
        {bulkActions &&
          React.cloneElement(bulkActions, {
            data,
            selectedIds,
            resource,
            selectAll: () => handleSelectWithLimit(),
            deselectAll: () => onSelect([]),
            onToggleItem,
            onSelect,
          })}
      </div>
    </Toolbar>
  );
};

export default CustomActionToolbar;
