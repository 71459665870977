import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: theme.spacing(1 / 4),
    },
    select: {
      height: "auto",
      overflow: "auto",
    },
  }),
  { name: "RaSelectArrayInput" }
);
