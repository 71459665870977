import { GridList } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useCallback } from "react";
import { useNotify } from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      overflow: "hidden",
      padding: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        margin: -theme.spacing(1),
      },
    },
    noResultsRoot: {
      padding: theme.spacing(2),
      fontSize: 26,
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      textAlign: "center",
    },
  }),
  { name: "wbo-MediaGrid" }
);

const GridBase = ({
  gridProps,
  component,
  data,
  ids,
  selectedIds,
  onToggleItem,
  selectLimit,
  xxsCols,
  xsCols,
  smCols,
  mdCols,
  lgCols,
  xlCols,
}) => {
  const isXXS = useMediaQuery("(max-width: 480px)");
  const isXS = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  const isSM = useMediaQuery((theme) => theme.breakpoints.only("sm"));
  const isMD = useMediaQuery((theme) => theme.breakpoints.only("md"));
  const isLG = useMediaQuery((theme) => theme.breakpoints.only("lg"));
  const isXL = useMediaQuery((theme) => theme.breakpoints.only("xl"));

  const numCols = useMemo(() => {
    if (isXXS) return xxsCols;
    if (isXS) return xsCols;
    if (isSM) return smCols;
    if (isMD) return mdCols;
    if (isLG) return lgCols;
    if (isXL) return xlCols;
  }, [
    isXXS,
    isXS,
    isSM,
    isMD,
    isLG,
    isXL,
    xxsCols,
    xsCols,
    smCols,
    mdCols,
    lgCols,
    xlCols,
  ]);

  const notify = useNotify();

  const classes = useStyles();

  const handleToggleSelected = useCallback(
    (id) => {
      if (selectLimit) {
        const isSelect = !selectedIds.includes(id);

        if (isSelect && selectedIds.length === selectLimit) {
          notify(
            "Sólo se pueden seleccionar 40 imágenes como máximo",
            "warning"
          );
        } else {
          onToggleItem(id);
        }
      } else {
        onToggleItem(id);
      }
    },
    [selectLimit, notify, onToggleItem, selectedIds]
  );

  if (!ids.length) return false;

  return (
    <div className={classes.root}>
      <GridList
        cols={numCols}
        spacing={16}
        cellHeight="auto"
        component="div"
        {...gridProps}
      >
        {ids.map((id) => {
          return (
            <div key={id}>
              {React.cloneElement(component, {
                record: data[id],
                selected: selectedIds.includes(id),
                toggleSelected: () => handleToggleSelected(id),
              })}
            </div>
          );
        })}
      </GridList>
    </div>
  );
};

GridBase.defaultProps = {
  xxsCols: 1,
  xsCols: 2,
  smCols: 2,
  mdCols: 3,
  lgCols: 5,
  xlCols: 5,
};

GridBase.propTypes = {
  gridProps: PropTypes.object,
  component: PropTypes.node,
  componentProps: PropTypes.object,
  data: PropTypes.object,
  ids: PropTypes.array,
  selectedIds: PropTypes.array,
  onToggleItem: PropTypes.func,
  xxsCols: PropTypes.number,
  xsCols: PropTypes.number,
  smCols: PropTypes.number,
  mdCols: PropTypes.number,
  lgCols: PropTypes.number,
  xlCols: PropTypes.number,
};

export default GridBase;
