import { Grid } from "@material-ui/core";
import _get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useCallback } from "react";
import { FormDataConsumer, ReferenceInput } from "react-admin";
import { useForm } from "react-final-form";

import { SelectInput } from "../../inputs";

const Input = ({ idSeason, sourceEvent, sourceAct }) => {
  const form = useForm();

  const handleChange = useCallback(
    (type) => {
      switch (type) {
        case "season":
          form.change(sourceEvent, null);
          form.change(sourceAct, null);
          break;
        case "event":
          form.change(sourceAct, null);
          break;
        default:
          break;
      }
    },
    [form, sourceEvent, sourceAct]
  );

  useEffect(() => {
    handleChange("season");
  }, [idSeason, handleChange]);

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              {idSeason ? (
                <ReferenceInput
                  source={sourceEvent}
                  reference="events"
                  label="commons.events"
                  filter={{ season: idSeason }}
                  onChange={() => handleChange("event")}
                  allowEmpty
                  fullWidth
                >
                  <SelectInput fullWidth />
                </ReferenceInput>
              ) : (
                <SelectInput label="commons.events" disabled fullWidth />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {sourceAct && idSeason && _get(formData, sourceEvent) ? (
                <ReferenceInput
                  source={sourceAct}
                  reference="acts"
                  label="commons.acts"
                  filter={{ event: _get(formData, sourceEvent) }}
                  allowEmpty
                  fullWidth
                >
                  <SelectInput fullWidth />
                </ReferenceInput>
              ) : (
                <SelectInput label="commons.acts" disabled fullWidth />
              )}
            </Grid>
          </Grid>
        );
      }}
    </FormDataConsumer>
  );
};

Input.propTypes = {
  idSeason: PropTypes.number,
  sourceEvent: PropTypes.string,
  sourceAct: PropTypes.string,
};

export default Input;
