import React, { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";

import MediaCard from "../../../components/datagrid/datagridComponents/MediaCard";
import MediaGrid from "../../../components/datagrid/MediaGrid";
import { MetadataField } from "../../../components/fields";
import List from "../../../components/ra-components/List";
import BulkActions from "./BulkActions";
import MatchActions from "./BulkActions/MatchActions";

export default ({ filtersValues, setfiltersValues, ...rest }) => {
  const dataProvider = useDataProvider();
  const match = filtersValues.match || false;

  const [loadedMatch, setLoadedMatch] = useState(false);
  const [dataMatch, setDataMatch] = useState(false);
  const [loadedMetadataOther, setLoadedMetadataOther] = useState(false);
  const [metadataOther, setMetadataOther] = useState(false);

  useEffect(() => {
    dataProvider.getOne("matches", { id: match }).then(({ data }) => {
      setDataMatch(data);
      setLoadedMatch(true);
    });
    dataProvider.getList("metadata-others", {}).then(({ data }) => {
      setMetadataOther(data.slice(0, 6));
      setLoadedMetadataOther(true);
    });
  }, [match, dataProvider]);

  return (
    <List
      filter={filtersValues}
      bulkActions={
        <BulkActions
          filtersValues={filtersValues}
          setfiltersValues={setfiltersValues}
        >
          {match && loadedMatch && loadedMetadataOther && (
            <MatchActions match={dataMatch} metadataOthers={metadataOther} />
          )}
        </BulkActions>
      }
      {...rest}
    >
      <MediaGrid
        lgCols={3}
        xlCols={3}
        component={
          <MediaCard
            imageSource="publicImageResizes"
            imageSize="small"
            imageModalSize="large"
            titleSource="name"
            extraComponent={
              loadedMatch &&
              loadedMetadataOther && (
                <MetadataField
                  source="metadatas"
                  sourceTagged="tagged"
                  other={metadataOther}
                  teams={[dataMatch.homeTeam, dataMatch.awayTeam]}
                />
              )
            }
          />
        }
      />
    </List>
  );
};
