import React from "react";
import ChangePasswordModal from "../components/custom/ChangePasswordModal";

export default [
  {
    primaryText: "userMenu.change_password",
    onClick: (callback) => {
      callback(<ChangePasswordModal />);
    },
  },
];
