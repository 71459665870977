import { Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import {
  Datagrid,
  DateField,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";

import RecoverTrashButton from "../../../components/buttons/RecoverTrashButton";
import MobileList from "../../../components/datagrid/MobileList";
import { UserActiveField } from "../../../components/fields";
import List from "../../../components/ra-components/List";
import { userActiveValors } from "../../../resources/users/constants";
import Actions from "./Actions";
import BulkActions from "./BulkActions";
import Filters from "./Filters";
import { useStyles } from "./styles";

export default (props) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = "users";

  const getTanlationState = (value) => {
    const state = userActiveValors.find((each) => each.value === value);
    if (state) {
      return state.translate;
    }
    return "";
  };

  return (
    <List
      actions={<Actions />}
      filters={<Filters />}
      bulkActions={<BulkActions />}
      perPage={30}
      {...props}
    >
      {isDesktop ? (
        <Datagrid rowClick="edit">
          <UserActiveField label={`commons.state`} source="active" />
          <TextField
            label={`resource.${resource}.email`}
            source="email"
            cellClassName={classes.fullWidthCell}
          />
          <TextField
            label={`resource.${resource}.typeOfpartnership`}
            source="partnership.name"
            cellClassName={classes.fullWidthCell}
          />
          <DateField label="commons.date" source="createdAt" showTime />
          <RecoverTrashButton />
        </Datagrid>
      ) : (
        <MobileList
          linkType="edit"
          content={(record) => (
            <div>
              <Typography>
                {translate(`commons.state`)}:{" "}
                {translate(getTanlationState(record?.active))}
              </Typography>
              <Typography>Email: {record.email}</Typography>
              <Typography>
                {translate(`resource.${resource}.typeOfpartnership`)}:{" "}
                {record?.partnership?.name}
              </Typography>
              <Typography>
                {translate(`commons.date`)}:{" "}
                {<DateField source="createdAt" record={record} showTime />}
              </Typography>
            </div>
          )}
          actions={[
            {
              label: (record) =>
                record.active
                  ? translate("commons.disable")
                  : translate("commons.reactivate"),
              onClick: (record) => {
                dataProvider
                  .update(resource, {
                    id: record.id,
                    data: { active: !record.active },
                  })
                  .then(() => {
                    notify(
                      record.active
                        ? `resource.${resource}.notify.user_disable`
                        : `resource.${resource}.notify.user_active`,
                      "info"
                    );
                    refresh();
                  });
              },
            },
            {
              label: (record, isTrash) =>
                isTrash
                  ? translate("commons.recover")
                  : translate("commons.delete"),
              onClick: (record, isTrash) => {
                dataProvider
                  .delete(resource, {
                    id: record.id,
                    data: { trashed: !isTrash },
                  })
                  .then(() => {
                    notify(
                      isTrash
                        ? `resource.${resource}.notify.recovered`
                        : `resource.${resource}.notify.deleted`,
                      "info"
                    );
                    refresh();
                  });
              },
            },
          ]}
        />
      )}
    </List>
  );
};
