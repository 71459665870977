export const REMOVE_UPLOADER_IMAGES = "REMOVE_UPLOADER_IMAGES";
export const UPDATE_UPLOADER_IMAGES = "UPDATE_UPLOADER_IMAGES";
export const ADD_NEW_UPLOADER_IMAGES = "ADD_NEW_UPLOADER_IMAGES";
export const START_UPLOAD_UPLOADER_IMAGES = "START_UPLOAD_UPLOADER_IMAGES";
export const START_UPLOAD_UPLOADER_IMAGE = "START_UPLOAD_UPLOADER_IMAGE";
export const SET_ERROR_UPLOADER = "SET_ERROR_UPLOADER";
export const UPDATE_PAUSE_STATE_UPLOADER_IMAGE =
  "UPDATE_PAUSE_STATE_UPLOADER_IMAGE";
export const UPLOAD_FRAGMENT_UPLOADER_IMAGES =
  "UPLOAD_FRAGMENT_UPLOADER_IMAGES";

export const upload_fragment_uploader_images = () => {
  return {
    type: UPLOAD_FRAGMENT_UPLOADER_IMAGES,
    payload: null,
  };
};

export const update_pause_state_uploader_image = (imagesKey, index) => {
  return {
    type: UPDATE_PAUSE_STATE_UPLOADER_IMAGE,
    payload: { imagesKey, index },
  };
};

export const set_error_uploader = (errored) => {
  return {
    type: SET_ERROR_UPLOADER,
    payload: errored,
  };
};

export const start_upload_uploader_image = () => {
  return {
    type: START_UPLOAD_UPLOADER_IMAGE,
    payload: null,
  };
};

export const start_upload_uploader_images = () => {
  return {
    type: START_UPLOAD_UPLOADER_IMAGES,
    payload: null,
  };
};

export const add_new_uploader_images = (images, formData) => {
  return {
    type: ADD_NEW_UPLOADER_IMAGES,
    payload: { images, formData },
  };
};

export const update_uploader_images = (images) => {
  return {
    type: UPDATE_UPLOADER_IMAGES,
    payload: images,
  };
};

export const remove_uploader_images = (imagesKey, index) => {
  return {
    type: REMOVE_UPLOADER_IMAGES,
    payload: { imagesKey, index },
  };
};
