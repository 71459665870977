import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    paddingBottom: 0,
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2),
    },
  },
  paperHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    position: "relative",
    padding: theme.spacing(),
    minHeight: "40px",
    "& > svg": {
      transition: "transform 0.3s",
    },
    [theme.breakpoints.down("md")]: {
      background: theme.palette.background.sidebar,
      borderBottom: `1px solid ${theme.palette.divider}`,
      "&:after": {
        content: '""',
        width: "1px",
        height: "100%",
        position: "absolute",
        top: 0,
        left: "40px",
        background: theme.palette.divider,
      },
    },
  },
  paperHeadOpen: {
    "& > svg": {
      transform: "rotate(180deg)",
    },
  },
  paperHeadTitle: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.spacing(),
      },
    },
  },
  paperBody: {
    width: "100%",
    background: theme.palette.background.darkPaper,
    "& .form-input": {
      padding: theme.spacing(),
    },
    [theme.breakpoints.down("md")]: {
      background: theme.palette.background.paper,
    },
  },
  paperButtonPanel: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(0.5, 1, 1, 1),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0.5, 0, 0, 0),
      justifyContent: "center",
    },
  },
}));

export const usePaperClasses = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.sidebar,
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
    },
    [theme.breakpoints.up("lg")]: {
      background: theme.palette.background.darkPaper,
      borderLeft: `${theme.spacing(1)}px solid ${theme.palette.primary.main}`,
    },
  },
}));

export const useDrawerClasses = makeStyles((theme) => ({
  root: {
    top: `${theme.values.toolbar.height.xs}px!important`,
    [theme.breakpoints.up("sm")]: {
      top: `${theme.values.toolbar.height.sm}px!important`,
    },
    [theme.breakpoints.up("md")]: {
      top: `${theme.values.toolbar.height.md}px!important`,
    },
    color: theme.palette.text.primary,
  },
  paper: {
    top: theme.values.toolbar.height.xs,
    [theme.breakpoints.up("sm")]: {
      top: theme.values.toolbar.height.sm,
    },
    [theme.breakpoints.up("md")]: {
      top: theme.values.toolbar.height.md,
    },
    width: "100%",
    height: "auto",
    bottom: 0,
  },
}));

export const useDrawerBackdropClasses = makeStyles((theme) => ({
  root: {
    top: theme.values.toolbar.height.xs,
    [theme.breakpoints.up("sm")]: {
      top: theme.values.toolbar.height.sm,
    },
    [theme.breakpoints.up("md")]: {
      top: theme.values.toolbar.height.md,
    },
  },
}));
