import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  choices,
  FormDataConsumer,
  required,
  useDataProvider,
  useNotify,
  useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import { password_complexity } from "../../../utils/password";

import PasswordInput from "../../inputs/PasswordInput";
import { useStyles } from "./styles";

const ChangePasswordModal = ({ onClose }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [isLoading, setLoading] = useState();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleOnSubmit = (data) => {
    setLoading(true);

    dataProvider
      .create("change-password", {
        data: {
          currentPlainPassword: data.currentPlainPassword,
          plainPassword: data.plainPassword,
          repeatPlainPassword: data.repeatPlainPassword,
        },
      })
      .then(() => {
        notify("login.password_changed", "info");
        onClose();
      })
      .catch((error) => notify(error.message, "warning"))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.dialogContent}>
              <h4>Título</h4>
              <div>
                <PasswordInput
                  source="currentPlainPassword"
                  label={translate("login.current_password")}
                  validate={required()}
                />
                <PasswordInput
                  source="plainPassword"
                  label={translate("login.password")}
                  validate={[required(), password_complexity()]}
                />
                <FormDataConsumer>
                  {({ formData }) => (
                    <PasswordInput
                      source="repeatPlainPassword"
                      label={translate("login.repeat_password")}
                      validate={[
                        required(),
                        choices(
                          [formData.plainPassword],
                          translate("login.repeat_password_text")
                        ),
                      ]}
                    />
                  )}
                </FormDataConsumer>
                <p style={{ fontSize: "0.8rem" }}>
                  {translate("login.password_complexity")}
                </p>
              </div>
            </div>
            <div className={classes.dialogButtons}>
              <Button onClick={onClose}>{translate("commons.cancel")}</Button>
              <Button
                disabled={isLoading}
                variant="contained"
                type="submit"
                color="primary"
              >
                {translate("login.change_password")}
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

ChangePasswordModal.propTypes = {
  onClose: PropTypes.func,
};

export default ChangePasswordModal;
