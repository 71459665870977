import makeStyles from "@material-ui/core/styles/makeStyles";

export const useButtonGroupStyles = makeStyles((theme) => ({
  root: {
    "& > button": {
      borderRadius: 0,
      "&:first-child": {
        borderTopLeftRadius: "2px",
        borderBottomLeftRadius: "2px",
      },
      "&:last-child": {
        borderTopRightRadius: "2px",
        borderBottomRightRadius: "2px",
      },
    },
  },
}));
