import Button from "@material-ui/core/Button";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React, { useState } from "react";
import { useTranslate } from "react-admin";

import MoreInfoDrawer from "../../custom/MoreInfoDrawer";
import { useMoreInfoButtonStyles } from "./styles";

const MoreInfoButton = ({ record, small }) => {
  const [isOpen, setOpen] = useState();
  const buttonClasses = useMoreInfoButtonStyles();
  const translate = useTranslate();

  return (
    <>
      <Button
        classes={buttonClasses}
        color="primary"
        onClick={() => setOpen(true)}
      >
        <InfoOutlined />{" "}
        {!small && <span>{translate("resource.history.advanced_info")}</span>}
      </Button>
      <MoreInfoDrawer record={record} open={isOpen} setOpen={setOpen} />
    </>
  );
};

export default MoreInfoButton;
