import CustomLayout from "../components/ra-components/Layout";
import { LoginPage } from "../pages";
import { dataProvider } from "../providers";
import authProvider from "../providers/authProviderToken";
import { languageReducer } from "../redux/reducers/language";
import { uploaderReducer } from "../redux/reducers/uploader";
import uploaderSaga from "../redux/sagas/uploader";
import { customRoutes } from "./customRoutes";
import { themeReducer } from "./theme";
import { filterTypeGalleryReducer } from "../redux/reducers/filterTypeGalleryReducer";
import i18nProvider from "./translations";

export default {
  i18nProvider,
  authProvider,
  dataProvider,
  loginPage: LoginPage,
  customReducers: {
    theme: themeReducer,
    language: languageReducer,
    uploader: uploaderReducer,
    filterTypeGallery: filterTypeGalleryReducer,
  },
  customSagas: [uploaderSaga],
  layout: CustomLayout,
  customRoutes,
};
