import { Collapse, Divider } from "@material-ui/core";
import { Close, ExpandLess } from "@material-ui/icons";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { remove_uploader_images } from "../../../../redux/actions/uploader";
import PhotoBlock from "./PhotoBlock";

const PhotoList = ({ classes, imagesKey, variant, label }) => {
  const [open, setOpen] = useState(true);
  const images = useSelector((state) => state.uploader[imagesKey]);
  const dispatch = useDispatch();

  const handleRemoveListImages = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(remove_uploader_images(imagesKey));
    },
    [dispatch, imagesKey]
  );

  return (
    <div>
      <div
        onClick={() => setOpen(!open)}
        className={classNames(classes.bar, classes[variant], {
          [classes.barOpen]: !!open,
        })}
      >
        {`${label} (${images.length})`}
        <div>
          {images.length > 0 && imagesKey !== "processingImages" && (
            <Close onClick={handleRemoveListImages} />
          )}
          <ExpandLess />
        </div>
      </div>
      <Collapse in={open}>
        {images.map((image, index) => (
          <React.Fragment key={`${image.name}${image.image.size}${index}`}>
            <PhotoBlock image={image} index={index} imagesKey={imagesKey} />
            {index !== images.length - 1 && <Divider variant="middle" />}
          </React.Fragment>
        ))}
      </Collapse>
    </div>
  );
};

PhotoList.propTypes = {
  classes: PropTypes.object,
  imagesKey: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
};

export default PhotoList;
