import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: ({ dark }) =>
      dark
        ? theme.palette.common.white
        : theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
  separator: {
    background: ({ dark }) =>
      dark
        ? theme.palette.common.white
        : theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
    width: 1,
    alignSelf: "stretch",
    margin: theme.spacing(1, 0),
  },
}));

export const useButtonStyles = makeStyles((theme) => ({
  root: {
    color: ({ dark }) =>
      dark
        ? theme.palette.common.white
        : theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
    minWidth: "40px",
    opacity: 0.87,
    "&.Mui-disabled": {
      opacity: 0.36,
      color: ({ dark }) =>
        dark
          ? theme.palette.common.white
          : theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
  },
}));
