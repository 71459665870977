import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => {
    return {
      mainContainer: {
        flex: "1 0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "50px 20px",
        [theme.breakpoints.up("sm")]: {
          "& form": {
            padding: theme.spacing(0, 2),
          },
        },
      },
      card: {
        width: "100%",
        maxWidth: "600px",
        borderLeft: `${theme.spacing()}px solid ${theme.palette.primary.main}`,
        textAlign: "center",
      },
      localeContainer: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: 10,
      },
      logo: {
        height: 36,
        margin: theme.spacing(2),
      },
      buttons: {
        margin: "0 auto",
        textAlign: "center",
        paddingTop: theme.spacing(2),
        "& + &": {
          paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.up("sm")]: {
          "& .MuiButton-root": {
            fontSize: "16px",
          },
        },
      },
      loginButtons: {
        maxWidth: "265px",
      },
    };
  },
  { name: "WBOLoginFilure" }
);
