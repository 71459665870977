import { CircularProgress, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useQueryWithStore } from "react-admin";

import GroupChecks from "./GroupChecks";

const useStyle = makeStyles((theme) => ({
  loading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

const BodyGroupChecks = ({ type, filter, isChecked }) => {
  const classes = useStyle();
  const { loaded, data } = useQueryWithStore({
    type: "getList",
    resource: "permissions",
    payload: { filter: filter },
  });
  if (loaded) {
    if (data) {
      return data.map((item) => (
        <GroupChecks
          type={type}
          permission={item}
          parentIsChecked={isChecked}
          key={item.id}
        />
      ));
    }
  } else {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
};

BodyGroupChecks.propTypes = {
  type: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  isChecked: PropTypes.bool,
};

export default BodyGroupChecks;
