import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    paper: {
      padding: `${theme.spacing(2)}px`,
      borderLeft: `${theme.spacing()}px solid ${theme.palette.primary.main}`,
    },
  }),
  {
    name: "WBOModal",
  }
);
