import { Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import { useStyles } from "./styles";

const Modal = (props) => {
  const { className, ...dialogProps } = props;
  const classes = useStyles();

  return <Dialog PaperProps={{ className: classes.paper }} {...dialogProps} />;
};

Modal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dialogContent: PropTypes.node,
  cancelButton: PropTypes.node,
  confirmButton: PropTypes.node,
  onConfirm: PropTypes.func,
};

export default Modal;
