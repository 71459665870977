import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { themes } from "../../../config/theme";

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

function withTheme(Component, themeKey = null) {
  class ThemeContainer extends React.Component {
    render() {
      const { forwardRef, theme, ...rest } = this.props;
      return (
        <ThemeProvider theme={themes[themeKey || theme]}>
          <Component {...rest} ref={forwardRef} />
        </ThemeProvider>
      );
    }
  }

  const ConnectedWithTheme = !themeKey
    ? connect(mapStateToProps)(ThemeContainer)
    : ThemeContainer;

  return React.forwardRef((props, ref) => {
    return <ConnectedWithTheme {...props} forwardRef={ref} />;
  });
}

export default withTheme;
