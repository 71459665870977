import React from "react";
import { Divider, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";

import { useMatchActionsStyles } from "./styles";

import { TagButton } from "../../../../components/buttons";
import Shield from "../../../../components/custom/Shield";

export default ({ match = false, metadataOthers = false }) => {
  const classes = useMatchActionsStyles();
  const translate = useTranslate();

  return (
    <div className={classes.root}>
      <Typography>{translate("resource.tagger.quick_tagging")}</Typography>
      <div className={classes.actions}>
        {match && (
          <div className={classes.matchActions}>
            <TagButton
              label={<Shield idTeam={match.homeTeam.id} />}
              values={[
                {
                  type: "team",
                  value: match.homeTeam.id,
                },
              ]}
              type="team"
            />
            <TagButton
              label={<Shield idTeam={match.awayTeam.id} />}
              values={[
                {
                  type: "team",
                  value: match.awayTeam.id,
                },
              ]}
              type="team"
            />
            <TagButton
              label={
                <div className={classes.buttonTagMatch}>
                  <Shield idTeam={match.homeTeam.id} />
                  <span>{"&"}</span>
                  <Shield idTeam={match.awayTeam.id} />
                </div>
              }
              values={[
                {
                  type: "team",
                  value: match.homeTeam.id,
                },
                {
                  type: "team",
                  value: match.awayTeam.id,
                },
              ]}
              type="team"
            />
          </div>
        )}
        {metadataOthers && (
          <div className={classes.tagActions}>
            {metadataOthers.map((item) => (
              <TagButton
                label={item.name}
                color={item.color}
                values={[
                  {
                    type: "other",
                    value: item.id,
                  },
                ]}
                key={item.id}
              />
            ))}
          </div>
        )}
      </div>
      <Divider />
    </div>
  );
};
