import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { linkToRecord } from "ra-core";
import React from "react";
import { Link } from "react-router-dom";

const useLinkOrNotStyles = makeStyles(
  {
    link: {
      textDecoration: "none",
      color: "inherit",
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  { name: "RaLinkOrNot" }
);

const LinkOrNot = ({
  classes: classesOverride,
  linkType,
  basePath,
  id,
  children,
  record,
}) => {
  const classes = useLinkOrNotStyles({ classes: classesOverride });
  const link = typeof linkType === "function" ? linkType(record, id) : linkType;

  return link === "edit" || link === true ? (
    <Link to={linkToRecord(basePath, id)} className={classes.link}>
      {children}
    </Link>
  ) : link === "show" ? (
    <Link to={`${linkToRecord(basePath, id)}/show`} className={classes.link}>
      {children}
    </Link>
  ) : (
    <span>{children}</span>
  );
};

LinkOrNot.propTypes = {
  classes: PropTypes.object,
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  basePath: PropTypes.string,
  id: PropTypes.number,
  children: PropTypes.node,
  record: PropTypes.object,
};

export default LinkOrNot;
