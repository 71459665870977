import { Button, ButtonGroup, useMediaQuery } from "@material-ui/core";
import React, { useCallback } from "react";
import {
  useDataProvider,
  useListContext,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";

import {
  BulkActionsMenuButton,
  DeleteButton,
} from "../../../../components/buttons";
import { useButtonGroupStyles } from "./styles";

const BulkActions = ({ selectedIds, selectAll, deselectAll }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const { filterValues, resource } = useListContext();
  const buttonGroupClasses = useButtonGroupStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dataProvider = useDataProvider();

  const isTrash = !!filterValues.trashed;

  const getLabel = useCallback(
    (translation) => {
      if (selectedIds.length) {
        return `${translate(translation)} (${selectedIds.length})`;
      } else {
        return `${translate(translation)}`;
      }
    },
    [translate, selectedIds.length]
  );

  const getLabelTrash = useCallback(() => {
    if (selectedIds.length) {
      return `${translate(
        isTrash ? "commons.recover" : "commons.move_to_trash"
      )} (${selectedIds.length})`;
    } else {
      return `${translate(
        isTrash ? "commons.recover" : "commons.move_to_trash"
      )}`;
    }
  }, [translate, selectedIds.length, isTrash]);

  const [activeUser] = useMutation(
    {
      type: "updateMany",
      resource,
      payload: {
        ids: selectedIds,
        data: { active: 1 },
      },
    },
    {
      onSuccess: () => {
        notify(`resource.${resource}.notify.user_active`, "info");
        refresh();
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const [disableUser] = useMutation(
    {
      type: "updateMany",
      resource,
      payload: {
        ids: selectedIds,
        data: { active: 0 },
      },
    },
    {
      onSuccess: () => {
        notify(`resource.${resource}.notify.user_disable`, "info");
        refresh();
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const [recoverMany] = useMutation(
    {
      type: "deleteMany",
      resource,
      payload: {
        ids: selectedIds,
        data: { trashed: false },
      },
    },
    {
      onSuccess: () => {
        notify(`resource.${resource}.notify.recovered`, "info");
        refresh();
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const getActions = useCallback(() => {
    const actions = [];
    if (filterValues.active !== 1) {
      actions.push({
        label: getLabel("commons.reactivate"),
        onClick: (selectedIds, isTrash) => {
          dataProvider
            .updateMany(resource, {
              ids: selectedIds,
              data: { active: 1 },
            })
            .then(() => {
              notify(`resource.${resource}.notify.user_active`, "info");
              refresh();
            });
        },
      });
    }

    if (filterValues.active !== 0) {
      actions.push({
        label: getLabel("commons.disable"),
        onClick: (selectedIds, isTrash) => {
          dataProvider
            .updateMany(resource, {
              ids: selectedIds,
              data: { active: 0 },
            })
            .then(() => {
              notify(`resource.${resource}.notify.user_disable`, "info");
              refresh();
            });
        },
      });
    }

    actions.push({
      label: getLabelTrash(),
      onClick: (selectedIds, isTrash) => {
        dataProvider
          .deleteMany(resource, {
            ids: selectedIds,
            data: { trashed: !isTrash },
          })
          .then(() => {
            notify(
              isTrash
                ? `resource.${resource}.notify.recovered`
                : `resource.${resource}.notify.deleted`,
              "info"
            );
            refresh();
          });
      },
    });

    return actions;
  }, [
    dataProvider,
    filterValues.active,
    notify,
    refresh,
    resource,
    getLabel,
    getLabelTrash,
  ]);

  return isDesktop ? (
    <div>
      <ButtonGroup
        classes={buttonGroupClasses}
        variant="contained"
        color="primary"
        size="small"
      >
        {filterValues.active !== "true" && (
          <DeleteButton
            resource={resource}
            disabled={selectedIds.length === 0}
            label={getLabel("commons.reactivate")}
            onConfirm={activeUser}
            dialogContent={
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      `resource.${resource}.dialog_enable.counter_html`,
                      {
                        smart_count: selectedIds.length,
                      }
                    ),
                  }}
                ></p>
              </div>
            }
            cancelButton={<Button>{translate("commons.cancel")}</Button>}
            confirmButton={
              <Button variant="contained" color="primary">
                {translate("commons.reactivate")}
              </Button>
            }
          />
        )}
        {filterValues.active !== "false" && (
          <DeleteButton
            resource={resource}
            disabled={selectedIds.length === 0}
            label={getLabel("commons.disable")}
            onConfirm={disableUser}
            dialogContent={
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      `resource.${resource}.dialog_disable.counter_html`,
                      {
                        smart_count: selectedIds.length,
                      }
                    ),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      `resource.${resource}.dialog_disable.advice_html`
                    ),
                  }}
                ></p>
              </div>
            }
            cancelButton={<Button>{translate("commons.cancel")}</Button>}
            confirmButton={
              <Button variant="contained" color="primary">
                {translate("commons.disable")}
              </Button>
            }
          />
        )}
        {isTrash ? (
          <DeleteButton
            disabled={selectedIds.length === 0}
            label={getLabelTrash()}
            onConfirm={recoverMany}
            dialogContent={
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      `resource.${resource}.dialog_recover.counter_html`,
                      {
                        smart_count: selectedIds.length,
                      }
                    ),
                  }}
                ></p>
              </div>
            }
            cancelButton={<Button>{translate("commons.cancel")}</Button>}
            confirmButton={
              <Button variant="contained" color="primary">
                {translate("commons.recover")}
              </Button>
            }
          />
        ) : (
          <DeleteButton
            isTrash={isTrash}
            resource={resource}
            disabled={selectedIds.length === 0}
            label={getLabelTrash()}
            dialogContent={
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      `resource.${resource}.dialog_delete.counter_html`,
                      {
                        smart_count: selectedIds.length,
                      }
                    ),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      `resource.${resource}.dialog_delete.advice_html`
                    ),
                  }}
                ></p>
              </div>
            }
            cancelButton={<Button>{translate("commons.cancel")}</Button>}
            confirmButton={
              <Button variant="contained" color="primary">
                {translate("commons.delete")}
              </Button>
            }
          />
        )}
      </ButtonGroup>
    </div>
  ) : (
    <BulkActionsMenuButton
      actions={getActions()}
      selectAll={selectAll}
      deselectAll={deselectAll}
    />
  );
};

export default BulkActions;
