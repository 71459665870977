import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import ReactDOM from "react-dom";

const portalDiv = document.getElementById("portal");
const Portal = ({ children }) => {
  const el = useMemo(() => document.createElement("div"), []);
  useEffect(() => {
    portalDiv.appendChild(el);

    return () => {
      portalDiv.removeChild(el);
    };
  }, [el]);
  return ReactDOM.createPortal(children, el);
};

Portal.propTypes = {
  children: PropTypes.node,
};

export default Portal;
