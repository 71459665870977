import customRoutesConfig from "../config/customRoutes";

export const IS = [
  "Object",
  "Arguments",
  "Function",
  "String",
  "Number",
  "Date",
  "RegExp",
  "Array",
].reduce((exp, name) => {
  exp[name.toLowerCase()] = (x) =>
    Object.prototype.toString.call(x) === `[object ${name}]`;
  return exp;
}, {});

// Faster checks
IS.object = (obj) => obj === Object(obj);

export function formatFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatMetadatas = (formData) => {
  let metadatas = [];
  for (const [key, value] of Object.entries(formData)) {
    if (value) {
      metadatas.push({
        type: key,
        value,
      });
    }
  }

  return metadatas;
};

export const getInfoFromMetadata = (metadata) => {
  let resource;
  let source = "name";

  switch (metadata.metadataType.name) {
    case "other":
      resource = "metadata-others";
      break;
    case "match":
      resource = "matches";
      break;
    case "championship":
      resource = "championships";
      source = "competition.name";
      break;
    case "player":
      resource = "players";
      source = "nickname";
      break;
    case "season":
      resource = "seasons";
      break;
    case "competition":
      resource = "competitions";
      break;
    case "gameweek":
      resource = "gameweeks";
      break;
    case "team":
      resource = "teams";
      source = "nickname";
      break;
    case "event":
      resource = "events";
      break;
    case "act":
      resource = "acts";
      break;
    default:
      resource = metadata.metadataType.name;
      break;
  }
  return { resource, source };
};

export const getImageResize = (resizes = [], size) => {
  const image = resizes.find((resize) => resize.type === size);
  if (image) {
    return image.url;
  } else {
    //Faltan imagenes por defecto
    return undefined;
  }
};

export function ifPublicPage() {
  const page = window.location.hash.split("?")[0];
  const listPublicsUrl = ["#/login"];

  for (const key in customRoutesConfig) {
    if (customRoutesConfig[key].noAuth) {
      listPublicsUrl.push(`#${customRoutesConfig[key].path}`);
    }
  }

  return listPublicsUrl.includes(page);
}
