import axios from "axios";
import decode from "jwt-decode";
import _get from "lodash/get";
import _pick from "lodash/pick";

import {
  LOGOUT_USERDATA_DATA_KEYS,
  TOKEN_KEY,
  USERDATA_DATA_KEYS,
} from "../config/constants";
import { getUserConfig, setUserConfig } from "../utils/userConfig";

const BASE_URL = process.env.REACT_APP_LOGIN_ENDPOINT;

const authAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/ld+json,application/json, text/plain, */*",
  },
});

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

function deleteToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export function getTokenData() {
  const token = getToken();
  let tokenData;
  try {
    tokenData = decode(token);
  } catch (e) {
    tokenData = undefined;
  }
  return tokenData;
}

let timeout;

function refreshToken() {
  const token = getToken();
  const tokenData = getTokenData();
  if (!token || !tokenData) return;
  authAPI
    .post(
      "/refresh_token",
      { refresh_token: tokenData.refresh_token },
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Language": getUserConfig("language") || "en",
        },
      }
    )
    .then((response) => {
      const newToken = _get(response, "data.token");
      setToken(newToken);
      const tokenData = getTokenData();
      setUserConfig(_pick(tokenData, USERDATA_DATA_KEYS));
      timeout = undefined;
      setRefreshToken();
    });
}

export function setRefreshToken() {
  const token = getToken();
  if (!token) return;
  if (!timeout) {
    const tokenData = getTokenData();
    timeout = setTimeout(
      refreshToken,
      tokenData.exp * 1000 - Date.now() - 300000
    ); // 5 min before token expires
  }
}

function doLogin({ username, password, reCaptcha }) {
  return authAPI
    .post(
      "/login_check",
      {
        username,
        password,
        reCaptcha,
      },
      {
        headers: { "Content-Language": getUserConfig("language") || "en" },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const token = _get(response, "data.token", undefined);
        if (token) {
          setToken(token);
          const tokenData = getTokenData();
          setUserConfig(_pick(tokenData, USERDATA_DATA_KEYS), true);
        } else {
          console.log("No token in response.");
          console.dir(response);
          return Promise.reject("commons.generic_error");
        }
      }
    })
    .catch((err) => {
      const status = _get(err, "response.status", undefined);
      if (status === 401) {
        return Promise.reject(
          err?.response?.data?.["hydra:description"] || "login_error"
        );
      }
      return Promise.reject("commons.generic_error");
    });
}

function doLogout() {
  const token = getToken();
  authAPI.post(
    "/logout",
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Language": getUserConfig("language") || "en",
      },
    }
  );
  deleteToken();
  setUserConfig(_pick(getUserConfig(), LOGOUT_USERDATA_DATA_KEYS), true);
  clearTimeout(timeout);
  timeout = undefined;
  return Promise.resolve("login");
}

export function checkToken() {
  const tokenData = getTokenData();
  return tokenData && tokenData.exp > Date.now() / 1000;
}

function checkAuth() {
  if (checkToken()) {
    setRefreshToken();
    return Promise.resolve();
  } else {
    return Promise.reject("session_expired");
  }
}

function checkError(error) {
  console.log("Auth/API error.");
  console.dir(error);

  let status = _get(error, "status", 0);

  if (status === 401 || status === 403) {
    return Promise.reject();
  }

  return Promise.resolve();
}

function getPermissions() {
  // return Promise.resolve();
  const tokenData = getTokenData();
  if (tokenData) Promise.resolve(tokenData.roles);
  return Promise.reject("commons.generic_error");
}

export function sendRecoveryPassword({ email, reCaptcha }) {
  return authAPI.post(
    "/recover-password",
    { email, reCaptcha },
    { headers: { "Content-Language": getUserConfig("language") || "en" } }
  );
}

export function sendChangePassword({ plainPassword, profile, reCaptcha }) {
  return authAPI.post(
    "/change-password",
    {
      plainPassword,
      profile,
      reCaptcha,
    },
    {
      headers: { "Content-Language": getUserConfig("language") || "en" },
    }
  );
}

export default {
  login: doLogin,
  logout: doLogout,
  checkAuth: checkAuth,
  checkError: checkError,
  getPermissions: getPermissions,
};
