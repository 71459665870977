import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { Datagrid, DateField, TextField, useTranslate } from "react-admin";

import { MoreInfoButton } from "../../../components/buttons";
import RecoverTrashButton from "../../../components/buttons/RecoverTrashButton";
import { ImageField } from "../../../components/fields";
import List from "../../../components/ra-components/List";
import Actions from "./Actions";
import BulkActions from "./BulkActions";
import Filters from "./Filters";
import HistoryMobileList from "./MobileList";
import { useStyles } from "./styles";

const StatusField = (props) => {
  const {
    record: { status },
  } = props;
  const translate = useTranslate();

  return <span>{translate(`resource.history.status.${status}`)}</span>;
};

export default (props) => {
  const isTabletUpper = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const classes = useStyles();

  return (
    <List
      filters={<Filters />}
      actions={<Actions />}
      bulkActions={<BulkActions />}
      {...props}
    >
      {isTabletUpper ? (
        <Datagrid>
          <ImageField
            cellClassName={classes.cellImageField}
            source="publicImageResizes"
            size="small"
            label="commons.image"
            sortable={false}
          />
          <StatusField
            source="status"
            label="resource.history.processed"
            sortable={false}
          />
          <TextField
            cellClassName={classes.cellTextField}
            source="name"
            label="commons.name"
            noWrap
          />
          <TextField
            cellClassName={classes.cellTextField}
            source="user.email"
            label="commons.uploaded_by"
            noWrap
          />
          <DateField
            label="resource.history.date"
            source="createdAt"
            showTime
          />
          <MoreInfoButton small={!isDesktop} />
          <RecoverTrashButton
            deleteResource="images"
            recoverResource="images"
          />
        </Datagrid>
      ) : (
        <HistoryMobileList />
      )}
    </List>
  );
};
