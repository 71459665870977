import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import UsersIcon from "@material-ui/icons/GroupOutlined";
import GroupsIcon from "@material-ui/icons/GroupWorkOutlined";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import PhotoLibraryOutlinedIcon from "@material-ui/icons/PhotoLibraryOutlined";
import PublishIcon from "@material-ui/icons/Publish";

import { checkPermission } from "./roles";

export const menuConfig = {
  uploader: {
    label: "resource.uploader.menu",
    path: "/uploader",
    icon: PublishIcon,
  },
  history: {
    label: "resource.history.menu",
    path: "/history",
    icon: HistoryOutlinedIcon,
  },
  gallery: {
    label: "resource.gallery.menu",
    path: "/gallery",
    icon: PhotoLibraryOutlinedIcon,
  },
  users: {
    label: "resource.users.menu",
    path: `/users`,
    icon: UsersIcon,
  },
  groups: {
    label: "resource.groups.menu",
    path: `/groups`,
    icon: GroupsIcon,
  },
  tagger: {
    label: "resource.tagger.menu",
    path: "/tagger",
    icon: LocalOfferOutlinedIcon,
  },
  downloads: {
    label: "resource.downloads.menu",
    path: "/downloads",
    icon: GetAppOutlinedIcon,
  },
};

export function getMenu() {
  const permisionMenu = Object.keys(menuConfig).filter((item) =>
    checkPermission(item)
  );
  const menu = [];
  for (var i = 0; i < permisionMenu.length; i++) {
    menu.push(menuConfig[permisionMenu[i]]);
  }
  return menu;
}

export default menuConfig;
