export const PROJECT_PREFIX = "multimedia";

// Config (localStorage)
export const TOKEN_KEY = `${PROJECT_PREFIX}.auth.token`;
export const USER_CONFIG_KEY = `${PROJECT_PREFIX}.config.userData`;
export const CURRENT_SEASON = 9;
export const USERDATA_DATA_KEYS = [
  "theme",
  "email",
  "id",
  "language",
  "downloadOriginal",
  "downloadAppPlayer",
  "roles",
  "season_recent_id",
];
export const LOGOUT_USERDATA_DATA_KEYS = ["theme", "language"];

export const DOWNLOAD_NOTIFICATION_FILTER_STATUS = ["queued", "ready"];

export const ITEMS_PER_PAGE = [10, 20, 30];
export const ITEMS_PER_PAGE_DEFAULT = 10;
