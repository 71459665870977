import provider from "./dataProvider";
import httpClient from "./httpClient";
import publicHttpClient from "./publicHttpClient";

const dataProvider = provider(
  process.env.REACT_APP_DEFAULT_ENDPOINT,
  httpClient
);

const publicDataProvider = provider(
  process.env.REACT_APP_DEFAULT_ENDPOINT,
  publicHttpClient
);

export { dataProvider, publicDataProvider };
