import React from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const VerticalButton = (props) => {
  const { icon, text, ...buttonProps } = props;
  const classes = useStyles();

  return (
    <Button {...buttonProps}>
      <span className={classes.contentRoot}>
        {icon}
        {text}
      </span>
    </Button>
  );
};

export default VerticalButton;
