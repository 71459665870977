import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import React from "react";
import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  TextField,
  useTranslate,
} from "react-admin";

export const useStyles = makeStyles(
  (theme) => ({
    listPermissions: {
      "& > ul > li > p": {
        display: "flex",
        alignItems: "center",
        paddingTop: 0,
      },
      "& > ul > li > section, & > ul > li > p": {
        display: "flex",
        alignItems: "center",
      },
      margin: 0,
      "& > ul": {
        marginTop: 0,
      },
      "& > ul > li": {
        padding: theme.spacing(1.5, 0, 1.5, 1),
        display: "flex!important",
      },
      "& > ul > li > span": {
        padding: 0,
      },
    },
  }),
  { name: "WBO-ListPermissions" }
);

const ListPermissions = ({ label, source }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-listPermissions"
        id="panel-listPermissions-header"
      >
        <Typography>{translate(label)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: "100%" }}>
          <ArrayInput
            label=""
            source={source}
            className={classes.listPermissions}
          >
            <SimpleFormIterator disableAdd>
              <FormDataConsumer>
                {({ formData, getSource, scopedFormData, ...rest }) => {
                  return (
                    <TextField
                      source={getSource("name")}
                      record={formData}
                      key={getSource("id")}
                    />
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

ListPermissions.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
};

export default ListPermissions;
