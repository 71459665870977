import { Button, IconButton } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import {
  email,
  required,
  useLocale,
  useLogin,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { PASSWORD_RECOVERY_FORM } from "..";
import Modal from "../../../components/custom/Modal";
import { TextInput } from "../../../components/inputs";
import CheckboxInput from "../../../components/inputs/CheckboxInput";
import PasswordInput from "../../../components/inputs/PasswordInput";
import imageTermsOfUse from "../../../config/translations/imageTermsOfUse";
import { useStyles } from "../styles";

const LoginForm = ({ setPageView, setLoading }) => {
  const classes = useStyles();
  const login = useLogin();
  const notify = useNotify();
  const locale = useLocale();
  const translate = useTranslate();
  const redirect = useRedirect();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [token, setToken] = useState();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const refreshToken = useCallback(() => {
    if (executeRecaptcha) {
      executeRecaptcha("login_form").then((result) => {
        setToken(result);
      });
    }
  }, [executeRecaptcha]);

  const handleFormSubmit = useCallback(
    (formData) => {
      setLoading(true);

      if (formData.reCaptcha) {
        login(formData).catch((err) => {
          console.log("login error: ", err);
          notify(err, "warning");
          setLoading(false);
          refreshToken();
        });
      } else {
        notify("recaptcha error", "warning");
        setLoading(false);
        refreshToken();
      }
    },
    [login, notify, setLoading, refreshToken]
  );

  return (
    <Form onSubmit={handleFormSubmit}>
      {({ handleSubmit, form, values }) => {
        if (!values.reCaptcha || values.reCaptcha !== token) {
          if (!token) {
            refreshToken();
          }
          form.change("reCaptcha", token);
        }

        return (
          <form onSubmit={handleSubmit}>
            <TextInput
              source="username"
              label="commons.e-mail"
              validate={[required(), email()]}
            />
            <PasswordInput
              source="password"
              label={translate("login.password")}
              validate={required()}
            />
            <div className={classes.passwordActions}>
              <Link
                color="primary"
                onClick={() => setPageView(PASSWORD_RECOVERY_FORM)}
              >
                {translate("login.password_forgot")}
              </Link>
            </div>
            <div>
              <CheckboxInput
                source="terms_of_use"
                label={
                  <span>
                    {translate("login.terms_of_use.start")}{" "}
                    <span
                      className={classes.termsLink}
                      role="button"
                      onClick={(e) => {
                        setIsTermsModalOpen(true);
                        e.preventDefault();
                      }}
                    >
                      {translate("login.terms_of_use.link")}
                    </span>{" "}
                    {translate("login.terms_of_use.end")}
                  </span>
                }
                validate={required()}
                className={classes.checkbox}
              />
              <Modal
                open={isTermsModalOpen}
                onClose={() => setIsTermsModalOpen(false)}
                scroll="body"
                maxWidth="lg"
              >
                <div className={classes.termsContent}>
                  {imageTermsOfUse[locale]}
                </div>
                <div className={classes.termsModalClose}>
                  <IconButton onClick={() => setIsTermsModalOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </Modal>
            </div>
            <div className={classNames(classes.buttons, classes.loginButtons)}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                {translate("login.login")}
              </Button>
            </div>
            <div className={classNames(classes.buttons, classes.loginButtons)}>
              <Button
                href={process.env.REACT_APP_AZURE_LOGIN}
                color="secondary"
                variant="contained"
                fullWidth
              >
                {translate("login.login_with_azure")}
              </Button>
            </div>
            <div className={classNames(classes.buttons, classes.loginButtons)}>
              <Button
                onClick={() => redirect("/register")}
                color="primary"
                fullWidth
              >
                {translate("register.request_access")}
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

export default LoginForm;
