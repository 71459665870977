import "normalize.css";

import dotenv from "dotenv";
import React from "react";
import {
  AdminContext as RaAdminContext,
  AdminUI as RaAdminUI,
} from "react-admin";
import { useSelector } from "react-redux";

import adminConfig from "./config/admin";
import { getResources } from "./config/resources";
import { checkToken } from "./providers/authProviderToken";
import { ifPublicPage } from "./utils";

dotenv.config();

const App = () => <Admin />;

const Admin = () => {
  if (!checkToken() && !ifPublicPage()) {
    window.location.href = "/#/login";
  }

  return (
    <RaAdminContext {...adminConfig}>
      <AdminUI />
    </RaAdminContext>
  );
};

const AdminUI = () => {
  useSelector((state) => Object.keys(state.admin.resources).length);
  return <RaAdminUI {...adminConfig}>{getResources()}</RaAdminUI>;
};

export default App;
