import { makeStyles } from "@material-ui/core";
import React from "react";
import { Layout, useSetLocale } from "react-admin";
import { useDispatch, useSelector } from "react-redux";

import { breakpoints, change_theme, themes } from "../../../config/theme";
import { change_language } from "../../../redux/actions/language";
import { getUserConfig } from "../../../utils/userConfig";
import Footer from "../../custom/Footer";
import AppBar from "./AppBar";
import CustomSidebar from "./Sidebar";
import CustomMenu from "./Sidebar/Menu";

const useCustomLayoutStyles = makeStyles({
  root: {
    minHeight: "0px",
    height: "100%",
    flexGrow: 1,
  },
  appFrame: {
    flexGrow: "1",
    marginTop: (theme) => theme.values.toolbar.height.xs,
    [breakpoints.up("sm")]: {
      marginTop: (theme) => theme.values.toolbar.height.sm,
    },
    [breakpoints.up("lg")]: {
      marginTop: (theme) => theme.values.toolbar.height.lg,
    },
  },
  content: {
    padding: "0px",
  },
});

const CustomLayout = (props) => {
  const setLocale = useSetLocale();
  const dispatch = useDispatch();
  const themeKey = useSelector((state) => state.theme);
  const theme = themes[themeKey];
  const themeLS = getUserConfig("theme");

  if (themeKey !== themeLS) {
    dispatch(change_theme(themeLS));
  }

  const language = useSelector((state) => state.language);
  const languageLS = getUserConfig("language");

  if (languageLS && language !== languageLS) {
    dispatch(change_language(languageLS));
    setLocale(languageLS);
  }

  return (
    <>
      <Layout
        {...props}
        classes={useCustomLayoutStyles(theme)}
        menu={CustomMenu}
        sidebar={CustomSidebar}
        appBar={AppBar}
        theme={theme}
      />
      <Footer theme={theme} />
    </>
  );
};

export default CustomLayout;
