import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
      height: "100%",
      background: theme.palette.background.darkPaper,
      transition: "box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    content: {
      padding: theme.spacing(1),
    },
    imageSelected: {
      boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
    },
    modalImg: {
      maxWidth: "100%",
      height: "auto",
      verticalAlign: "middle",
      maxHeight: "calc(100vh - 96px)", // modal has 32px margintop, 32px marginbottom, 16px paddingtop and 16px paddingbottom, so 96 total.
    },
    cardImage: {
      cursor: "pointer",
    },
    modalClose: {
      position: "absolute",
      top: 24,
      right: 24,
      color: theme.palette.common.white,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      },
    },
    zoomInButton: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 11,
      color: theme.palette.common.white,
      padding: 9,
    },
    titleContainer: {
      display: "flex",
      maxWidth: `calc(100vw - ${theme.spacing(4)}px)`,
      alignItems: "center",
      "& > div": {
        "&:first-child": {
          width: ({ moreInfo }) => (moreInfo ? "calc(100% - 40px)" : "100%"),
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
    },
  }),
  { name: "wbo-MediaCard" }
);

export const useCheckboxClasses = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 11,
    color: theme.palette.common.white,
  },
}));

export const useShadowClasses = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: 42,
    background:
      "linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)",
    zIndex: 10,
    cursor: "pointer",
  },
}));
