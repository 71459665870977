export const config = {
  season: {
    children: [
      {
        type: "championship",
        filter: "championship.season",
        filterValue: "season.id",
      },
      {
        type: "allEvent",
        filter: "season",
        filterValue: "season.id",
        aditionalFilter: { allEvent: true },
      },
    ],
  },
  championship: {
    children: [
      {
        type: "gameweek",
        filter: "gameweek.championship",
        filterValue: "championship.id",
      },
    ],
  },
  gameweek: {
    children: [
      {
        type: "match",
        filter: "match.gameweek",
        filterValue: "gameweek.id",
      },
    ],
  },
  match: {
    children: false,
  },
  team: {
    children: false,
  },
  allEvent: {
    children: [
      {
        type: "event",
        filter: "event.season",
        filterValue: "season.id",
      },
    ],
  },
  event: {
    children: [
      {
        type: "act",
        filter: "act.event",
        filterValue: "event.id",
      },
    ],
  },
  act: {
    children: false,
  },
};

export const isChecked = (idPermission, arrayPermissions) => {
  if (arrayPermissions) {
    return arrayPermissions.some((element) => element.id === idPermission);
  } else {
    return false;
  }
};

export const addPermission = (permision, arrayPermissions, type) => {
  let arrayNewPermissions = [];

  if (arrayPermissions) {
    switch (type) {
      case "allEvent":
        arrayNewPermissions = arrayPermissions.filter((element) => {
          return (
            !element.event ||
            (element.event && element.season?.id !== permision.season.id)
          );
        });
        break;
      default:
        arrayNewPermissions = arrayPermissions.filter((element) => {
          return element[type]?.id !== permision[type].id;
        });
        break;
    }
  }

  arrayNewPermissions.push({ ...permision });
  return arrayNewPermissions;
};

export const removePermission = (permision, arrayPermissions) => {
  let arrayNewPermissions = [];

  if (arrayPermissions) {
    arrayNewPermissions = arrayPermissions.filter((element) => {
      return element.id !== permision.id;
    });
  }

  return arrayNewPermissions;
};
