import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.common.white,
  },
  menuRoot: {
    width: 300,
  },
  menuTitleBar: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1, 2.5),
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.contrastText,
  },
  titleIconAlign: {
    marginLeft: -4,
    marginRight: theme.spacing(1),
  },
}));

export const useDownloadStatusItemClasses = makeStyles(
  (theme) => ({
    root: {
      fontSize: 14,
      padding: theme.spacing(0, 2),
      "& + & > div": {
        borderTop: "1px solid " + theme.palette.background.sidebar,
      },
      display: "flex",
      alignItems: "center",
    },
    content: {
      padding: theme.spacing(1, 0.5),
      flexGrow: 1,
    },
    infoRow: {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      "& + &": {
        paddingTop: theme.spacing(0.5),
      },
    },
    downloadTitle: {
      fontSize: 16,
    },
    downloadDate: {
      color: theme.palette.text.secondary,
    },
    totalPhotos: {
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
    },
    downloadStatus: {
      display: "flex",
      alignItems: "center",
    },
    textQueued: {
      color: theme.palette.error.main,
    },
    textReady: {
      color: theme.palette.success.main,
    },
    circularProgressRoot: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1),
    },
  }),
  { name: "wbo-DownloadStatusItem" }
);
