import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    dialogContent: {
      marginBottom: `${theme.spacing(2)}px`,
      flex: "1 0 auto",
      textAlign: "center",
    },
    dialogButtons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: "0 0 auto",
      "& > * + *": {
        marginLeft: `${theme.spacing(2)}px`,
      },
    },
  }),
  {
    name: "WBODialog",
  }
);
