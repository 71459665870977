import { makeStyles, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import PerPageSelect from "./PerPageSelect";
import PaginationControls from "./PaginationControls";
import styles from "./styles";
import { useTranslate } from "react-admin";
import { useEffect } from "react";
import {
  ITEMS_PER_PAGE,
  ITEMS_PER_PAGE_DEFAULT,
} from "../../../../config/constants";

const useStyles = makeStyles(styles, { name: "WBOPagination" });

const Pagination = ({ total, page, perPage, setPage, setPerPage, ids }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const translate = useTranslate();

  useEffect(() => {
    if (!ITEMS_PER_PAGE.includes(perPage)) {
      setPerPage(ITEMS_PER_PAGE_DEFAULT);
    }
  }, [perPage, setPerPage]);

  if (!ids.length)
    return (
      <div className={classes.noResultsRoot}>
        {translate("commons.no_results")}
      </div>
    );

  return (
    <>
      {isMobile && (
        <div className={classNames(classes.rowPagination, classes.rowTop)}>
          <div className={classNames(classes.controls)}>
            <PaginationControls
              total={total}
              page={page}
              perPage={perPage}
              setPage={setPage}
            />
          </div>
        </div>
      )}
      <div className={classes.rowPagination}>
        {isDesktop && <PerPageSelect setPerPage={setPerPage} value={perPage} />}
        <div className={classes.controls}>
          <PaginationControls
            total={total}
            page={page}
            perPage={perPage}
            setPage={setPage}
          />
        </div>
      </div>
    </>
  );
};

Pagination.defaultProps = {};

Pagination.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
};

export default Pagination;
