import {
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import Downshift from "downshift";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useChoices,
  useDataProvider,
  useInput,
  useTranslate,
} from "react-admin";

import { IS } from "../../../utils";
import { useStyles } from "./styles";

const AutoCompleteSingleInput = ({
  optionText,
  optionValue,
  searchResource,
  searchProperty,
  translateChoice,
  choices: propChoices,
  ...rest
}) => {
  const [value, setValue] = useState(rest.value || "");
  const [choices, setChoices] = useState(propChoices || []);
  const [selectedItem, setSelectedItem] = useState(null);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const inputRef = useRef();

  let { disabled, label, name, source } = rest;
  name = name || source;
  label =
    (label && translate(label, { _: label })) ||
    (name && translate(name, { _: name }));

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({ ...rest, name });

  const inputError = touched && error;

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  const renderMenuItemOption = useCallback((choice) => getChoiceText(choice), [
    getChoiceText,
  ]);

  useEffect(() => {
    if (input.value) {
      if (propChoices) {
        const selectedItem = propChoices.find(
          (choice) => getChoiceValue(choice) === input.value
        );
        setSelectedItem(selectedItem);
        setValue(renderMenuItemOption(selectedItem) || "");
      } else {
        setLoading(true);
        dataProvider
          .getOne(searchResource, { id: input.value })
          .then(({ data }) => {
            setSelectedItem(data);
            setValue(renderMenuItemOption(data) || "");
            setLoading(false);
          });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (input.value !== value) {
      setValue(input.value || "");
    }

    if (input.value) {
      if (propChoices) {
        const selectedItem = propChoices.find(
          (choice) => getChoiceValue(choice) === input.value
        );
        setSelectedItem(selectedItem);
        setValue(renderMenuItemOption(selectedItem) || "");
      } else {
        setLoading(true);
        dataProvider
          .getOne(searchResource, { id: input.value })
          .then(({ data }) => {
            setSelectedItem(data);
            setValue(renderMenuItemOption(data) || "");
            setLoading(false);
          });
      }
    } else {
      setSelectedItem(null);
    }

    // eslint-disable-next-line
  }, [input.value]);

  const searchValue = useCallback(
    (value) => {
      if (!propChoices) {
        setLoading(true);
        dataProvider
          .getList(searchResource, {
            sort: {},
            pagination: { page: 1, perPage: 10 },
            filter: { [searchProperty]: value },
          })
          .then((result) => {
            setChoices(result.data);
            setLoading(false);
          });
      }
    },
    [dataProvider, searchProperty, searchResource, propChoices]
  );
  const searchValueDebounced = useMemo(() => _debounce(searchValue, 300), [
    searchValue,
  ]);

  const onSelect = useCallback(
    (selectedItem) => {
      inputRef.current.blur();

      setSelectedItem(selectedItem);
      setValue(selectedItem[optionText] || "");
      input.onChange(getChoiceValue(selectedItem));
    },
    [input, optionText, getChoiceValue]
  );

  const onInputValueChange = useCallback(
    (newValue) => {
      if (newValue !== "[object Object]") {
        if (value !== newValue) {
          setValue(newValue || "");

          if (newValue && newValue.length >= 3) searchValueDebounced(newValue);
        }
      }
    },
    [value, searchValueDebounced]
  );

  const onFocus = useCallback(() => {
    if (!focused) {
      setFocused(true);
    }

    if (input.onFocus) {
      input.onFocus();
    }
  }, [focused, input]);

  const onBlur = useCallback(() => {
    if (focused) {
      setFocused(false);
    }

    if (!selectedItem) {
      setValue("");
    } else if (!value) {
      setSelectedItem(null);
      input.onChange(null);
    }

    if (!propChoices) {
      setChoices([]);
    }

    if (input.onBlur) {
      input.onBlur();
    }
  }, [focused, input, selectedItem, value, propChoices]);

  return (
    <Downshift
      onChange={onSelect}
      onInputValueChange={onInputValueChange}
      inputValue={!IS.number(value) ? value : ""}
      selectedItem={selectedItem}
      itemToString={(item) => renderMenuItemOption(item)}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        getRootProps,
      }) => (
        <div className={classes.inputBox}>
          <div>
            <FormControl
              {...getRootProps(
                {
                  className: classes.inputWrapper,
                  error: !!inputError,
                  disabled,
                  required: isRequired,
                },
                { suppressRefError: true }
              )}
            >
              {label && (
                <InputLabel htmlFor={input.name}>
                  {translate(label, { _: label })}
                </InputLabel>
              )}
              <TextField
                {...getInputProps({
                  id,
                  label,
                  name,
                  disabled,
                  style: { width: "100%" },
                  inputRef,
                  onFocus,
                  onBlur,
                  InputProps: {
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  },
                })}
              />
              {loading && <LinearProgress className={classes.linearProgress} />}
            </FormControl>
          </div>
          {(isOpen || focused) && choices && choices.length > 0 && (
            <List className={classes.itemsList} {...getMenuProps()}>
              {choices
                .filter((item) => {
                  const text = renderMenuItemOption(item);
                  if (text && value) {
                    return text.toLowerCase().includes(value.toLowerCase());
                  }
                  return false;
                })
                .map((item, index) => {
                  const label = renderMenuItemOption(item);
                  return (
                    <ListItem
                      key={getChoiceValue(item)}
                      className={classes.item}
                      title={label}
                      {...getItemProps({
                        key: getChoiceValue(item),
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index && "rgba(0, 0, 0, 0.08)",
                        },
                      })}
                    >
                      <Typography noWrap>{label}</Typography>
                    </ListItem>
                  );
                })}
            </List>
          )}
        </div>
      )}
    </Downshift>
  );
};

AutoCompleteSingleInput.defaultProps = {
  onSelect: () => {},
  onChange: () => {},
  optionText: "name",
  optionValue: "id",
  selectedItem: {},
  translateChoice: true,
  value: "",
};

AutoCompleteSingleInput.propTypes = {
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
  searchResource: PropTypes.string,
  searchProperty: PropTypes.string,
  translateChoice: PropTypes.bool,
};

export default AutoCompleteSingleInput;
