import { FormControl, Grid, InputLabel, NativeSelect } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReferenceInput, useTranslate } from "react-admin";
import { useForm } from "react-final-form";

import { MatchInput, SelectInput } from "../../../components/inputs";

export default () => {
  const translate = useTranslate();
  const form = useForm();
  const [type, setType] = useState("match");

  useEffect(() => {
    switch (type) {
      case "match":
        form.change("event", null);
        form.change("act", null);
        break;
      default:
        break;
    }
  }, [type, form]);

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item xs={12} sm={4}>
        <FormControl style={{ marginBottom: "4px" }}>
          <InputLabel htmlFor="select-type">
            {translate("commons.type")}
          </InputLabel>
          <NativeSelect
            value={type}
            onChange={(e) => setType(e.target.value)}
            inputProps={{
              name: "type",
              id: "select-type",
            }}
          >
            <option value="match">{translate("commons.match")}</option>
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReferenceInput
          label="commons.season"
          source="season"
          reference="seasons"
        >
          <SelectInput />
        </ReferenceInput>
      </Grid>
      {type === "match" && (
        <Grid item xs={12}>
          <MatchInput
            sourceSeason="season"
            sourceChampionship="championship"
            sourceGameweek="gameweek"
            sourceMatch="match"
          />
        </Grid>
      )}
    </Grid>
  );
};
