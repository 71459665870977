import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";

import { getImageResize } from "../../../utils";
import sanitizeRestProps from "./sanitizeRestProps";
import { useStyles } from "./styles";

const ImageField = (props) => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    record,
    source,
    size,
    src,
    title,
    ...rest
  } = props;
  let sourceValue = get(record, source);
  sourceValue = getImageResize(sourceValue, size);
  const classes = useStyles(props);
  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...sanitizeRestProps(rest)} />
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <ul
        className={classnames(classes.list, className)}
        {...sanitizeRestProps(rest)}
      >
        {sourceValue.map((file, index) => {
          const fileTitleValue = get(file, title) || title;
          const srcValue = get(file, src) || title;

          return (
            <li key={index}>
              <img
                alt={fileTitleValue}
                title={fileTitleValue}
                src={srcValue}
                className={classes.image}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  const titleValue = get(record, title) || title;

  return (
    <div
      className={classnames(classes.root, { className: !!className })}
      {...sanitizeRestProps(rest)}
    >
      <img
        title={titleValue}
        alt={titleValue}
        src={sourceValue}
        className={classes.image}
      />
    </div>
  );
};

// wat? TypeScript looses the displayName if we don't set it explicitly
ImageField.displayName = "ImageField";

ImageField.defaultProps = {
  addLabel: true,
};

ImageField.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
};

export default ImageField;
