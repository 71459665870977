import React, { useState, useCallback } from "react";
import { useTranslate } from "react-admin";
import { useGetDownloadURL, useDownloadsUpdate } from "./downloadsHooks";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { Button, IconButton } from "@material-ui/core";

const DownloadButton = (props) => {
  const { record, iconButton, className } = props;
  const translate = useTranslate();
  const getDownloadURL = useGetDownloadURL();
  const downloadsUpdate = useDownloadsUpdate();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDownloadClick = useCallback(() => {
    if (record.status === "ready" || record.status === "downloaded") {
      setIsDisabled(true);

      getDownloadURL(record.hash)
        .then((r) => {
          setIsDisabled(false);
          const downloadUrl = r?.data?.url;
          if (downloadUrl) {
            window.open(downloadUrl, "_blank");
          }
          downloadsUpdate(record["@id"]);
        })
        .catch((e) => setIsDisabled(false));
    }
  }, [record, downloadsUpdate, getDownloadURL]);

  const isDownloadDisabled =
    isDisabled || (record.status !== "ready" && record.status !== "downloaded");

  if (iconButton) {
    return (
      <IconButton
        size="small"
        onClick={handleDownloadClick}
        disabled={isDownloadDisabled}
        edge="end"
        className={className}
      >
        <GetAppOutlinedIcon />
      </IconButton>
    );
  } else {
    return (
      <Button
        onClick={handleDownloadClick}
        startIcon={<GetAppOutlinedIcon />}
        disabled={isDownloadDisabled}
        className={className}
      >
        {translate("resource.downloads.download")}
      </Button>
    );
  }
};

export default DownloadButton;
