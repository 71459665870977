import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import {
  DateField,
  ReferenceField,
  TextField,
  useDataProvider,
  useListContext,
  useTranslate,
} from "react-admin";

import { getImageResize, getInfoFromMetadata } from "../../../utils";
import { useDrawerContentStyles, useDrawerStyles } from "./styles";

const MoreInfoDrawer = ({ record, open, setOpen }) => {
  const resource = "images";
  const [data, setData] = useState(record);
  const translate = useTranslate();

  const dataProvider = useDataProvider();
  const drawerClasses = useDrawerStyles();
  const drawerContentClasses = useDrawerContentStyles();
  const { filterValues } = useListContext();

  useEffect(() => {
    if (!data.metadatas && open) {
      const isTrash = !!filterValues.trashed;

      let params = { id: data.id };
      if (isTrash) {
        params.filter = { trashed: 1 };
      }

      dataProvider.getOne(resource, params).then(({ data }) => {
        setData(data);
      });
    }
  }, [open, setData, data.id, dataProvider, data, filterValues.trashed]);

  return (
    <Drawer
      classes={drawerClasses}
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      <div className={drawerContentClasses.container}>
        <div className={drawerContentClasses.titleContainer}>
          <h3>{translate("resource.history.advanced_info")}</h3>
          <Button
            onClick={() => setOpen(false)}
            className={drawerContentClasses.closeButton}
          >
            <Close />
          </Button>
        </div>
        <div className={drawerContentClasses.content}>
          <img
            src={getImageResize(data.publicImageResizes, "medium")}
            alt={data.name}
            title={data.name}
          />
          <div className={drawerContentClasses.text}>
            <Typography variant="h6">{translate("commons.title")}</Typography>
            <Typography>{data.title}</Typography>
          </div>
          <div className={drawerContentClasses.text}>
            <Typography variant="h6">{translate("commons.name")}</Typography>
            <Typography>{data.name}</Typography>
          </div>
          {data.reference && (
            <div className={drawerContentClasses.text}>
              <Typography variant="h6">
                {translate("commons.reference")}
              </Typography>
              <Typography>{data.reference}</Typography>
            </div>
          )}
          {data?.user?.email && (
            <div className={drawerContentClasses.text}>
              <Typography variant="h6">
                {translate("commons.uploaded_by")}
              </Typography>
              <Typography>{data.user.email}</Typography>
            </div>
          )}
          <div className={drawerContentClasses.text}>
            <Typography variant="h6">
              {translate("resource.history.date")}
            </Typography>
            <DateField source="createdAt" record={data} showTime />
          </div>
          <div className={drawerContentClasses.text}>
            <Typography variant="h6">
              {translate("commons.metadatas")}
            </Typography>
            <table cellSpacing="0" className={drawerContentClasses.table}>
              <thead>
                <tr>
                  <th>{translate("commons.type")}</th>
                  <th>{translate("commons.value")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.metadatas?.map((metadata, index) => {
                  const { resource, source } = getInfoFromMetadata(metadata);

                  return (
                    <tr key={index}>
                      <td>
                        {translate(`commons.${metadata.metadataType.name}`, {
                          _: metadata.metadataType.name,
                        })}
                      </td>
                      <td>
                        <ReferenceField
                          record={data}
                          source={`metadatas[${index}].value`}
                          reference={resource}
                          basePath={resource}
                          link={false}
                        >
                          <TextField source={source} />
                        </ReferenceField>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div></div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default MoreInfoDrawer;
