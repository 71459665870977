import {
  SET_ERROR_UPLOADER,
  UPDATE_UPLOADER_IMAGES,
} from "../actions/uploader";

export const uploaderReducer = (
  state = {
    queueImages: [],
    processingImages: [],
    readyImages: [],
    failedImages: [],
    disabled: true,
    error: false,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_UPLOADER_IMAGES: {
      return { ...state, ...action.payload };
    }
    case SET_ERROR_UPLOADER: {
      return { ...state, error: action.payload };
    }
    default: {
      return state;
    }
  }
};
