import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      // display: "flex"
    },
    buttonPanel: {
      padding: theme.spacing(1, 0),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.up("sm")]: {
        // justifyContent: "flex-end",
        "& > button": {
          marginLeft: theme.spacing(),
        },
      },
    },
    showTaggedRoot: {
      color: theme.palette.text.primary,
      "& > .MuiFormControlLabel-label": {
        display: "flex",
      },
    },
    actionsLeft: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    textResult: {
      paddingLeft: theme.spacing(2),
      color: theme.palette.text.primary,
    },
  }),
  { name: "wbo-Tagger-BulkActions" }
);

export const useMatchActionsStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  matchActions: {
    "& > button": {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  },
  tagActions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
    "& > button": {
      marginBottom: theme.spacing(0.5),
      flex: "1 1 32%",
      "& .MuiButton-startIcon": {
        display: "none",
      },
      [theme.breakpoints.up("sm")]: {
        flex: "1 1 auto",
        marginRight: theme.spacing(0.5),
      },
      [theme.breakpoints.up("md")]: {
        flex: "0 1 auto",
        "& .MuiButton-startIcon": {
          display: "inherit",
        },
      },
    },
  },
  buttonTagMatch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > span": {
      color: theme.palette.text.primary,
    },
  },
}));
