export default {
  commons: {
    theme_mode: "%{theme} mode",
    generic_error: "An error has occurred.",
    settings: "Settings",
    basic_settings: "Basic Settings",
    saved_settings: "Saved Settings",
    name: "Name",
    name_or_title: "Name / Title",
    email: "Email",
    "e-mail": "E-mail",
    notifications: "Notifications",
    receiving_notifications: "Receiving notifications",
    request_to_receive_notifications: "Request to receive notifications",
    theme: "Theme",
    light: "Light",
    dark: "Dark",
    english: "English",
    spanish: "Spanish",
    language: "Language",
    cancel: "Cancel",
    title: "Title",
    description: "Description",
    author: "Author",
    or: "or",
    type: "Type",
    season: "Season",
    seasons: "Seasons",
    competition: "Competition",
    competitions: "Competitions",
    gameweek: "Gameweek",
    gameweeks: "Gameweeks",
    match: "Match",
    matches: "Matches",
    team: "Team",
    teams: "Teams",
    event: "Event",
    events: "Events",
    act: "Act",
    acts: "Acts",
    size: "Size",
    select_all: "Select all",
    deselect_all: "Deselect all",
    deselect_count: "Deselect (%{count})",
    action_create: "Add",
    save: "Save",
    create: "Create",
    edit: "Edit",
    user: "User",
    image: "Image",
    list: "List",
    trash: "Trash",
    show_list: "Show list",
    show_trash: "Show trash",
    delete: "Delete",
    championship: "Competition",
    championships: "Competitions",
    other: "Other",
    others: "Others",
    player: "Player",
    players: "Players",
    profile: "Profile",
    disable: "Disable",
    enable: "Enable",
    reactivate: "Reactivate",
    move_to_trash: "Move to trash",
    recover: "Recover",
    elements_per_page: "Elements per page: ",
    no_results: "No results found",
    generic_tags: "Generic tags",
    date: "Date",
    date_range: "Date range",
    bulk_actions: "Bulk actions",
    homePlayers: "Home players",
    awayPlayers: "Away players",
    no_coincidences: "There are no coincidences...",
    id: "Id",
    url: "Url",
    metadatas: "Metadatas",
    value: "Value",
    not_selected: "Not selected",
    back: "Back",
    go_back: "Go back",
    uploaded_by: "Uploaded by",
    reference: "Reference",
    state: "State",
    user_active: {
      inactive: "Inactive",
      active: "Active",
      pending: "Pending",
    },
    yes: "Yes",
    no: "No",
  },
  resource: {
    history: {
      menu: "History",
      advanced_info: "Advanced information",
      date: "Date",
      processed: "Processed",
      notify: {
        deleted: "Images deleted",
        recovered: "Images recovered",
      },
      dialog_delete: {
        counter_html:
          "<b>%{smart_count} photo</b> will be moved to the trash |||| <b>%{smart_count} photos</b> will be moved to the trash",
        advice_html:
          "You can recover your photos whenever you want with the <b>recovery</b> option in the <b>Trash</b> section",
      },
      dialog_recover: {
        counter_html:
          "<b>%{smart_count} photo</b> will be recovered |||| <b>%{smart_count} photos</b> will be recovered",
      },
      status: {
        ready: "Yes",
        pending: "Pending",
      },
    },
    images: {
      notify: {
        deleted: "Images deleted",
        recovered: "Images recovered",
        selectLimit: "Only a maximum of 40 images can be selected",
      },
    },
    users: {
      create_title: "New User",
      create_button: "Create User",
      edit_title: "User %{id}",
      edit_button: "Save User",
      menu: "Users",
      email: "Email",
      active: "Active",
      action_create: "New user",
      active_users: "Active",
      inactive_users: "Inactive",
      active_user: "Active",
      inactive_user: "Inactive",
      roles: "Roles",
      groups: "Groups",
      real_image: "Real image",
      downloadOriginal: "Download Original",
      downloadAppPlayer: "App Player",
      state: "State",
      partnership: "Partnership",
      typeOfpartnership: "Type of partnership",
      internalEventPermission: "Internal events",
      change_password: "Change password",
      dowloadPermissionTitle: "Permisos de descarga",
      notify: {
        user_active: "User activated",
        user_disable: "User disabled",
        deleted: "User deleted",
        recovered: "User recovered",
      },
      dialog_disable: {
        counter_html:
          "Will be deactivated <b>%{smart_count} user</b> |||| will be deactivated <b>%{smart_count} users</b>",
        advice_html:
          "You can reactivate them by filtering in the section Users by status: inactive and selecting the option Reactivate",
      },
      dialog_enable: {
        counter_html:
          "Will be reactivated <b>%{smart_count} user</b> |||| will be reactivated <b>%{smart_count} users</b>",
      },
      dialog_delete: {
        counter_html:
          "<b>%{smart_count} user</b> will be moved to the trash |||| <b>%{smart_count} users</b> will be moved to the trash",
        advice_html:
          "You can recover your users whenever you want with the <b>recovery</b> option in the <b>Trash</b> section",
      },
      dialog_recover: {
        counter_html:
          "<b>%{smart_count} user</b> will be recovered |||| <b>%{smart_count} users</b> will be recovered",
      },
      validations: {
        partnershipDescription: {
          required: "Indicate the name of the media, club, press or sponsor",
        },
        userGrops: {
          required: "Not assigned to at least 1 group",
        },
      },
    },
    roles: {
      ROLE_ADMIN: "Admin",
      ROLE_GLOBAL: "Multimedia",
      ROLE_PHOTOGRAPHER: "Photographer",
      ROLE_TAGGER: "Tagger",
      ROLE_MULTIMEDIA: "Manager",
      ROLE_USER: "User",
    },
    gallery: {
      menu: "Galleries",
      selected_images:
        "%{smart_count} photo selected |||| %{smart_count} photos selected",
      download_originals: "Originals",
      download_reduced: "Reduced",
      downloads: "Downloads",
      compressing_zip: "Compressing ZIP",
      completed: "Ready",
      notify_download_start:
        "Your download has started processing. You can see its progress by tapping the notification icon on the top bar.",
    },
    groups: {
      action_create: "New Group",
      create_title: "New Group",
      create_button: "Create Group",
      edit_title: "Group %{id}",
      edit_button: "Save Group",
      menu: "Groups",
      name: "Name",
      "assigned-permissions": "Assigned Permissions",
      dialog_delete: {
        counter_html:
          "<b>%{smart_count} group</b> will be moved to the trash |||| <b>%{smart_count} groups</b> will be moved to the trash",
        advice_html:
          "You can recover your groups whenever you want with the <b>recovery</b> option in the <b>Trash</b> section",
      },
      dialog_recover: {
        counter_html:
          "<b>%{smart_count} group</b> will be recovered from the trash |||| <b>%{smart_count} groups</b> will be recovered from the trash",
      },
      notify: {
        recovered: "Groups recovered",
        deleted: "Groups deleted",
      },
    },
    uploader: {
      menu: "Uploader",
      match_act_advise: "Select a match or act to upload photos",
      select_file: "Select a file",
      select_file_mobile: "Select photos from the device",
      drag_and_drop: "Drag and drop the images here",
      in_queue: "In queue",
      in_process: "In process",
      ready: "Ready",
      failed: "Failed",
      upload_photos: "Upload photos",
      photos_in_queue:
        "%{smart_count} photo in queue |||| %{smart_count} photos in queue",
      ready_photos:
        "%{smart_count} photo ready |||| %{smart_count} photos ready",
      failed_photos:
        "%{smart_count} photo failed |||| %{smart_count} photos failed",
      uploading: "Uploading",
      file_size_error: "%{file} exceeds size limit",
      file_number_error: "Can't upload more than %{limit} photos",
      show_tagged: "Show tagged images",
      duplicate_images:
        "You have dragged images that are already on one of the lists",
      invalid_type_error: '"%{file}" file type is invalid.',
    },
    tagger: {
      menu: "Tagger",
      action_tagger: "Tagger",
      quick_tagging: "Quick tagging",
      advancedTagger: {
        title: "Advanced tagger",
        notImagesSelected: "Select one or more images to tag",
        selectedTags: "Assigned labels:",
        searchTags: "Search tags",
        tabGeneric: "Generic",
        tabHomeTeam: "Home",
        tabAwayTeam: "Away",
        tagged: "Tagged",
      },
    },
    downloads: {
      menu: "Downloads",
      download: "Download",
      download_singular: "Download",
      queued: "Queued",
      ready: "Ready",
      downloaded: "Downloaded",
      expired: "Expired",
      status: "Status",
      created_at: "Created at",
      error: "Error",
      go_to_downloads: "Go to downloads page",
    },
  },
  login: {
    login: "Login",
    login_error: "You're username or password is incorrect. Please, try again.",
    username: "Username",
    session_expired: "You're session has expired.",
    current_password: "Current password",
    password: "Password",
    repeat_password: "Repeat password",
    password_changed: "Password changed",
    remember_me: "Remember me",
    password_forgot: "I forgot my password",
    password_complexity:
      "The password must have at least 1 lower case, 1 upper case, 1 special character, 1 number, no space and a length of between 8 and 15 characters.",
    password_complexity_failed: "The password does not meet the complexity",
    terms_of_use: {
      start: "I accept the",
      link: "terms of use",
      end: "for LALIGA images.",
    },
    login_with_azure: "Login with Azure",
    password_recovery: "Password recovery",
    send_email: "Send email",
    password_recovery_complete_text: "Password recovery email sent",
    change_password: "Change password",
    recoverToken: "Code",
    repeat_password_text: "Passwords must match",
    check_email:
      "If your email is in the system, you will receive an email to recover your password.",
    login_page_failure:
      "Cannot login with your Office365 account at the moment, try again.",
  },
  register: {
    title: "Access to Multimedia Panel for the %{season_years} season",
    description:
      'To request access to the LALIGA image library (logos, brands, visuals, fonts, typefaces, ball, trophy, football jerseys, images of players, images of matches, images of training sessions, etc.) (hereinafter, the "Images").',
    request_access: "Request access",
    label: {
      name: "Name",
      partnershipDescription: "Partnership",
      typeOfpartnership: "Type of partnership",
      terms_of_use: {
        start: "I accept the",
        link: "Legal Terms and Conditions and the Privacy Policy",
      },
    },
  },
  components: {
    inputDateRange: {
      from: "from",
      to: "to",
    },
    filters: {
      filters: "Filters",
      see_results: "See %{smart_count} result |||| See %{smart_count} results",
      active_filters:
        "%{smart_count} filter active |||| %{smart_count} filters active",
      clean: "Clean",
      result_count: "%{smart_count} result |||| %{smart_count} results",
      clean_filters: "Clean filters",
    },
  },
  footer: {
    all_rights_reserved: "All rights reserved",
    privacy_and_cookie_policy: "Privacy and cookie policy",
    privacy_and_cookie_policy_href:
      "https://www.laliga.com/en-ES/legal/legal-web",
    privacy_settings: "Privacy Settings",
  },
  userMenu: {
    groups: "My groups",
    change_password: "Change password",
    logout: "Logout",
  },
};
