import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { List, useTranslate } from "react-admin";

import CustomActionToolbar from "../toolbars/CustomActionToolbar";
import Filters from "./Filters";
import CustomPagination from "./Pagination";
import SectionTitle from "../../custom/SectionTitle";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      "& > .MuiToolbar-root": {
        display: "block",
        paddingLeft: 0,
        "& + div > .MuiPaper-root": {
          backgroundColor: theme.palette.background.default,
          boxShadow: "none",
          marginTop: 0,
          paddingTop: 61,
          [theme.breakpoints.up("sm")]: {
            paddingTop: "0px",
          },
        },
        "& > span": {
          display: "none",
        },
      },
      "& .MuiTableCell-head": {
        zIndex: 2,
        position: "sticky",
        borderBottom: "none",
        "&:after": {
          content: "''",
          width: "100%",
          height: "2px",
          bottom: 0,
          left: 0,
          position: "absolute",
          background: "#E0E0E0",
        },
        top: `${theme.values.toolbar.height.xs}px`,
        [theme.breakpoints.up("sm")]: {
          top: `${theme.values.toolbar.height.sm}px`,
        },
        [theme.breakpoints.up("lg")]: {
          top: `${theme.values.toolbar.height.lg}px`,
        },
        backgroundColor: theme.palette.background.darkPaper,
      },
    },
    noCustomActions: {
      "& > .MuiToolbar-root": {
        display: "none",
      },
    },
  }),
  { name: "wbo-List" }
);

const CustomList = (props) => {
  const translate = useTranslate();
  const {
    actions,
    bulkActions,
    exportAction,
    filters,
    selectLimit,
    ...listProps
  } = props;
  const {
    hasCreate,
    pagination = <CustomPagination />,
    filterDefaultValues,
    setPerPage,
    perPage,
  } = listProps;
  const classes = useStyles();
  const hasCustomActions = filters || bulkActions || actions;

  return (
    <>
      <SectionTitle>{translate(props.options.label)}</SectionTitle>
      <List
        sort={{ field: "id", order: "DESC" }}
        {...listProps}
        filters={
          filters && (
            <Filters
              perPage={perPage}
              setPerPage={setPerPage}
              filterDefaultValues={filterDefaultValues}
            >
              {filters}
            </Filters>
          )
        }
        actions={
          <CustomActionToolbar
            actions={actions}
            bulkActions={bulkActions}
            createAction={hasCreate}
            selectLimit={selectLimit}
          />
        }
        pagination={pagination}
        bulkActionButtons={bulkActions ? null : false}
        className={classNames(classes.root, {
          [classes.noCustomActions]: !hasCustomActions,
        })}
      />
    </>
  );
};

export default CustomList;
