import { IconButton } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import React from "react";
import { Edit as RaEdit, SimpleForm, useTranslate } from "react-admin";
import { Link } from "react-router-dom";

import DefaultToolbar from "../toolbars/DefaultToolbar";
import { useStyles } from "./styles";

const EditTitle = ({ basePath, resource, record, titleSource }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <h1 className={classes.title}>
      <IconButton component={Link} to={basePath}>
        <NavigateBeforeIcon />
      </IconButton>
      {translate(`resource.${resource}.edit_title`, {
        _: translate("commons.edit"),
        id: record[titleSource],
      })}
    </h1>
  );
};

const Edit = ({ children, titleSource, ...rest }) => (
  <RaEdit {...rest}>
    <SimpleForm toolbar={<DefaultToolbar edit />}>
      <EditTitle titleSource={titleSource} />
      {React.cloneElement(children, { isCreate: false })}
    </SimpleForm>
  </RaEdit>
);

Edit.defaultProps = {
  titleSource: "name",
};

export default Edit;
