import React from "react";
import { Resource } from "react-admin";

import { checkPermission } from "./roles";
import {
  groups,
  users,
  history,
  uploader,
  gallery,
  tagger,
  downloads,
} from "../resources";

const resourcesConfig = {
  uploader: {
    resource: uploader,
    endpoint: "images",
    visible: true,
  },
  users: {
    resource: users,
    endpoint: "users",
    visible: true,
  },
  groups: {
    resource: groups,
    endpoint: "groups",
    visible: true,
  },
  history: {
    resource: history,
    endpoint: "historical",
    visible: true,
  },
  tagger: {
    resource: tagger,
    endpoint: "images",
    visible: true,
  },
  gallery: {
    resource: gallery,
    endpoint: "images",
    visible: true,
  },
  downloads: {
    resource: downloads,
    endpoint: "zips/me",
    visible: true,
  },
  "change-password": {
    resource: false,
    endpoint: "users/change-password",
  },
  permissions: {
    resource: false,
    endpoint: "permissions",
  },
  "multi-images-metadatas": {
    resource: false,
    endpoint: "multi-images-metadatas",
  },
  seasons: {
    resource: false,
    endpoint: "seasons",
  },
  championships: {
    resource: false,
    endpoint: "championships",
  },
  gameweeks: {
    resource: false,
    endpoint: "gameweeks",
  },
  matches: {
    resource: false,
    endpoint: "matches",
  },
  events: {
    resource: false,
    endpoint: "events",
  },
  acts: {
    resource: false,
    endpoint: "acts",
  },
  images: {
    resource: false,
    endpoint: "images",
  },
  players: {
    resource: false,
    endpoint: "players",
  },
  teams: {
    resource: false,
    endpoint: "teams",
  },
  "metadata-others": {
    resource: false,
    endpoint: "metadata-others",
  },
  "user-profiles": {
    resource: false,
    endpoint: "user-profiles",
  },
  zips: {
    resource: false,
    endpoint: "zips",
  },
  download_zip: {
    resource: false,
    endpoint: "zips/download",
  },
  "chunk-images": {
    resource: false,
    endpoint: "chunk-images",
  },
  roles: {
    resource: false,
    endpoint: "roles",
  },
  competitions: {
    resource: false,
    endpoint: "competitions",
  },
  lineups: {
    resource: false,
    endpoint: "lineups",
  },
  "metadata-types": {
    resource: false,
    endpoint: "metadata-types",
  },
  partnerships: {
    resource: false,
    endpoint: "partnerships",
  },

  // Rutas públicas
  "public-partnerships": {
    resource: false,
    endpoint: "public/partnerships",
  },
  "public-users-signup": {
    resource: false,
    endpoint: "public/users/signup",
  },
  competitions: {
    resource: false,
    endpoint: "public/competitions",
  },
};

export function getResources() {
  let withPermissions = [];
  let withoutPermission = [];

  Object.keys(resourcesConfig).forEach((item) => {
    if (checkPermission(item)) {
      withPermissions.push(item);
    } else {
      withoutPermission.push(item);
    }
  });

  let resources = [];

  withPermissions.forEach((item) => {
    const resourceProps = resourcesConfig[item].resource || {};
    const resourceName = item;

    resources.push(
      <Resource key={resourceName} name={resourceName} {...resourceProps} />
    );
  });

  withoutPermission.forEach((item) => {
    const resourceName = item;

    resources.push(<Resource key={resourceName} name={resourceName} />);
  });

  return resources;
}

export default resourcesConfig;
