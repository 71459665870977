import { Button, useMediaQuery } from "@material-ui/core";
import React, { useCallback } from "react";
import {
  useDataProvider,
  useListContext,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";

import {
  DeleteButton,
  BulkActionsMenuButton,
} from "../../../../components/buttons";

const BulkActions = ({ selectedIds, selectAll, deselectAll }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dataProvider = useDataProvider();

  const { filterValues, resource } = useListContext();
  const isTrash = !!filterValues.trashed;

  const getLabel = useCallback(() => {
    if (selectedIds.length) {
      return `${translate(
        isTrash ? "commons.recover" : "commons.move_to_trash"
      )} (${selectedIds.length})`;
    } else {
      return `${translate(
        isTrash ? "commons.recover" : "commons.move_to_trash"
      )}`;
    }
  }, [translate, selectedIds.length, isTrash]);

  const [recoverMany] = useMutation(
    {
      type: "deleteMany",
      resource,
      payload: {
        ids: selectedIds,
        data: { trashed: false },
      },
    },
    {
      onSuccess: () => {
        notify(`resource.${resource}.notify.recovered`, "info");
        refresh();
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  return isDesktop ? (
    isTrash ? (
      <DeleteButton
        disabled={selectedIds.length === 0}
        label={getLabel()}
        onConfirm={recoverMany}
        dialogContent={
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: translate(
                  `resource.${resource}.dialog_recover.counter_html`,
                  {
                    smart_count: selectedIds.length,
                  }
                ),
              }}
            ></p>
          </div>
        }
        cancelButton={<Button>{translate("commons.cancel")}</Button>}
        confirmButton={
          <Button variant="contained" color="primary">
            {translate("commons.recover")}
          </Button>
        }
      />
    ) : (
      <DeleteButton
        isTrash={isTrash}
        resource={resource}
        disabled={selectedIds.length === 0}
        label={getLabel()}
        dialogContent={
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: translate(
                  `resource.${resource}.dialog_delete.counter_html`,
                  {
                    smart_count: selectedIds.length,
                  }
                ),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: translate(
                  `resource.${resource}.dialog_delete.advice_html`
                ),
              }}
            ></p>
          </div>
        }
        cancelButton={<Button>{translate("commons.cancel")}</Button>}
        confirmButton={
          <Button variant="contained" color="primary">
            {translate("commons.delete")}
          </Button>
        }
      />
    )
  ) : (
    <BulkActionsMenuButton
      actions={[
        {
          label: getLabel(),
          onClick: (selectedIds, isTrash) => {
            dataProvider
              .deleteMany(resource, {
                ids: selectedIds,
                data: { trashed: !isTrash },
              })
              .then(() => {
                notify(
                  isTrash
                    ? `resource.${resource}.notify.recovered`
                    : `resource.${resource}.notify.deleted`,
                  "info"
                );
                refresh();
              });
          },
        },
      ]}
      selectAll={selectAll}
      deselectAll={deselectAll}
    />
  );
};

export default BulkActions;
