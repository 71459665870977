import * as React from "react";
import get from "lodash/get";

import { userActiveValors } from "../../../resources/users/constants";
import { useTranslate } from "react-admin";

const UserActiveField = (props) => {
  const translate = useTranslate();
  const { record, source } = props;

  const getValueUser = (value) => {
    const str = userActiveValors.find((each) => each.value == value);
    if (str) {
      return translate(str.translate);
    }
    return "";
  };

  return <span>{getValueUser(get(record, source))}</span>;
};

export default UserActiveField;
