import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { deepmerge } from "@material-ui/utils";

import { getUserConfig, setUserConfig } from "../utils/userConfig";

// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl
export const breakpoints = createBreakpoints({});
const spacing = 8;
const values = {
  toolbar: {
    height: {
      xs: 48,
      sm: 58,
      md: 58,
      lg: 68,
      xl: 68,
    },
    lineHeight: {
      xs: 4,
      sm: 4,
      md: 4,
      lg: 8,
      xl: 8,
    },
  },
};

export const defaultTheme = {
  spacing,
  breakpoints,
  values: values,
  typography: {
    fontFamily: `${[
      '"LaLigaText"',
      '"CoreSansC"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(",")}!important`,
  },
  props: {
    MuiPaper: {
      square: true,
      elevation: 4,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiFormControl: {
      fullWidth: true,
    },
  },
  overrides: {
    MuiSwitch: {
      root: {
        padding: `${spacing}px`,
      },
      switchBase: {
        "&.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track": {
          opacity: 1,
        },
      },
      track: {
        borderRadius: `${spacing * 1.5}px`,
        opacity: 0.2,
      },
      thumb: {
        height: `${spacing * 2.25}px`,
        width: `${spacing * 2.25}px`,
        margin: `${spacing * 0.125}px`,
        color: "#fff",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "2px",
      },
    },
    MuiInput: {
      underline: {
        "&:after, &:before": {
          display: "none",
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        top: `${spacing * 0.25}px`,
        left: `${spacing * 0.5}px`,
        "& + .MuiInput-formControl": {
          marginTop: `${spacing * 3}px`,
        },
      },
    },
    MuiInputBase: {
      root: {
        transition: "background 0.3s",
      },
      input: {
        padding: `${spacing}px`,
        transition: "background-color 0.3s",
      },
      inputMarginDense: {
        paddingTop: `${spacing}px`,
      },
      multiline: {
        padding: `${spacing}px !important`,
      },
    },
    MuiInputAdornment: {
      root: {
        flex: "1 0 30px",
        justifyContent: "center",
      },
    },
    RaLinearProgress: {
      root: {
        width: "100%",
      },
    },
    RaList: {
      main: {
        boxShadow: "none",
        [breakpoints.up("md")]: {
          margin: `${spacing * 2}px`,
        },
      },
    },
    RaDatagrid: {
      rowCell: {
        height: "64px",
      },
    },
    RaCreateButton: {
      floating: {
        bottom: "20px",
      },
    },
    RaCreate: {
      root: {
        width: "100%",
        maxWidth: "900px",
        margin: "0 auto",
        height: "100%",
      },
      main: {
        height: "calc(100% - 1em)",
        "& .simple-form": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          "& > .MuiCardContent-root ": {
            flex: "1 1 auto",
            padding: `${spacing}px`,
            [breakpoints.up("lg")]: {
              padding: `${spacing * 2}px`,
            },
          },
        },
      },
      noActions: {
        marginTop: "0!important",
      },
      card: {
        boxShadow: "none",
      },
    },
    RaEdit: {
      root: {
        width: "100%",
        maxWidth: "900px",
        margin: "0 auto",
        height: "100%",
      },
      main: {
        height: "calc(100% - 1em)",
        "& .simple-form": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          "& > .MuiCardContent-root ": {
            flex: "1 1 auto",
            padding: `${spacing}px`,
            [breakpoints.up("lg")]: {
              padding: `${spacing * 2}px`,
            },
          },
        },
      },
      noActions: {
        marginTop: "0!important",
      },
      card: {
        boxShadow: "none",
      },
    },
    RaToolbar: {
      toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    MuiTabs: {
      root: {
        minHeight: 32,
      },
      indicator: {
        top: 0,
      },
    },
    MuiTab: {
      root: {
        minHeight: 32,
        transition: "background 0.3s, color 0.3s",
        "&.Mui-selected": {
          background: "transparent",
        },
      },
    },
  },
};

const laligaRed = { main: "#f21e27" };
const laligaYellow = { main: "#ffd300" };
const laligaBlue = { main: "#008fc9" };

export const themeLight = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: laligaRed,
    secondary: laligaBlue,
    background: {
      default: "#fff",
      paper: "#fff",
      sidebar: "#f2f2f2",
      darkPaper: "#fff",
      footer: "#25282A",
      odd: "#fff",
      even: "#f2f2f2",
      bottomPanelItemHover: "#eeeeee",
    },
  },
  overrides: deepmerge(defaultTheme.overrides, {
    MuiInputBase: {
      root: {
        background: "#f2f2f2",
        "&.Mui-focused": {
          background: "#e6e6e6",
        },
      },
      input: {
        "&.Mui-disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
      },
    },
    MuiTab: {
      root: {
        background: "#E0E0E0",
      },
    },
  }),
});

export const themeDark = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: laligaYellow,
    secondary: laligaBlue,
    type: "dark",
    background: {
      default: "#22211F",
      paper: "#22211F",
      sidebar: "#333333",
      darkPaper: "#100F0F",
      footer: "#25282A",
      odd: "#22211F",
      even: "#100F0F",
      bottomPanelItemHover: "#100F0F",
    },
  },
  overrides: deepmerge(defaultTheme.overrides, {
    MuiInputBase: {
      root: {
        background: "#333",
        "&.Mui-focused": {
          background: "#3d3d3d",
        },
      },
      input: {
        "&.Mui-disabled": {
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        },
      },
    },
    MuiTab: {
      root: {
        background: "#616161",
        color: "#FFF",
      },
    },
    MuiAccordion: {
      root: {
        background: "#333",
      },
    },
  }),
});

export const themes = {
  light: themeLight,
  dark: themeDark,
};

const CHANGE_THEME = "CHANGE_THEME";
export const change_theme = (themeKey) => {
  return {
    type: CHANGE_THEME,
    payload: themeKey,
  };
};

const savedTheme = getUserConfig("theme");
if (!themes[savedTheme]) {
  setUserConfig("light", "theme");
}
const initialTheme = themes[savedTheme] ? savedTheme : "light";

export const themeReducer = (state = initialTheme, action) => {
  switch (action.type) {
    case CHANGE_THEME: {
      setUserConfig(action.payload, "theme");
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
