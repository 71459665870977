import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  inputBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "& label": {
      color: theme.palette.text.secondary,
      transform: "translate(0, 1.5px) scale(0.75)",
      margin: "0",
    },
  },
  itemsList: {
    position: "absolute",
    top: "58px",
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
    listStyle: "none",
    margin: 0,
    padding: 0,
    zIndex: 99,
    boxShadow: theme.shadows[8],
    maxHeight: "328px",
    overflowY: "auto",
    color: theme.palette.common.black,
  },
  inputWrapper: {
    position: "relative",
    "& svg": {
      fontSize: "22px",
      color: theme.palette.text.secondary,
    },
  },
  item: {
    padding: theme.spacing(1, 2),
  },
  linearProgress: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "3px",
  },
}));
