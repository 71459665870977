import _get from "lodash/get";
import _set from "lodash/set";
import _merge from "lodash/merge";
import _isPlainObject from "lodash/isPlainObject";
import _isString from "lodash/isString";

import { USER_CONFIG_KEY } from "../config/constants";

export function getUserConfig(key = null) {
  const userConfig = localStorage.getItem(USER_CONFIG_KEY)
    ? JSON.parse(localStorage.getItem(USER_CONFIG_KEY))
    : {};
  if (key) {
    return _get(userConfig, key);
  }
  return userConfig;
}

export function setUserConfig(value, key = null) {
  let userConfig = undefined;

  if (_isPlainObject(value)) {
    if (key) {
      // set to truthy to replace
      userConfig = value;
    } else {
      userConfig = getUserConfig();
      _merge(userConfig, value);
    }
  } else {
    if (_isString(key)) {
      userConfig = getUserConfig();
      _set(userConfig, key, value);
    }
  }

  if (userConfig) {
    localStorage.setItem(USER_CONFIG_KEY, JSON.stringify(userConfig));
  }
}

export function deleteUserConfig() {
  localStorage.removeItem(USER_CONFIG_KEY);
}
