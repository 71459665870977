import { Link } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useTranslate } from "react-admin";

import { useFooterStyles } from "./styles";

const Footer = ({ theme }) => {
  const classes = useFooterStyles(theme);
  const translate = useTranslate();

  return (
    <footer className={classes.root}>
      <div className={classes.footerTop}>
        LALIGA {new Date().getFullYear()} &copy; -{" "}
        {translate("footer.all_rights_reserved")}
      </div>
      <div className={classes.footerBottom}>
        <Link
          href={translate("footer.privacy_and_cookie_policy_href")}
          target="_blank"
          rel="noreferrer noopener"
        >
          {translate("footer.privacy_and_cookie_policy")}
        </Link>
        <span>{` | `}</span>
        {/* Optanon Privacy Settings button start */}
        <a class="optanon-show-settings">
          {translate("footer.privacy_settings")}
        </a>
        {/* Optanon Privacy Settings button end */}
      </div>
    </footer>
  );
};

Footer.propTypes = {
  theme: PropTypes.object,
};

export default Footer;
