import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  mobileListRoot: {
    display: "flex",
  },
  mobileListImage: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 200px",
      marginRight: theme.spacing(2),
    },
    "& img": {
      width: "100%",
      maxWidth: "100%",
      height: "auto",
      maxHeight: "unset",
    },
  },
}));
