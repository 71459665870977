import makeStyles from "@material-ui/core/styles/makeStyles";

export const useBulkActionsClasses = makeStyles(
  (theme) => ({
    deselectContainer: {
      textAlign: "right",
    },
    itemsRoot: {
      flexGrow: 1,
    },
    itemRoot: {
      display: "flex",
      padding: theme.spacing(2),
      transition: "background 0.3s",
      "&:hover": {
        background: theme.palette.background.bottomPanelItemHover,
      },
    },
    imageContainer: {
      flexBasis: "33%",
      flexShrink: 0,
    },
    imageRatio: {
      paddingTop: "56.25%",
      position: "relative",
    },
    image: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    infoContainer: {
      padding: theme.spacing(0, 1),
      flexGrow: 1,
      minWidth: 0,
    },
    deselectItemContainer: {
      display: "flex",
      alignItems: "center",
    },
    imageTitle: {
      fontWeight: "bold",
    },
    actionsRoot: {
      padding: theme.spacing(1),
      justifyContent: "center",
      display: "flex",
    },
    actionButton: {
      margin: theme.spacing(1),
      borderWidth: `2px!important`,
      borderColor: `${theme.palette.primary.main}!important`,
      color: `${theme.palette.primary.main}!important`,
    },
    downloadOriginalsButton: {
      marginRight: theme.spacing(1),
    },
    extraClass: {
      display: "flex",
      justifyContent: "center",
    },
  }),
  { name: "WBOGallery-BulkActions" }
);
