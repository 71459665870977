import Button from "@material-ui/core/Button";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { email, required, useNotify, useTranslate } from "react-admin";
import { Form } from "react-final-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { LOGIN_FORM, PASSWORD_RECOVERY_FORM_2 } from "..";
import { TextInput } from "../../../components/inputs";
import { sendRecoveryPassword } from "../../../providers/authProviderToken";
import { useStyles } from "../styles";

const PasswordRecoveryForm = ({ setPageView, setLoading }) => {
  const [token, setToken] = useState();
  const classes = useStyles();

  const notify = useNotify();
  const translate = useTranslate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const refreshToken = useCallback(() => {
    if (executeRecaptcha) {
      executeRecaptcha("password_recovery_form").then((result) => {
        setToken(result);
      });
    }
  }, [executeRecaptcha]);

  const handleFormSubmit = useCallback(
    (formData) => {
      setLoading(true);

      if (formData.reCaptcha) {
        sendRecoveryPassword(formData)
          .then(() => {
            setLoading(false);
            setPageView(PASSWORD_RECOVERY_FORM_2);
          })
          .catch((err) => {
            notify(err.response.data["hydra:description"], "warning");
            setLoading(false);
            refreshToken();
          });
      } else {
        notify("recaptcha error", "warning");
        setLoading(false);
        refreshToken();
      }
    },
    [setPageView, notify, setLoading, refreshToken]
  );

  return (
    <Form onSubmit={handleFormSubmit}>
      {({ handleSubmit, form, values }) => {
        if (!values.reCaptcha || values.reCaptcha !== token) {
          if (!token) {
            refreshToken();
          }
          form.change("reCaptcha", token);
        }

        return (
          <form onSubmit={handleSubmit}>
            <TextInput source="email" validate={[required(), email()]} />
            <div className={classNames(classes.buttons, classes.actionWrapper)}>
              <Button
                onClick={() => {
                  setPageView(LOGIN_FORM);
                }}
              >
                {translate("commons.cancel")}
              </Button>
              <Button variant="contained" type="submit" color="primary">
                {translate("login.send_email")}
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

export default PasswordRecoveryForm;
