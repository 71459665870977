import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useQueryWithStore, useTranslate } from "react-admin";

import GroupChecks from "./GroupChecks";

const useStyle = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      position: "relative",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    loading: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100px",
      background: "#F2f2f2",
    },
  };
});

const CheckPemissions = ({ idSeason }) => {
  const classes = useStyle();
  const translate = useTranslate();

  const { loaded: loadedSeasons, data: dataSeasons } = useQueryWithStore({
    type: "getList",
    resource: "permissions",
    payload: {
      filter: { "exists[season]": true, allEvent: false },
      order: { field: "id", order: "DESC" },
    },
  });

  const { loaded: loadedTeams, data: dataTeams } = useQueryWithStore({
    type: "getList",
    resource: "permissions",
    payload: {
      filter: { "exists[team]": true },
    },
  });

  let permissionsSeasons = [];
  if (loadedSeasons) {
    permissionsSeasons = dataSeasons.sort((a, b) => a.id < b.id);
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-seasons"
          id="panel-seasons-header"
        >
          <Typography>{translate("commons.seasons")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            {loadedSeasons ? (
              permissionsSeasons.map((season) => (
                <GroupChecks
                  type="season"
                  permission={season}
                  parentIsChecked={false}
                  key={season.id}
                />
              ))
            ) : (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-teams"
          id="panel-teams-header"
        >
          <Typography>{translate("commons.teams")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            {loadedTeams ? (
              dataTeams.map((team) => (
                <GroupChecks
                  type="team"
                  permission={team}
                  parentIsChecked={false}
                  key={team.id}
                />
              ))
            ) : (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CheckPemissions;
