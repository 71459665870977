import { FormControl, Grid, InputLabel, NativeSelect } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { useCallback, useMemo } from "react";
import { ReferenceInput, useTranslate } from "react-admin";
import { useForm } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import {
  AutoCompleteInput,
  DateRangeInput,
  EventInput,
  MatchInput,
  SelectInput,
  TextInput,
} from "../../../components/inputs";
import { change_filter_type_gallery } from "../../../redux/actions/filterTypeGallery";

const useStyles = makeStyles((theme) => ({
  emptyGridItem: {
    padding: [0, "!important"],
  },
}));

const Filters = (props) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const type = useSelector((state) => state.filterTypeGallery);
  const formContext = useForm();
  const classes = useStyles();

  const handleTypeChange = useCallback(
    (e) => {
      const newType = e.target.value;

      formContext.change("championship", null);
      formContext.change("gameweek", null);
      formContext.change("match", null);
      formContext.change("event", null);
      formContext.change("act", null);

      dispatch(change_filter_type_gallery(newType));
    },
    [dispatch, formContext]
  );

  const conditionalFormInputs = useMemo(() => {
    if (type === "match") {
      return (
        <>
          <Grid item xs={12}>
            <MatchInput
              sourceSeason="season"
              sourceChampionship="championship"
              sourceGameweek="gameweek"
              sourceMatch="match"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <AutoCompleteInput
              multiple
              label="commons.players"
              source="player"
              searchResource="players"
              searchProperty="name"
              optionText="name"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <AutoCompleteInput
              multiple
              label="commons.teams"
              source="team"
              searchResource="teams"
              searchProperty="name"
              optionText={(team) => {
                let nickname = team.nickname;
                if (team.gender === "female") nickname += " (fem)";
                return nickname;
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <AutoCompleteInput
              multiple
              offline
              label="commons.generic_tags"
              source="other"
              searchResource="metadata-others"
              searchProperty="name"
              optionText="name"
            />
          </Grid>
        </>
      );
    } else if (type === "event") {
      return (
        <Grid item xs={12}>
          <EventInput
            sourceSeason="season"
            sourceEvent="event"
            sourceAct="act"
          />
        </Grid>
      );
    }
  }, [type]);

  return (
    <Grid container key="grid" spacing={2}>
      <Grid item xs={12} lg={4}>
        <FormControl style={{ marginBottom: "4px", marginTop: "8px" }}>
          <InputLabel htmlFor="select-type">
            {translate("commons.type")}
          </InputLabel>
          <NativeSelect
            value={type}
            onChange={handleTypeChange}
            inputProps={{
              name: "type",
              id: "select-type",
            }}
          >
            <option value="match">{translate("commons.match")}</option>
            <option value="event">{translate("commons.event")}</option>
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={4} className={classes.emptyGridItem} />
      <Grid item xs={12} lg={4} className={classes.emptyGridItem} />
      <Grid item xs={12} lg={4}>
        <TextInput
          source="q"
          label="commons.name_or_title"
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <DateRangeInput
          source="createdAt"
          label={translate("commons.date_range")}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <ReferenceInput
          label="commons.season"
          source="season"
          reference="seasons"
        >
          <SelectInput />
        </ReferenceInput>
      </Grid>
      {conditionalFormInputs}
    </Grid>
  );
};

export default Filters;
