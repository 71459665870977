import Button from "@material-ui/core/Button";
import React from "react";
import { useTranslate } from "react-admin";

import { useStyles } from "./styles";

const BackButton = ({ basePath }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Button href={`/#${basePath}`} className={classes.button}>
      {translate("commons.cancel")}
    </Button>
  );
};

export default BackButton;
