import _get from "lodash/get";
import React, { useEffect, useState } from "react";
import { maxLength, required } from "react-admin";
import { Grid } from "@material-ui/core";
import { SelectInput, TextInput } from "../../components/inputs";
import { publicDataProvider } from "../../providers";

// api/v1/public/competitions
// api/v1/public/competitions/{{id}}/teams
const PartnershipDescriptionInput = ({ values, form }) => {
  const [competitions, setCompetitions] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    publicDataProvider.getList("competitions", {}).then(({ data }) =>
      setCompetitions(
        data.map((each) => ({
          id: each.id,
          name: each.name,
        }))
      )
    );
  }, [values.partnership]); // eslint-disable-line

  useEffect(() => {
    form.change("partnershipDescription", "");
  }, [values.partnership]); // eslint-disable-line

  useEffect(() => {
    let idCompetition = _get(values, "partnershipDescriptionCompetition") || "";
    if (idCompetition != "") {
      fetch(
        `${process.env.REACT_APP_DEFAULT_ENDPOINT}/public/competitions/${idCompetition}/teams`
      )
        .then((res) => res.json())
        .then((res) =>
          setTeams(
            res["hydra:member"].map((each) => ({
              id: each.id,
              name: `${each.nickname} ${
                each.gender === "female" ? "(fem)" : ""
              }`,
            }))
          )
        )
        .catch((err) =>
          console.log(
            `Algun error se ha producido en la llamada a equipos`,
            err
          )
        );
    }
  }, [values.partnershipDescriptionCompetition]); // eslint-disable-line

  if (_get(values, "partnership[@id]") === "/api/v1/partnerships/1") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SelectInput
            allowEmpty={true}
            emptyValue=""
            source="partnershipDescriptionCompetition"
            choices={competitions}
            label="commons.competition"
            optionValue="id"
            validate={[required()]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput
            allowEmpty={true}
            emptyValue=""
            source="partnershipDescription"
            choices={teams}
            label="commons.team"
            optionValue="name"
            validate={[required()]}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <TextInput
        source="partnershipDescription"
        label="register.label.partnershipDescription"
        validate={[
          required(
            "resource.users.validations.partnershipDescription.required"
          ),
          maxLength(255),
        ]}
        disabled={!values.partnership}
      />
    );
  }
};

export default PartnershipDescriptionInput;
