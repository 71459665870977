import {
  Button,
  Collapse,
  Drawer,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import classNames from "classnames";
import _get from "lodash/get";
import React, { useState } from "react";
import { FormWithRedirect, useTranslate } from "react-admin";
import { useFormState } from "react-final-form";

import { CURRENT_SEASON } from "../../../config/constants";
import { getUserConfig } from "../../../utils/userConfig";
import FiltersInput from "./FiltersInput";
import {
  useDrawerBackdropClasses,
  useDrawerClasses,
  usePaperClasses,
  useStyles,
} from "./styles";

export default ({ title, setfiltersValues }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const drawerClasses = useDrawerClasses();
  const backdropClasses = useDrawerBackdropClasses();
  const paperClasses = usePaperClasses();

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [isOpen, setIsOpen] = useState(true);
  const [isDraweOpen, setIsDraweOpen] = useState(true);

  return (
    <FormWithRedirect
      resource="tagger"
      initialValues={{
        season: getUserConfig("season_recent_id") || CURRENT_SEASON,
      }}
      render={({ form }) => {
        const { values } = useFormState({ subscription: { values: true } });
        const canITagger = () => {
          const match = _get(values, "match");

          if (match) {
            return true;
          }
          return false;
        };
        const handleClick = () => {
          const match = _get(values, "match");

          let filter = {};
          filter.tagged = false;
          if (match) {
            filter.match = match;
          }
          setIsOpen(false);
          setIsDraweOpen(false);
          setfiltersValues(filter);
        };

        return (
          <div className={classes.root}>
            <Paper
              className={classes.paper}
              classes={paperClasses}
              elevation={4}
            >
              <div
                className={classNames(classes.paperHead, {
                  [classes.paperHeadOpen]: !!isOpen,
                })}
                onClick={() => {
                  isDesktop ? setIsOpen(!isOpen) : setIsDraweOpen(true);
                }}
              >
                <div className={classes.paperHeadTitle}>
                  <LocalOfferOutlinedIcon />
                  {title}
                </div>
                {isDesktop && <ExpandMoreRoundedIcon />}
              </div>
              {isDesktop ? (
                <Collapse in={isOpen}>
                  <div className={classes.paperBody}>
                    <div className="form-input">
                      <FiltersInput />
                    </div>
                    <div className={classes.paperButtonPanel}>
                      <Button
                        onClick={handleClick}
                        disabled={!canITagger()}
                        color="primary"
                        variant="contained"
                        size="small"
                      >
                        {translate("resource.tagger.action_tagger")}
                      </Button>
                    </div>
                  </div>
                </Collapse>
              ) : (
                <Drawer
                  classes={drawerClasses}
                  open={isDraweOpen}
                  onClose={() => setIsDraweOpen(false)}
                  variant="persistent"
                  ModalProps={{ BackdropProps: { classes: backdropClasses } }}
                >
                  <div className={classes.paperBody}>
                    <div
                      className={classes.paperHead}
                      onClick={() => setIsDraweOpen(false)}
                    >
                      <div className={classes.paperHeadTitle}>
                        <LocalOfferOutlinedIcon />
                        {title}
                      </div>
                      <CloseOutlinedIcon />
                    </div>
                    <div className="form-input">
                      <FiltersInput />
                    </div>
                    <div className={classes.paperButtonPanel}>
                      <Button
                        onClick={handleClick}
                        disabled={!canITagger()}
                        color="primary"
                        variant="contained"
                        size="small"
                      >
                        {translate("resource.tagger.action_tagger")}
                      </Button>
                    </div>
                  </div>
                </Drawer>
              )}
            </Paper>
          </div>
        );
      }}
    />
  );
};
