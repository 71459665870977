import { Chip, Tab, Tabs } from "@material-ui/core";
import _get from "lodash/get";
import React, { useCallback, useEffect, useState } from "react";
import { useDataProvider, useQueryWithStore, useTranslate } from "react-admin";

import Sield from "../../../components/custom/Shield";
import { AutoCompleteGroupInput } from "../../../components/inputs";
import { useMetadataCatalogStyles } from "./styles";

const MetadataCatalog = ({ idMatch, selecteds = [], addMetadata }) => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useMetadataCatalogStyles();
  const [tabActive, setTabActive] = useState(0);
  const [homeTeam, setHomeTeam] = useState(false);
  const [awayTeam, setAwayTeam] = useState(false);
  const [lineups, setLineups] = useState(false);
  const {
    loaded: loadedMetadataOthers,
    data: metadatasOthers,
  } = useQueryWithStore({
    type: "getList",
    resource: "metadata-others",
    payload: {},
  });
  const { data: metadataTypes } = useQueryWithStore({
    type: "getList",
    resource: "metadata-types",
    payload: {},
  });

  useEffect(() => {
    if (idMatch) {
      dataProvider.getOne("matches", { id: idMatch }).then(({ data }) => {
        setHomeTeam(data.homeTeam);
        setAwayTeam(data.awayTeam);
      });
      dataProvider
        .getLineups("lineups", { filter: { match: idMatch } })
        .then(({ data }) => {
          setLineups(data);
        });
    }
  }, [idMatch, dataProvider]);

  const getChoices = useCallback(() => {
    let choices = {};
    if (loadedMetadataOthers) {
      choices["commons.others"] = {
        choices: metadatasOthers
          .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
          .map((item) => ({
            id: `other:${item.id}`,
            name: item.name,
          })),
      };
    }

    if (homeTeam && awayTeam) {
      choices["commons.teams"] = {
        choices: [
          {
            id: `team:${homeTeam.id}`,
            name: homeTeam.nickname,
          },
          {
            id: `team:${awayTeam.id}`,
            name: awayTeam.nickname,
          },
        ],
      };
      if (lineups) {
        choices["commons.homePlayers"] = {
          choices: lineups.homeTeam.map((item) => ({
            id: `player:${item.player.id}`,
            name: `${item.player.nickname} (${
              item.shirtNumber ? item.shirtNumber : ""
            }) (${homeTeam.nickname})`,
          })),
        };
        choices["commons.awayPlayers"] = {
          choices: lineups.awayTeam.map((item) => ({
            id: `player:${item.player.id}`,
            name: `${item.player.nickname} (${
              item.shirtNumber ? item.shirtNumber : ""
            }) (${awayTeam.nickname})`,
          })),
        };
      }
    }
    return choices;
  }, [loadedMetadataOthers, metadatasOthers, homeTeam, awayTeam, lineups]);

  const handleSelectOption = (value) => {
    let data = value.split(":");
    addMetadata({
      metadataType: getMetadataType(data[0]),
      value: data[1],
    });
  };

  const isSelected = (type, value) => {
    return selecteds.some(
      (element) =>
        element?.metadataType?.name === type && element.value === value
    );
  };

  const getMetadataType = (type) => {
    return metadataTypes.find((element) => element.name === type);
  };

  return (
    <div className={classes.root}>
      <div className={classes.selectMetadata}>
        {loadedMetadataOthers && homeTeam && awayTeam && lineups && (
          <AutoCompleteGroupInput
            label="resource.tagger.advancedTagger.searchTags"
            choices={getChoices()}
            selectedValues={selecteds.map(
              (item) => `${_get(item, "metadataType.name")}:${item.value}`
            )}
            onSelect={(value) => handleSelectOption(value)}
          />
        )}
      </div>
      {metadataTypes && (
        <div className={classes.tabsMetadatas}>
          <Tabs
            value={tabActive}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            onChange={(event, newValue) => {
              setTabActive(newValue);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <Tab
              label={translate("resource.tagger.advancedTagger.tabGeneric")}
              wrapped
            />
            <Tab
              label={
                homeTeam
                  ? homeTeam.nickname
                  : translate("resource.tagger.advancedTagger.tabHomeTeam")
              }
              wrapped
            />
            <Tab
              label={
                awayTeam
                  ? awayTeam.nickname
                  : translate("resource.tagger.advancedTagger.tabAwayTeam")
              }
              wrapped
            />
          </Tabs>
          <div
            className={classes.tabPanel}
            role="tabpanel"
            hidden={tabActive !== 0}
          >
            <div className={classes.groupTag}>
              {homeTeam && (
                <Chip
                  avatar={
                    <div className={classes.chipAvatar}>
                      <Sield idTeam={homeTeam.id} size="xs" />
                    </div>
                  }
                  label={homeTeam.nickname}
                  onClick={() =>
                    addMetadata({
                      metadataType: getMetadataType("team"),
                      value: homeTeam.id,
                    })
                  }
                  disabled={isSelected("team", homeTeam.id)}
                />
              )}
              {awayTeam && (
                <Chip
                  avatar={
                    <div className={classes.chipAvatar}>
                      <Sield idTeam={awayTeam.id} size="xs" />
                    </div>
                  }
                  label={awayTeam.nickname}
                  onClick={() =>
                    addMetadata({
                      metadataType: getMetadataType("team"),
                      value: awayTeam.id,
                    })
                  }
                  disabled={isSelected("team", awayTeam.id)}
                />
              )}
              {homeTeam && awayTeam && (
                <Chip
                  avatar={
                    <>
                      <div className={classes.chipAvatar}>
                        <Sield idTeam={homeTeam.id} size="xs" />
                      </div>
                      <div className={classes.chipAvatar}>
                        <Sield idTeam={awayTeam.id} size="xs" />
                      </div>
                    </>
                  }
                  label={"Ambos equipos"}
                  onClick={() =>
                    addMetadata([
                      {
                        metadataType: getMetadataType("team"),
                        value: homeTeam.id,
                      },
                      {
                        metadataType: getMetadataType("team"),
                        value: awayTeam.id,
                      },
                    ])
                  }
                  disabled={
                    isSelected("team", homeTeam.id) &&
                    isSelected("team", awayTeam.id)
                  }
                />
              )}
            </div>
            <div className={classes.groupTag}>
              {loadedMetadataOthers &&
                metadatasOthers.slice(0, 15).map((item) => (
                  <Chip
                    label={item.name}
                    onClick={() =>
                      addMetadata({
                        metadataType: getMetadataType("other"),
                        value: item.id,
                      })
                    }
                    disabled={isSelected("other", item.id)}
                    key={item.id}
                  />
                ))}
            </div>
          </div>
          <div
            className={classes.tabPanel}
            role="tabpanel"
            hidden={tabActive !== 1}
          >
            <div className={classes.groupTag}>
              {lineups &&
                lineups.homeTeam.map((item) => (
                  <Chip
                    label={`${item.player.nickname} ${
                      item.shirtNumber ? `(${item.shirtNumber})` : ""
                    }`}
                    onClick={() =>
                      addMetadata({
                        metadataType: getMetadataType("player"),
                        value: item.player.id,
                      })
                    }
                    disabled={isSelected("player", item.player.id)}
                    key={item.player.id}
                  />
                ))}
            </div>
          </div>
          <div
            className={classes.tabPanel}
            role="tabpanel"
            hidden={tabActive !== 2}
          >
            <div className={classes.groupTag}>
              {lineups &&
                lineups.awayTeam.map((item) => (
                  <Chip
                    label={`${item.player.nickname} ${
                      item.shirtNumber ? `(${item.shirtNumber})` : ""
                    }`}
                    onClick={() =>
                      addMetadata({
                        metadataType: getMetadataType("player"),
                        value: item.player.id,
                      })
                    }
                    disabled={isSelected("player", item.player.id)}
                    key={item.player.id}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MetadataCatalog;
