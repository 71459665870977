import { useMediaQuery } from "@material-ui/core";
import React from "react";

import { useStyles } from "./styles";

const SectionTitle = ({ children }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  return !isDesktop && <h1 className={classes.root}>{children}</h1>;
};

export default SectionTitle;
