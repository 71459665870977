import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {
  choices,
  email,
  maxLength,
  required,
  useLocale,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import Modal from "../../components/custom/Modal";
import {
  CheckboxInput,
  PasswordInput,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import successfullRegister from "../../config/translations/successfullRegister";
import { publicDataProvider } from "../../providers";
import { password_complexity } from "../../utils/password";
import { useStyles } from "./styles";
import PartnershipDescriptionInput from "./PartnershipDescriptionInput";

const RegisterForm = ({ setModalOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const [dataPartnerships, setDataPartnerships] = useState([]);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    publicDataProvider
      .getList("public-partnerships", {})
      .then(({ data }) => setDataPartnerships(data));
  }, []);

  const handleFormSubmit = (formData, form) => {
    const { repeatPlainPassword, terms_of_use, ...restFormData } = formData;

    if (restFormData?.partnershipDescriptionCompetition != undefined) {
      delete restFormData.partnershipDescriptionCompetition;
    }

    if (executeRecaptcha) {
      setIsLoading(true);
      executeRecaptcha("register_form").then((result) => {
        publicDataProvider
          .create("public-users-signup", {
            data: { ...restFormData, reCaptcha: result },
          })
          .then((resp) => {
            setModalSuccessOpen(true);
            setTimeout(() => {
              form.restart();
            });
          })
          .catch((err, ...rest) => {
            notify(err.message, "warning");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  };

  return (
    <>
      <Form onSubmit={handleFormSubmit}>
        {({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Typography
                className={classes.cardDescription}
                variant="body1"
                paragraph
              >
                {translate("register.description")}
              </Typography>
              <TextInput
                source="name"
                label="register.label.name"
                validate={[required(), maxLength(255)]}
              />
              <TextInput
                source="email"
                label="commons.e-mail"
                validate={[required(), email()]}
              />
              <SelectInput
                allowEmpty={true}
                emptyValue=""
                source="partnership.@id"
                choices={dataPartnerships}
                label="resource.users.typeOfpartnership"
                optionValue="@id"
                validate={[required()]}
              />
              <PartnershipDescriptionInput values={values} form={form} />
              <PasswordInput
                source="plainPassword"
                label={"login.password"}
                validate={[required(), password_complexity()]}
              />
              <PasswordInput
                source="repeatPlainPassword"
                label={"login.repeat_password"}
                validate={[
                  required(),
                  choices([values.plainPassword], "login.repeat_password_text"),
                ]}
              />
              <CheckboxInput
                source="terms_of_use"
                label={
                  <span>
                    {translate("register.label.terms_of_use.start")}{" "}
                    <span
                      className={classes.termsLink}
                      role="button"
                      onClick={(e) => {
                        setModalOpen(true);
                        e.preventDefault();
                      }}
                    >
                      {translate("register.label.terms_of_use.link")}
                    </span>
                  </span>
                }
                validate={required()}
                className={classes.checkbox}
              />
              <div className={classes.buttons}>
                <Button onClick={() => redirect("/login")}>
                  {translate("commons.cancel")}
                </Button>
                <Button variant="contained" type="submit" color="primary">
                  {translate("commons.create")}
                </Button>
              </div>
              <div
                className={classNames(classes.loading, {
                  [classes.showLoading]: isLoading,
                })}
              >
                <CircularProgress size={80} />
              </div>
            </form>
          );
        }}
      </Form>
      <Modal
        open={modalSuccessOpen}
        onClose={() => setModalSuccessOpen(false)}
        scroll="body"
        maxWidth="md"
      >
        <div className={classes.termsContent}>
          {successfullRegister[locale]}
        </div>
        <div className={classes.termsModalClose}>
          <IconButton onClick={() => setModalSuccessOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
      </Modal>
    </>
  );
};

export default RegisterForm;
