import { makeStyles } from "@material-ui/core";

export const useWrapperDesktopStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      height: "100%",
      background: theme.palette.background.sidebar,
      boxShadow: theme.shadows[8],
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
    },
    body: {
      flex: "1 1 auto",
    },
  }),
  { name: "WBO-AdvancedTagger-Desktop" }
);

export const useTaggerStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up(["lg"])]: {
        position: "sticky",
        top: theme.values.toolbar.height.lg,
      },
    },
    modalImg: {
      maxWidth: "100%",
      height: "auto",
      verticalAlign: "middle",
      maxHeight: "calc(100vh - 96px)", // modal has 32px margintop, 32px marginbottom, 16px paddingtop and 16px paddingbottom, so 96 total.
    },
    modalClose: {
      position: "absolute",
      top: 24,
      right: 24,
      color: theme.palette.common.white,
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
    notImages: {
      position: "relative",
      border: `2px solid ${
        theme.palette.type === "dark"
          ? theme.palette.grey[900]
          : theme.palette.grey[400]
      }`,
      background:
        theme.palette.type === "dark"
          ? theme.palette.grey[800]
          : theme.palette.grey[300],
      borderRadius: "4px",
      paddingBottom: "56.25%",
      "& > p": {
        width: "60%",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    rowInfo: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      [theme.breakpoints.up(["lg"])]: {
        padding: theme.spacing(0, 2),
      },
    },
    headImage: {
      padding: theme.spacing(0.5, 0),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    selectedTags: {
      "& .tags": {
        width: "100%",
        minHeight: "80px",
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(1, 0),
        "& > div": {
          marginRight: theme.spacing(0.5),
          marginBottom: theme.spacing(0.5),
        },
      },
    },
    rowMetadatas: {
      borderTop: `1px solid ${theme.palette.divider}`,
      background: theme.palette.background.paper,
      flex: "1 1 auto",
    },
    rowMetadatasHidden: {
      display: "none",
    },
  }),
  { name: "WBO-tagger" }
);

export const useMetadataCatalogStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  selectMetadata: {
    padding: theme.spacing(2),
  },
  tabPanel: {
    // display: "none",
    padding: theme.spacing(2),
  },
  groupTag: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2),
    "& > div": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  chipAvatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:first-child": {
      marginLeft: theme.spacing(1),
    },
  },
}));
