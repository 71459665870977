export default {
  commons: {
    theme_mode: "Modo %{theme}",
    generic_error: "Ha ocurrido un error.",
    settings: "Configuración",
    basic_settings: "Configuración básica",
    saved_settings: "Configuración guardada",
    name: "Nombre",
    name_or_title: "Nombre / Título",
    email: "Email",
    "e-mail": "Correo electrónico",
    notifications: "Notificaciones",
    receiving_notifications: "Recibiendo notificaciones",
    request_to_receive_notifications: "Solicitar recibir notificaciones",
    theme: "Tema",
    light: "Claro",
    dark: "Oscuro",
    english: "Inglés",
    spanish: "Español",
    language: "Idioma",
    cancel: "Cancelar",
    title: "Título",
    description: "Descripción",
    author: "Autor",
    or: "o",
    type: "Tipo",
    season: "Temporada",
    seasons: "Temporadas",
    competition: "Competición",
    competitions: "Competiciones",
    gameweek: "Jornada",
    gameweeks: "Jornadas",
    match: "Partido",
    matches: "Partidos",
    team: "Equipo",
    teams: "Equipos",
    event: "Evento",
    events: "Eventos",
    act: "Acto",
    acts: "Actos",
    size: "Tamaño",
    select_all: "Seleccionar todo",
    deselect_all: "Deseleccionar todo",
    deselect_count: "Deseleccionar (%{count})",
    action_create: "Añadir",
    save: "Guardar",
    create: "Crear",
    edit: "Editar",
    user: "Usuario",
    image: "Imagen",
    list: "Listado",
    trash: "Papelera",
    show_list: "Ver listado",
    show_trash: "Ver papelera",
    delete: "Eliminar",
    championship: "Competición",
    championships: "Competiciones",
    other: "Otro",
    others: "Otros",
    player: "Jugador",
    players: "Jugadores",
    profile: "Perfil",
    disable: "Desactivar",
    enable: "Activar",
    reactivate: "Reactivar",
    move_to_trash: "Mover a la papelera",
    recover: "Recuperar",
    elements_per_page: "Elementos por página: ",
    no_results: "No se han encontrado resultados",
    generic_tags: "Etiquetas genéricas",
    date: "Fecha",
    date_range: "Rango de fechas",
    bulk_actions: "Acciones en lote",
    homePlayers: "Jugadores locales",
    awayPlayers: "Jugadores visitantes",
    no_coincidences: "No hay coincidencias...",
    id: "Id",
    url: "Url",
    metadatas: "Metadatos",
    value: "Valor",
    not_selected: "Sin asignar",
    back: "Atrás",
    go_back: "Volver",
    uploaded_by: "Subida por",
    reference: "Referencia",
    state: "Estado",
    user_active: {
      inactive: "Inactivo",
      active: "Activo",
      pending: "Pendiente",
    },
    yes: "Sí",
    no: "No",
  },
  resource: {
    history: {
      menu: "Historial",
      advanced_info: "Información avanzada",
      date: "Fecha",
      processed: "Procesada",
      notify: {
        deleted: "Imagenes eliminadas",
        recovered: "Imagenes recuperadas",
      },
      dialog_delete: {
        counter_html:
          "Se moverá <b>%{smart_count} foto</b> a la papelera |||| Se moverán <b>%{smart_count} fotos</b> a la papelera",
        advice_html:
          "Podrás recuperar las fotos cuando quieras con la opción <b>recuperar</b> en la sección <b>Papelera</b>",
      },
      dialog_recover: {
        counter_html:
          "Se recuperará <b>%{smart_count} foto</b> de la papelera |||| Se recuperarán <b>%{smart_count} fotos</b> de la papelera",
      },
      status: {
        ready: "Sí",
        pending: "Pendiente",
      },
    },
    images: {
      notify: {
        deleted: "Imagenes eliminadas",
        recovered: "Imagenes recuperadas",
        selectLimit: "Sólo se pueden seleccionar 40 imágenes como máximo",
      },
    },
    users: {
      create_title: "Nuevo usuario",
      create_button: "Crear usuario",
      edit_title: "Usuario %{id}",
      edit_button: "Guardar Usuario",
      menu: "Usuarios",
      email: "Email",
      active: "Activo",
      action_create: "Nuevo usuario",
      active_users: "Activos",
      inactive_users: "Inactivos",
      active_user: "Activo",
      inactive_user: "Inactivo",
      roles: "Roles",
      groups: "Grupos",
      real_image: "Imagen real",
      downloadOriginal: "Descargar original",
      downloadAppPlayer: "App Player",
      state: "Estado",
      partnership: "Agrupación",
      typeOfpartnership: "Tipo de agrupación",
      internalEventPermission: "Eventos internos",
      change_password: "Cambiar contraseña",
      dowloadPermissionTitle: "Permisos de descarga",
      notify: {
        user_active: "Usuario activado",
        user_disable: "Usuario desactivado",
        deleted: "Usuarios eliminados",
        recovered: "Usuarios recuperados",
      },
      dialog_disable: {
        counter_html:
          "Se desactivará <b>%{smart_count} usuario</b> |||| Se desactivarán <b>%{smart_count} usuarios</b>",
        advice_html:
          "Podrás reactivarlos filtrando en la sección Usuarios por estado: inactivos y seleccionando la opción Reactivar",
      },
      dialog_enable: {
        counter_html:
          "Se reactivará <b>%{smart_count} usuario</b> |||| Se reactivarán <b>%{smart_count} usuarios</b>",
      },
      dialog_delete: {
        counter_html:
          "Se moverá <b>%{smart_count} usuario</b> a la papelera |||| Se moverán <b>%{smart_count} usuarios</b> a la papelera",
        advice_html:
          "Podrás recuperar las usuarios cuando quieras con la opción <b>recuperar</b> en la sección <b>Papelera</b>",
      },
      dialog_recover: {
        counter_html:
          "Se recuperará <b>%{smart_count} usuario</b> de la papelera |||| Se recuperarán <b>%{smart_count} usuarios</b> de la papelera",
      },
      validations: {
        partnershipDescription: {
          required: "Indique el nombre del medio, club, prensa o patrocinador",
        },
        userGrops: {
          required: "No se ha asignado al menos a 1 grupo",
        },
      },
    },
    roles: {
      ROLE_ADMIN: "Administrador",
      ROLE_GLOBAL: "Multimedia",
      ROLE_PHOTOGRAPHER: "Fotógrafo",
      ROLE_TAGGER: "Etiquetador",
      ROLE_MULTIMEDIA: "Gestor",
      ROLE_USER: "Usuario",
    },
    gallery: {
      menu: "Galerías",
      selected_images:
        "%{smart_count} foto seleccionada |||| %{smart_count} fotos seleccionadas",
      download_originals: "Originales",
      download_reduced: "Reducidas",
      downloads: "Descargas",
      compressing_zip: "comprimiendo ZIP",
      completed: "Lista",
      notify_download_start:
        "Tu descarga ha comenzado. Puedes ver el progreso pulsando en el icono de notificación de la barra superior.",
    },
    groups: {
      action_create: "Nuevo grupo",
      create_title: "Nuevo grupo",
      create_button: "Crear grupo",
      edit_title: "Grupo %{id}",
      edit_button: "Guardar grupo",
      menu: "Grupos",
      "assigned-permissions": "Permisos asignados",
      dialog_delete: {
        counter_html:
          "Se moverá <b>%{smart_count} grupo</b> a la papelera |||| Se moverán <b>%{smart_count} grupos</b> a la papelera",
        advice_html:
          "Podrás recuperar los grupos cuando quieras con la opción <b>recuperar</b> en la sección <b>Papelera</b>",
      },
      dialog_recover: {
        counter_html:
          "Se recuperará <b>%{smart_count} grupo</b> de la papelera |||| Se recuperarán <b>%{smart_count} grupos</b> de la papelera",
      },
      notify: {
        recovered: "Grupos recuperados",
        deleted: "Grupos eliminados",
      },
    },
    uploader: {
      menu: "Subir fotos",
      match_act_advise: "Selecciona un partido o un acto para subir fotos",
      select_file: "Selecciona un archivo",
      select_file_mobile: "Seleccionar fotos del dispositivo",
      drag_and_drop: "Arrastra y suelta aquí las imágenes",
      in_queue: "En cola",
      in_process: "En proceso",
      ready: "Listas",
      failed: "Fallidas",
      upload_photos: "Subir fotos",
      photos_in_queue:
        "%{smart_count} foto en cola |||| %{smart_count} fotos en cola",
      ready_photos:
        "%{smart_count} foto lista |||| %{smart_count} fotos listas",
      failed_photos:
        "%{smart_count} foto fallida |||| %{smart_count} fotos fallidas",
      uploading: "Subiendo",
      file_size_error: "%{file} supera el límite de tamaño",
      file_number_error: "No puedes subir más de %{limit} imagenes",
      show_tagged: "Mostrar imágenes etiquetadas",
      duplicate_images:
        "Has arrastrado imagenes que ya están en alguna de las listas",
      invalid_type_error: 'El tipo de archivo de "%{file}" es inválido.',
    },
    tagger: {
      menu: "Etiquetador",
      action_tagger: "Etiquetar",
      quick_tagging: "Etiquetado rápido",
      advancedTagger: {
        title: "Etiquetado avanzado",
        notImagesSelected: "Selecciona una o más imágenes para etiquetar",
        selectedTags: "Etiquetas asignadas:",
        searchTags: "Buscar etiquetas",
        tabGeneric: "Genéricas",
        tabHomeTeam: "Local",
        tabAwayTeam: "Visitante",
        tagged: "Etiquetada",
      },
    },
    downloads: {
      menu: "Descargas",
      download: "Descargar",
      download_singular: "Descarga",
      queued: "En cola",
      ready: "Listo",
      downloaded: "Descargado",
      expired: "Expirado",
      status: "Estado",
      created_at: "Fecha de creación",
      error: "Error",
      go_to_downloads: "Ir a la página de descargas",
    },
  },
  login: {
    login: "Iniciar sesión",
    login_error:
      "Su nombre de usuario o contraseña son incorrectos. Por favor, inténtelo de nuevo.",
    username: "Nombre de Usuario",
    session_expired: "Su sessión ha expirado.",
    current_password: "Contraseña actual",
    password: "Contraseña",
    repeat_password: "Repita la contraseña",
    password_changed: "Contraseña modificada",
    remember_me: "Recuérdame",
    password_forgot: "He olvidado mi contraseña",
    password_complexity:
      "La contraseña debe tener al menos 1 minúscula, 1 mayúscula, 1 carácter especial, 1 número, ningún espacio y una longitud de entre 8 y 15 carácteres.",
    password_complexity_failed: "La contraseña no cumple la complejidad",
    terms_of_use: {
      start: "Acepto las",
      link: "condiciones de uso",
      end: "de las imágenes de LALIGA.",
    },
    login_with_azure: "Entrar con Azure",
    password_recovery: "Recuperar contraseña",
    send_email: "Enviar email",
    password_recovery_complete_text:
      "Correo de recuperación de contraseña enviado",
    change_password: "Cambiar contraseña",
    recoverToken: "Código",
    repeat_password_text: "Las contraseñas deben coincidir",
    check_email:
      "Si su email se encuentra en el sistema, recibirá un email para recuperar su contraseña.",
    login_page_failure:
      "No hemos podido iniciar sesión con tu cuenta de Office365, intenta de nuevo.",
  },
  register: {
    title: "Acceso a Panel Multimedia para la temporada %{season_years}",
    description:
      "Solicitar acceso al repositorio de imágenes de LALIGA (logotipos, marcas, visuales, fuentes, tipografías, balón, trofeo, camisetas, imágenes de jugadores, imágenes de partidos, imágenes de entrenamientos, etc.) (en adelante, las “Imágenes”).",
    request_access: "Solicitar acceso",
    label: {
      name: "Nombre",
      partnershipDescription: "Agrupación",
      typeOfpartnership: "Tipo de agrupación",
      terms_of_use: {
        start: "Acepto los",
        link: "Términos Legales y la Política de Privacidad",
      },
    },
  },
  components: {
    inputDateRange: {
      from: "desde",
      to: "hasta",
    },
    filters: {
      filters: "Filtros",
      see_results:
        "Ver %{smart_count} resultado |||| Ver %{smart_count} resultados",
      active_filters:
        "%{smart_count} filtro activo |||| %{smart_count} filtros activos",
      clean: "Limpiar",
      result_count: "%{smart_count} resultado |||| %{smart_count} resultados",
      clean_filters: "Limpiar filtros",
    },
  },
  footer: {
    all_rights_reserved: "Todos los derechos reservados",
    privacy_and_cookie_policy: "Política de privacidad y cookies",
    privacy_and_cookie_policy_href:
      "https://www.laliga.com/informacion-legal/legal-web",
    privacy_settings: "Configurar privacidad",
  },
  userMenu: {
    groups: "Mis grupos",
    change_password: "Cambiar contraseña",
    logout: "Cerrar sesión",
  },
};
