import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import ZoomInRoundedIcon from "@material-ui/icons/ZoomInRounded";
import IconButton from "@material-ui/core/IconButton";
import React, { useEffect, useRef } from "react";
import SliderSlick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
    },
    zoom: {
      position: "absolute",
      top: 0,
      right: 0,
      padding: theme.spacing(1),
      zIndex: 1,
      cursor: "pointer",
      "& > button": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      },
      "& > svg": {
        color: theme.palette.primary.contrastText,
        fontSize: "36px",
      },
    },
    arrow: {
      width: "20px",
      height: "50px",
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      opacity: 0.8,
      transform: "translate(0,-50%)",
      transition: "opacity 0.2s",
      zIndex: 1,
      cursor: "pointer",
      "&:hover": {
        opacity: 1,
      },
      "& > svg": {
        fontSize: "36px",
      },
    },
    prevArrow: {
      left: 0,
    },
    nextArrow: {
      right: 0,
    },
    disabled: {
      opacity: 0.4,
      cursor: "default",
      "&:hover": {
        opacity: 0.4,
      },
    },
  }),
  { name: "WBO-Slider" }
);

const Slider = React.forwardRef(
  (
    {
      children,
      onChange,
      onClick,
      dots,
      infinite,
      slidesToShow,
      adaptiveHeight,
    },
    ref
  ) => {
    const classes = useStyles();
    const slickRef = useRef(null);
    const settings = {
      dots: dots,
      infinite: infinite,
      slidesToShow: slidesToShow,
      adaptiveHeight: adaptiveHeight,
      beforeChange: (current, next) => onChange(next),
    };

    useEffect(() => {
      ref.current = {
        sliderGoTo: sliderGoTo,
        sliderNext: sliderNext,
        sliderPrev: sliderPrev,
      };
      // eslint-disable-next-line
    }, []);

    const PrevArrow = ({ onClick, currentSlide }) => (
      <div
        className={classNames(classes.arrow, classes.prevArrow, {
          [classes.disabled]: currentSlide === 0,
        })}
        onClick={onClick}
      >
        <NavigateBeforeRoundedIcon />
      </div>
    );

    const NextArrow = ({ onClick, currentSlide, slideCount }) => (
      <div
        className={classNames(classes.arrow, classes.nextArrow, {
          [classes.disabled]: currentSlide === slideCount - 1,
        })}
        onClick={onClick}
      >
        <NavigateNextRoundedIcon />
      </div>
    );

    const sliderGoTo = (index, dontAnimate) => {
      slickRef.current.slickGoTo(index, dontAnimate);
    };

    const sliderNext = () => {
      slickRef.current.slickNext();
    };

    const sliderPrev = () => {
      slickRef.current.slickPrev();
    };

    return (
      <div className={classes.root} ref={ref}>
        <div className={classes.zoom}>
          <IconButton onClick={onClick}>
            <ZoomInRoundedIcon />
          </IconButton>
        </div>
        <SliderSlick
          {...settings}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          ref={slickRef}
        >
          {children}
        </SliderSlick>
      </div>
    );
  }
);

Slider.defaultProps = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  adaptiveHeight: true,
};

export default Slider;
