import PropTypes from "prop-types";
import React from "react";
import { TextInput as RaTextInput } from "react-admin";

const TextInput = ({ variant = "standard", fullWidth = true, ...rest }) => (
  <RaTextInput
    variant={variant}
    fullWidth={fullWidth}
    InputProps={{ disableUnderline: true }}
    {...rest}
  />
);

TextInput.propTypes = {
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default TextInput;
