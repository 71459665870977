import { makeStyles } from "@material-ui/core";
import _get from "lodash/get";
import React, { useState } from "react";
import { ReferenceField, TextField, useTranslate } from "react-admin";

import Filters from "./Filters";
import GridList from "./GridList";
import AdvancedTagger from "./AdvancedTagger";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: "inherit",
    "& .wbo-toolbar-bulkActions": {
      width: "100%",
    },
  },
  gridColumn: {
    flex: "1 1 auto",
  },
  taggerColumn: {
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 35%",
      maxWidth: "500px",
    },
  },
}));

export default (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [filtersValues, setfiltersValues] = useState(false);

  const getTitleFilter = () => {
    const match = _get(filtersValues, "match");
    if (match) {
      return (
        <>
          <ReferenceField
            source="match"
            reference="matches"
            basePath="matches"
            record={filtersValues}
            link={false}
          >
            <TextField source="homeTeam.nickname" />
          </ReferenceField>
          <span style={{ margin: "0 4px" }}>-</span>
          <ReferenceField
            source="match"
            reference="matches"
            basePath="matches"
            record={filtersValues}
            link={false}
          >
            <TextField source="awayTeam.nickname" />
          </ReferenceField>
        </>
      );
    }
    return translate("resource.tagger.action_tagger");
  };

  return (
    <div className={classes.root}>
      <div className={classes.gridColumn}>
        <Filters title={getTitleFilter()} setfiltersValues={setfiltersValues} />
        {filtersValues && (
          <GridList
            filtersValues={filtersValues}
            setfiltersValues={setfiltersValues}
            perPage={30}
            {...props}
          />
        )}
      </div>
      <div className={classes.taggerColumn}>
        <AdvancedTagger filtersValues={filtersValues} />
      </div>
    </div>
  );
};
