export default (theme) => ({
  inputBox: {
    color: theme.palette.text.primary,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(0.5),
    "& label": {
      margin: "0",
    },
    "& button": {
      width: "30px",
      height: "30px",
    },
    "& svg": {
      fontSize: "22px",
    },
    "& input": {
      color: "transparent",
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
  },
  textValues: {
    ...theme.typography.subheading,
    position: "absolute",
    width: "calc(100% - 46px)",
    bottom: 0,
    left: 0,
    paddingLeft: "38px",
    paddingBottom: "12px",
    cursor: "pointer",
    transition:
      "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    "& .focused": {
      color: theme.palette.primary.main,
    },
  },
  boxDateRangeInput: {
    position: "absolute",
    top: "100%",
    left: "0px",
    zIndex: theme.zIndex.drawer,
    boxShadow: theme.shadows[8],
    "& .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover": {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "& .CalendarDay__selected_span": {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "& .CalendarDay__hovered_span, & .CalendarDay__hovered_span:hover": {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "& .CalendarDay__hovered_span:active": {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
});
