import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import _pick from "lodash/pick";
import qs from "qs";
import React, { useEffect, useState } from "react";
import {
  Notification,
  useLocale,
  useRedirect,
  useTranslate,
} from "react-admin";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import LocaleSwitch from "../../components/custom/LocaleSwitch";
import withTheme from "../../components/ra-components/pageDecorators/withTheme";
import { USERDATA_DATA_KEYS } from "../../config/constants";
import {
  getToken,
  getTokenData,
  setRefreshToken,
  setToken,
} from "../../providers/authProviderToken";
import { setUserConfig } from "../../utils/userConfig";
import ChangePasswordForm from "./ChangePasswordForm";
import LoginForm from "./LoginForm";
import PasswordRecoveryForm from "./PasswordRecoveryForm";
import { useStyles } from "./styles";

export const LOGIN_FORM = "LOGIN_FORM";
export const PASSWORD_RECOVERY_FORM = "PASSWORD_RECOVERY_FORM";
export const PASSWORD_RECOVERY_FORM_2 = "PASSWORD_RECOVERY_FORM_2";

const LoginPage = (props) => {
  const [pageView, setPageView] = useState(LOGIN_FORM);
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();
  const locale = useLocale();

  const { token } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const [isLoading, setLoading] = useState(!!token);

  useEffect(() => {
    const token = getToken();

    if (token) {
      redirect("/");
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (token) {
      setToken(token);
      const tokenData = getTokenData();
      setUserConfig(_pick(tokenData, USERDATA_DATA_KEYS));
      setRefreshToken();
      window.location.href = "/#/";
    }
  }, [token]);

  let title;
  let Component;
  switch (pageView) {
    default:
    case LOGIN_FORM: {
      title = translate("login.login");
      Component = LoginForm;
      break;
    }
    case PASSWORD_RECOVERY_FORM: {
      title = translate("login.password_recovery");
      Component = PasswordRecoveryForm;
      break;
    }
    case PASSWORD_RECOVERY_FORM_2: {
      title = translate("login.password_recovery");
      Component = ChangePasswordForm;
      break;
    }
  }

  return (
    <div className={classes.mainContainer}>
      {!token && (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
          language={locale}
          useRecaptchaNet
          key={locale}
        >
          <div className={classes.localeContainer}>
            <LocaleSwitch localOnly />
          </div>
          {!isLoading && (
            <img
              className={classes.logo}
              src="/img/logos/laliga-color-h-2023.svg"
              alt="logo"
            />
          )}
          <div className={classNames({ [classes.displayNone]: isLoading })}>
            <Card className={classes.card}>
              <CardHeader title={title} />
              <CardContent>
                <Component setPageView={setPageView} setLoading={setLoading} />
              </CardContent>
            </Card>
          </div>
          <Notification />
        </GoogleReCaptchaProvider>
      )}
      <div className={classNames({ [classes.displayNone]: !isLoading })}>
        <CircularProgress size={80} />
      </div>
    </div>
  );
};

export default withTheme(LoginPage, "light");
