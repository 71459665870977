import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import {
  CRUD_DELETE_MANY,
  useDeleteMany,
  useListContext,
  useNotify,
  useRefresh,
} from "react-admin";

import Dialog from "../../custom/Dialog";
import { useButtonStyles, useStyles } from "./styles";

const DeleteButton = ({
  label,
  disabled,
  dialogContent,
  cancelButton,
  confirmButton,
  resource,
  onConfirm,
  isTrash = false,
}) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const [isOpen, setOpen] = useState(false);
  const { contextResource, selectedIds, onSelect } = useListContext();
  if (!resource) {
    resource = contextResource;
  }

  const notify = useNotify();
  const refresh = useRefresh();
  const [deleteMany, { loading }] = useDeleteMany(resource, selectedIds, {
    action: CRUD_DELETE_MANY,
    onSuccess: () => {
      refresh();
      notify(
        isTrash
          ? `resource.${resource}.notify.recovered`
          : `resource.${resource}.notify.deleted`,
        "info",
        {
          smart_count: selectedIds.length,
        }
      );
      onSelect([]);
      setOpen(false);
    },
    onFailure: (error) => {
      notify(
        typeof error === "string"
          ? error
          : error.message || "ra.notification.http_error",
        "warning"
      );
      setOpen(false);
    },
  });

  const handleClick = useCallback((e) => {
    setOpen(true);
    e.stopPropagation();
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleDelete = useCallback(
    (e) => {
      deleteMany();
    },
    [deleteMany]
  );

  const handleOnConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
      setOpen(false);
      refresh();
      onSelect([]);
    } else {
      handleDelete();
    }
  }, [onConfirm, handleDelete, refresh, onSelect]);

  return (
    <>
      <Button
        size="small"
        disabled={disabled}
        variant="contained"
        color="primary"
        onClick={handleClick}
        classes={buttonClasses}
      >
        {loading && (
          <CircularProgress
            className={classes.loading}
            size={16}
            color="inherit"
          />
        )}
        <span className={classNames({ [classes.hidden]: loading })}>
          {label}
        </span>
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleDialogClose}
        dialogContent={dialogContent}
        cancelButton={cancelButton}
        confirmButton={confirmButton}
        onConfirm={handleOnConfirm}
      />
    </>
  );
};

DeleteButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  dialogContent: PropTypes.node,
  cancelButton: PropTypes.node,
  confirmButton: PropTypes.node,
  resource: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default DeleteButton;
