import makeStyles from "@material-ui/core/styles/makeStyles";

export const useMenuItemLinkStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    "&.MuiListItem-button:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary.contrastText,
      },
    },
    "& .MuiListItemIcon-root": {
      color:
        theme.palette.type === "dark" ? theme.palette.common.white : "#828282",
    },
    cursor: "pointer",
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

export const useStyles = makeStyles((theme) => ({
  localeSwitchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const useMenuItemStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  menuItem: {
    width: "100%",
    padding: "6px 16px",
  },
  logout: {
    color: theme.palette.error.main,
  },
}));
