import { useMediaQuery } from "@material-ui/core";
import React, { createElement } from "react";
import { MenuItemLink, useTranslate } from "react-admin";
import { useSelector } from "react-redux";

import { getMenu } from "../../../../../config/menu";
import LocaleSwitch from "../../../../custom/LocaleSwitch";
import ThemeSelector from "../../../../custom/ThemeSelector";
import { useStyles, useMenuItemLinkStyles } from "./styles";
import UserMenuMobile from "./UserMenuMobile";

const CustomMenu = ({ onMenuClick }) => {
  const menuItemClasses = useMenuItemLinkStyles();
  const classes = useStyles();
  const isTabletOrLower = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const translate = useTranslate();

  return (
    <>
      <ThemeSelector />
      {isMobile && (
        <div className={classes.localeSwitchContainer}>
          <LocaleSwitch />
        </div>
      )}
      {getMenu().map((item) => (
        <MenuItemLink
          key={item.label}
          to={item.path}
          primaryText={translate(item.label)}
          leftIcon={item.icon && createElement(item.icon)}
          onClick={onMenuClick}
          sidebarIsOpen={isSidebarOpen}
          classes={menuItemClasses}
        />
      ))}
      {isTabletOrLower && <UserMenuMobile />}
    </>
  );
};

export default CustomMenu;
