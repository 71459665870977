import spanishMessages from "@blackbox-vision/ra-language-spanish";
import { deepmerge } from "@material-ui/utils";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import { resolveBrowserLocale } from "react-admin";

import { getUserConfig } from "../../utils/userConfig";
import englishTranslations from "./en";
import spanishTranslations from "./es";

const messages = {
  en: deepmerge(englishMessages, englishTranslations),
  es: deepmerge(spanishMessages, spanishTranslations),
};

let defaultLanguage = getUserConfig("language");
const browserLanguage = resolveBrowserLocale();

if (!defaultLanguage) {
  if (browserLanguage === "es" || browserLanguage === "en") {
    defaultLanguage = browserLanguage;
  } else {
    defaultLanguage = "es";
  }
}

const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  defaultLanguage
);

export default i18nProvider;
