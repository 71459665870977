import {
  Button,
  Collapse,
  Drawer,
  IconButton,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  ExpandMore,
  FilterList as FilterListIcon,
  ReplayRounded as ReplayRoundedIcon,
} from "@material-ui/icons";
import { deepmerge } from "@material-ui/utils";
import classNames from "classnames";
import equal from "fast-deep-equal";
import isNil from "lodash/isNil";
import _omitBy from "lodash/omitBy";
import React, { useCallback, useMemo, useState } from "react";
import { useListContext, useTranslate } from "react-admin";
import { Form, FormSpy } from "react-final-form";

import PerPageSelect from "../Pagination/PerPageSelect";
import ResultCount from "./ResultCount";
import {
  useDesktopClasses,
  useDrawerBackdropClasses,
  useDrawerClasses,
  useHorizontalTextClasses,
  useMobileClasses,
  usePaperClasses,
} from "./styles";
import VerticalButton from "./VerticalButton";
import FilterInputs from "./FilterInputs";

function getActiveFiltersCount(formValues) {
  const renderFilters = _omitBy(formValues, isNil);
  return Object.keys(renderFilters).length;
}

const HorizontalScrollText = ({ className, text }) => {
  const classes = useHorizontalTextClasses();

  return (
    <div className={classNames(className, classes.root)}>
      <div className={classes.position}>
        <div className={classes.text}>{text}</div>
      </div>
    </div>
  );
};

const CustomFilters = (props) => {
  const {
    filterDefaultValues = {},
    children,
    perPage,
    setPerPage,
    ...filterProps
  } = props;
  const { setFilters, filterValues = {} } = filterProps;
  const desktopStyles = useDesktopClasses();
  const mobileClasses = useMobileClasses();
  const paperClasses = usePaperClasses();
  const backdropClasses = useDrawerBackdropClasses();
  const drawerClasses = useDrawerClasses();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const translate = useTranslate();
  const listContext = useListContext();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const [isOpen, setOpen] = useState(true);

  const handleSpyChange = useCallback(
    ({ values }) => {
      if (!equal(values, filterValues)) {
        setFilters(values);
      }
    },
    [setFilters, filterValues]
  );

  const initialValues = useMemo(
    () => {
      if (Object.keys(filterValues).length === 0) {
        return deepmerge(filterDefaultValues, filterValues);
      }
      return filterValues;
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Form initialValues={initialValues} onSubmit={() => {}}>
      {({ handleSubmit, form: { reset, initialize }, values }) => {
        const activeFilterCount = getActiveFiltersCount(values);

        return (
          <>
            <form onSubmit={handleSubmit}>
              {isDesktop ? (
                <div className={desktopStyles.root}>
                  <Paper classes={paperClasses} elevation={4}>
                    <div
                      className={classNames(desktopStyles.header, {
                        [desktopStyles.headerOpen]: !!isOpen,
                      })}
                      onClick={() => setOpen(!isOpen)}
                    >
                      <div className={desktopStyles.headerTitle}>
                        <FilterListIcon />
                        {translate("components.filters.filters")}
                      </div>
                      <ExpandMore />
                    </div>
                    <Collapse in={isOpen}>
                      <FilterInputs
                        initialize={initialize}
                        values={values}
                        filterValues={filterValues}
                      >
                        {children}
                      </FilterInputs>
                    </Collapse>
                  </Paper>
                  <div className={desktopStyles.info}>
                    <ResultCount />
                    <Button
                      endIcon={<ReplayRoundedIcon />}
                      onClick={() => reset(filterDefaultValues)}
                    >
                      {translate("components.filters.clean_filters")}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className={mobileClasses.root}>
                    <div className={mobileClasses.filtersBar}>
                      <VerticalButton
                        size="small"
                        icon={<FilterListIcon />}
                        text={translate("components.filters.filters")}
                        onClick={() => setIsDrawerOpen(true)}
                      />
                      <div className={mobileClasses.filterString}>
                        <HorizontalScrollText
                          text={
                            !!activeFilterCount &&
                            translate("components.filters.active_filters", {
                              smart_count: getActiveFiltersCount(values),
                            })
                          }
                        />
                      </div>
                      <VerticalButton
                        size="small"
                        icon={<ReplayRoundedIcon />}
                        text={translate("components.filters.clean")}
                        onClick={() => reset(filterDefaultValues)}
                      />
                    </div>
                    <div className={mobileClasses.info}>
                      <ResultCount />
                      {isTablet && (
                        <PerPageSelect
                          value={perPage}
                          setPerPage={setPerPage}
                        />
                      )}
                    </div>
                  </div>
                  <Drawer
                    classes={drawerClasses}
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    ModalProps={{ BackdropProps: { classes: backdropClasses } }}
                    variant="persistent"
                  >
                    <div className={mobileClasses.drawerTitleBar}>
                      <div className={mobileClasses.drawerTitle}>
                        <IconButton
                          disabled
                          onClick={() => setIsDrawerOpen(true)}
                        >
                          <FilterListIcon />
                        </IconButton>
                        <div className={mobileClasses.drawerTitleText}>
                          {translate("components.filters.filters")}
                        </div>
                      </div>
                      <IconButton onClick={() => setIsDrawerOpen(false)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className={mobileClasses.drawerCleanFiltersBar}>
                      <Button
                        startIcon={<ReplayRoundedIcon />}
                        onClick={() => reset(filterDefaultValues)}
                      >
                        {translate("components.filters.clean_filters")}
                      </Button>
                    </div>
                    <div className={mobileClasses.drawerContent}>
                      <FilterInputs
                        initialize={initialize}
                        values={values}
                        filterValues={filterValues}
                      >
                        {children}
                      </FilterInputs>
                    </div>
                    <div className={mobileClasses.filterConfirm}>
                      <Button
                        className={mobileClasses.filterConfirmButton}
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => setIsDrawerOpen(false)}
                      >
                        {translate("components.filters.see_results", {
                          smart_count: listContext.total,
                        })}
                      </Button>
                    </div>
                  </Drawer>
                </>
              )}
            </form>
            <FormSpy
              subscription={{ values: true }}
              onChange={handleSpyChange}
            />
          </>
        );
      }}
    </Form>
  );
};

export default CustomFilters;
