import {
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import classnames from "classnames";
import _get from "lodash/get";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import { getImageResize } from "../../../../utils";
import { MoreInfoButton } from "../../../buttons";
import Modal from "../../../custom/Modal";
import { useCheckboxClasses, useShadowClasses, useStyles } from "./styles";

const CardShadow = ({ onClick }) => {
  const classes = useShadowClasses();
  return <div role="button" className={classes.root} onClick={onClick} />;
};

const MediaCard = ({
  record,
  selected,
  toggleSelected,
  imageSource,
  titleSource,
  extraComponent,
  selectable,
  imageSize,
  imageModalSize,
  moreInfo,
}) => {
  const classes = useStyles(moreInfo);
  const checkboxClasses = useCheckboxClasses();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleZoomClick = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  return (
    <>
      <Card
        elevation={4}
        className={classnames(classes.root, {
          [classes.imageSelected]: selectable ? selected : false,
        })}
      >
        <CardMedia
          className={classes.cardImage}
          onClick={toggleSelected}
          src={getImageResize(_get(record, imageSource), imageSize)}
          component="img"
        />
        <CardContent className={classes.content}>
          {titleSource && (
            <div className={classes.titleContainer}>
              <div>{_get(record, titleSource)}</div>
              {moreInfo && <MoreInfoButton record={record} small />}
            </div>
          )}
          {React.isValidElement(extraComponent) &&
            React.cloneElement(extraComponent, { record })}
        </CardContent>
        {selectable && (
          <>
            <CardShadow onClick={toggleSelected} />
            <Checkbox
              classes={checkboxClasses}
              checked={selected}
              onClick={toggleSelected}
              color="primary"
              disableRipple={true}
            />
            <IconButton
              className={classes.zoomInButton}
              onClick={handleZoomClick}
            >
              <ZoomInIcon />
            </IconButton>
          </>
        )}
      </Card>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        scroll="body"
        maxWidth="lg"
      >
        <img
          className={classes.modalImg}
          src={getImageResize(_get(record, imageSource), imageModalSize)}
          alt=""
        />
        <IconButton
          className={classes.modalClose}
          onClick={() => setIsModalOpen(false)}
        >
          <Close />
        </IconButton>
      </Modal>
    </>
  );
};

MediaCard.defaultProps = {
  selectable: true,
  imageSize: "small",
  imageModalSize: "medium",
};

MediaCard.propTypes = {
  record: PropTypes.object,
  selected: PropTypes.bool,
  toggleSelected: PropTypes.func,
  imageSource: PropTypes.string,
  titleSource: PropTypes.string,
  extraComponent: PropTypes.node,
  selectable: PropTypes.bool,
  imageSize: PropTypes.string,
  imageModalSize: PropTypes.string,
};

export default MediaCard;
