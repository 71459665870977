import PropTypes from "prop-types";
import React from "react";
import { useListContext } from "react-admin";

import RecoverButton from "./RecoverButton";
import TrashButton from "./TrashButton";

const RecoverTrashButton = ({ recoverResource, deleteResource, ...rest }) => {
  const { filterValues } = useListContext();
  const isTrash = !!filterValues.trashed;

  if (isTrash) {
    return <RecoverButton recoverResource={recoverResource} {...rest} />;
  } else {
    return <TrashButton deleteResource={deleteResource} {...rest} />;
  }
};

RecoverTrashButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  recoverResource: PropTypes.string,
  deleteResource: PropTypes.string,
};

export default RecoverTrashButton;
