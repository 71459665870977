import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { FormDataConsumer } from "react-admin";
import Input from "./Input";

const MatchInput = ({ idSeason, sourceSeason, ...rest }) => {
  if (sourceSeason) {
    return (
      <FormDataConsumer>
        {({ formData }) => (
          <Input idSeason={_get(formData, sourceSeason)} {...rest} />
        )}
      </FormDataConsumer>
    );
  } else {
    return <Input idSeason={idSeason} {...rest} />;
  }
};

MatchInput.propTypes = {
  idSeason: PropTypes.number,
  sourceSeason: PropTypes.string,
  sourceChampionship: PropTypes.string,
  sourceGameweek: PropTypes.string,
  sourceMatch: PropTypes.string,
};

export default MatchInput;
