import equal from "fast-deep-equal";
import { useEffect } from "react";

const FilterInputs = ({ initialize, filterValues, values, children }) => {
  useEffect(() => {
    if (!equal(values, filterValues)) {
      initialize(filterValues);
    }
    // eslint-disable-next-line
  }, [filterValues]);

  return children;
};

export default FilterInputs;
