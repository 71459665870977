import isPlainObject from "lodash/isPlainObject";
import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  HttpError,
} from "react-admin";
import { ITEMS_PER_PAGE, ITEMS_PER_PAGE_DEFAULT } from "../config/constants";
import listResources from "../config/resources";

export default (apiUrl, httpClient) => {
  const getQueryParams = (type, params) => {
    let query = {};

    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        const { pagination = {}, sort = {}, filter = false } = params;
        const { page, perPage } = pagination;
        const { field, order } = sort;

        if (order) query[`order[${field}]`] = order;
        if (page) query.page = page;
        if (perPage)
          query.itemsPerPage = ITEMS_PER_PAGE.includes(perPage)
            ? perPage
            : ITEMS_PER_PAGE_DEFAULT;
        if (filter) query = { ...query, ...getQueryFilters(filter) };

        if (type === GET_MANY_REFERENCE && params.target) {
          query[params.target] = params.id;
        }

        break;
      case GET_MANY:
        const { filter: filterMany } = params;

        if (filterMany) query = { ...query, ...getQueryFilters(filterMany) };
        break;
      case GET_ONE:
        const { filter: filterGetOne = false } = params;
        if (filterGetOne)
          query = { ...query, ...getQueryFilters(filterGetOne) };
        break;
      default:
        break;
    }

    return query;
  };

  const getQueryFilters = (filter) => {
    let queryFilters = {};
    if (filter) {
      const buildFilterParams = (key, nestedFilter, rootKey) => {
        const filterValue = nestedFilter[key];

        if (Array.isArray(filterValue)) {
          filterValue.sort().forEach((arrayFilterValue, index) => {
            queryFilters[`${rootKey}[${index}]`] = arrayFilterValue;
          });
          return;
        }

        if (!isPlainObject(filterValue)) {
          queryFilters[rootKey] = filterValue;
          return;
        }

        Object.keys(filterValue).forEach((subKey) => {
          if (
            rootKey === "exists" ||
            [
              "after",
              "before",
              "strictly_after",
              "strictly_before",
              "lt",
              "gt",
              "lte",
              "gte",
              "between",
            ].includes(subKey)
          ) {
            return buildFilterParams(
              subKey,
              filterValue,
              `${rootKey}[${subKey}]`
            );
          }
          buildFilterParams(subKey, filterValue, `${rootKey}.${subKey}`);
        });
      };

      Object.keys(filter).forEach((key) => {
        buildFilterParams(key, filter, key);
      });
    }
    return queryFilters;
  };

  const getApiResource = (keyResource) => {
    return listResources[keyResource].endpoint;
  };

  const checkIfError = (json, error) => {
    if (
      !json &&
      (!error.status ||
        (error.status !== 500 && (error.status < 200 || error.status >= 300)))
    ) {
      let message = "commons.generic_error";
      if (error.body) {
        if (error.body["hydra:description"]) {
          message = error.body["hydra:description"];
        } else if (error.body.detail) {
          message = error.body.detail;
        }
      }
      throw new HttpError(message, error.status, error);
    }
  };

  return {
    getList: (resource, params) => {
      const query = getQueryParams(GET_LIST, params);
      const url = `${apiUrl}/${getApiResource(resource)}?${stringify(query)}`;
      return httpClient(url).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json && json["hydra:member"] ? json["hydra:member"] : [],
          total:
            json && json["hydra:totalItems"] ? json["hydra:totalItems"] : 0,
        };
      });
    },

    getManyReference: (resource, params) => {
      const query = getQueryParams(GET_MANY_REFERENCE, params);
      const url = `${apiUrl}/${getApiResource(resource)}?${stringify(query)}`;

      return httpClient(url).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json && json["hydra:member"] ? json["hydra:member"] : [],
          total:
            json && json["hydra:totalItems"] ? json["hydra:totalItems"] : 0,
        };
      });
    },

    getOne: (resource, params) => {
      const query = getQueryParams(GET_ONE, params);
      const url = `${apiUrl}/${getApiResource(resource)}/${
        params.id
      }?${stringify(query)}`;

      return httpClient(url).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json,
        };
      });
    },

    getMany: (resource, params) => {
      const query = getQueryParams(GET_MANY, {
        filter: { id: params.ids },
      });
      const url = `${apiUrl}/${getApiResource(resource)}?${stringify(query)}`;

      return httpClient(url).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json && json["hydra:member"] ? json["hydra:member"] : [],
        };
      });
    },

    update: (resource, params) =>
      httpClient(`${apiUrl}/${getApiResource(resource)}/${params.id}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
      }).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json,
        };
      }),

    updateMany: (resource, params) => {
      const query = getQueryParams(GET_MANY, {
        filter: { id: params.ids },
      });
      const url = `${apiUrl}/${getApiResource(resource)}?${stringify(query)}`;
      return httpClient(url, {
        method: "PATCH",
        body: JSON.stringify(params.data),
      }).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json,
        };
      });
    },

    updateManyCustom: (resource, params) =>
      httpClient(`${apiUrl}/${getApiResource(resource)}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json,
        };
      }),

    create: (resource, params) =>
      httpClient(`${apiUrl}/${getApiResource(resource)}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: { ...params.data, id: json.id },
        };
      }),

    delete: (resource, params) =>
      httpClient(`${apiUrl}/${getApiResource(resource)}/${params.id}`, {
        method: "DELETE",
        body: JSON.stringify(params.data),
      }).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json,
        };
      }),

    deleteMany: (resource, params) => {
      const query = getQueryParams(GET_MANY, {
        filter: { id: params.ids },
      });
      const url = `${apiUrl}/${getApiResource(
        resource
      )}/multidelete?${stringify(query)}`;
      return httpClient(url, {
        method: "DELETE",
        body: JSON.stringify(params.data),
      }).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json,
        };
      });
    },

    createImage: (resource, params) =>
      httpClient(`${apiUrl}/${getApiResource(resource)}`, {
        method: "POST",
        body: params.data,
        headers: {
          Accept: "text/plain, */*; q=0.01",
          "Mime-Type": "multipart/form-data",
        },
      }).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: { ...params.data, id: json.id },
        };
      }),

    patch: (resource, params) =>
      httpClient(`${apiUrl}/${getApiResource(resource)}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json })),

    getLineups: (resource, params) => {
      const query = getQueryParams(GET_LIST, params);
      const url = `${apiUrl}/${getApiResource(resource)}?${stringify(query)}`;
      return httpClient(url).then(({ json, ...error }) => {
        checkIfError(json, error);
        return {
          data: json,
        };
      });
    },

    addMetadata: (resource, params) =>
      httpClient(
        `${apiUrl}/${getApiResource(resource)}/${params.id}/metadata`,
        {
          method: "PUT",
          body: JSON.stringify(params.metadata),
        }
      ).then(({ json, ...error }) => {
        checkIfError(json, error);

        let data = params.data;
        const metadataIndex = data.metadatas.findIndex(
          (element) =>
            element.value === params.metadata.value &&
            element.metadataType.id === params.metadata.metadataType.id
        );
        if (metadataIndex === -1) {
          data.metadatas.push(json);
        } else {
          data.metadatas[metadataIndex] = json;
        }

        return {
          data: data,
        };
      }),

    removeMetadata: (resource, params) =>
      httpClient(
        `${apiUrl}/${getApiResource(resource)}/${params.id}/metadata`,
        {
          method: "DELETE",
          body: JSON.stringify(params.metadata),
        }
      ).then(({ json, ...error }) => {
        checkIfError(json, error);
        let data = { ...params.data };
        const metadataIndex = data.metadatas.findIndex(
          (element) => element.id === params.metadata.id
        );
        if (metadataIndex !== -1) {
          data.metadatas.splice(metadataIndex, 1);
        }
        return {
          data: data,
        };
      }),
  };
};
