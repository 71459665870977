import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import {
  DateField,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";

import MoreInfoDrawer from "../../../../components/custom/MoreInfoDrawer";
import MobileList from "../../../../components/datagrid/MobileList";
import { ImageField } from "../../../../components/fields";
import { useStyles } from "./styles";

const HistoryMobileList = (props) => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const resource = "images";

  const classes = useStyles();
  const [isOpen, setOpen] = useState([]);

  const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const handleSetOpen = (id) => {
    let newIsOpen = [...isOpen];
    if (newIsOpen.includes(id)) {
      let index = newIsOpen.findIndex((item) => item === id);
      newIsOpen.splice(index, 1);
    } else {
      newIsOpen.push(id);
    }
    setOpen(newIsOpen);
  };

  return (
    <MobileList
      {...props}
      content={(record, id) => (
        <div className={classes.mobileListRoot}>
          <div className={classes.mobileListImage}>
            <div>
              <ImageField
                record={record}
                source="publicImageResizes"
                size="small"
              />
            </div>
          </div>
          {isNotMobile && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <Typography noWrap>
                {translate("commons.name")}: {record.name}
              </Typography>
              <Typography noWrap>
                {translate("commons.uploaded_by")}: {record.user.email}
              </Typography>
              <Typography noWrap>
                {translate("resource.history.date")}:{" "}
                {<DateField source="createdAt" record={record} showTime />}
              </Typography>
              <Typography noWrap>
                {translate("resource.history.processed")}:{" "}
                {
                  <span>
                    {translate(`resource.history.status.${record.status}`)}
                  </span>
                }
              </Typography>
            </div>
          )}
          <MoreInfoDrawer
            record={record}
            open={isOpen.includes(id)}
            setOpen={() => handleSetOpen(id)}
          />
        </div>
      )}
      actions={[
        {
          label: (record, isTrash) =>
            isTrash
              ? translate("commons.recover")
              : translate("commons.delete"),
          onClick: (record, isTrash) => {
            dataProvider
              .delete(resource, {
                id: record.id,
                data: { trashed: !isTrash },
              })
              .then(() => {
                notify(
                  isTrash
                    ? "resource.history.notify.recovered"
                    : "resource.history.notify.deleted",
                  "info"
                );
                refresh();
              });
          },
        },
        {
          label: "Más info",
          onClick: (record) => {
            handleSetOpen(record.id);
          },
        },
      ]}
    />
  );
};

export default HistoryMobileList;
