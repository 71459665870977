import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      minHeight: "34.5px",
    },
    label: {
      color: theme.palette.text.primary,
      textTransform: "capitalize",
    },
    buttonTypeTeam: {
      padding: theme.spacing(0),
      minWidth: "auto",
    },
    loading: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
  { name: "wbo-tagButton" }
);

export const useButtonStyle = makeStyles((theme) => ({
  root: {
    color: ({ color }) => (color ? color : theme.palette.primary.main),
    border: ({ color }) =>
      `1px solid ${color ? color : theme.palette.primary.main}`,
    "&:hover": {
      border: ({ color }) =>
        `1px solid ${color ? color : theme.palette.primary.main}`,
    },
  },
  disabled: {
    "&.Mui-disabled": {
      opacity: "0.8",
      color: ({ color }) => (color ? color : theme.palette.primary.main),
      border: ({ color }) =>
        `1px solid ${color ? color : theme.palette.primary.main}`,
      "&:hover": {
        border: ({ color }) =>
          `1px solid ${color ? color : theme.palette.primary.main}`,
      },
    },
  },
}));
