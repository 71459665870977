import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  useTranslate,
} from "react-admin";
import CustomList from "../../components/ra-components/List";
import MobileList from "../../components/datagrid/MobileList";
import { Grid, useMediaQuery } from "@material-ui/core";
import SelectInput from "../../components/inputs/SelectInput";
import { useStatusFieldClasses, useStyles, useMobileStyles } from "./styles";
import DownloadButton from "../../components/custom/DownloadImages/DownloadButton";

const Filters = (props) => (
  <Grid container key="grid" spacing={2}>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <SelectInput
        label="resource.downloads.status"
        source="status"
        choices={[
          { id: "queued", name: "resource.downloads.queued" },
          { id: "ready", name: "resource.downloads.ready" },
          { id: "downloaded", name: "resource.downloads.downloaded" },
          { id: "expired", name: "resource.downloads.expired" },
          { id: "error", name: "resource.downloads.error" },
        ]}
        translateChoice
        allowEmpty
      />
    </Grid>
  </Grid>
);

const StatusField = (props) => {
  const {
    record: { status },
  } = props;
  const translate = useTranslate();
  const classes = useStatusFieldClasses();

  return (
    <span className={classes[status]}>
      {translate(`resource.downloads.${status}`)}
    </span>
  );
};

export default (props) => {
  const classes = useStyles();
  const mobileClasses = useMobileStyles();
  const statusClasses = useStatusFieldClasses();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const translate = useTranslate();

  return (
    <CustomList
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={<Filters />}
    >
      {isDesktop ? (
        <Datagrid>
          <FunctionField
            source="id"
            sortable={false}
            render={(record) =>
              `${translate("resource.downloads.download_singular")} ${
                record.id
              }`
            }
          />
          <StatusField label="resource.downloads.status" source="status" />
          <TextField source="total" sortable={false} />
          <DateField label="resource.downloads.created_at" source="createdAt" />
          <DownloadButton headerClassName={classes.downloadButtonHeader} />
        </Datagrid>
      ) : (
        <MobileList
          content={(record) => {
            const { status, id } = record;

            return (
              <div className={mobileClasses.root}>
                <span className={mobileClasses.nameField}>
                  <span>{`${translate(
                    "resource.downloads.download_singular"
                  )} ${id}`}</span>
                  <DateField
                    className={mobileClasses.dateText}
                    record={record}
                    source="createdAt"
                  />
                </span>
                <span className={statusClasses[status]}>
                  {translate(`resource.downloads.${status}`)}
                </span>
                <DownloadButton
                  record={record}
                  iconButton
                  className={mobileClasses.downloadField}
                />
              </div>
            );
          }}
        />
      )}
    </CustomList>
  );
};
