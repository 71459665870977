import Grid from "@material-ui/core/Grid";
import React from "react";

import { TextInput } from "../../../../components/inputs";

const Filters = (props) => (
  <Grid container>
    <Grid item xs={12}>
      <TextInput label="commons.name" source="name" />
    </Grid>
  </Grid>
);

export default Filters;
