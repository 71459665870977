import { getUserConfig } from "../utils/userConfig";

export const ROLES = {
  ROLE_ADMIN: "ALL",
  ROLE_MULTIMEDIA: ["uploader", "history", "gallery", "downloads", "tagger"],
  ROLE_PHOTOGRAPHER: ["uploader", "history"],
  ROLE_TAGGER: ["tagger"],
  ROLE_GLOBAL: ["gallery", "downloads"],
  ROLE_USER: ["gallery", "downloads"],
};

export const checkPermission = (section) => {
  const myRoles = getUserConfig("roles");

  if (myRoles) {
    for (var i = 0; i < myRoles.length; i++) {
      const role = myRoles[i];

      if (ROLES[role]) {
        if (ROLES[role] === "ALL") {
          return true;
        }
        if (ROLES[role].includes(section)) {
          return true;
        }
      }
    }
  }

  return false;
};
