import React from "react";

import Create from "../../components/ra-components/Create";
import Form from "./form";

export default (props) => (
  <Create {...props}>
    <Form {...props} />
  </Create>
);
