import PropTypes from "prop-types";
import React from "react";

import Modal from "../Modal";
import { useStyles } from "./styles";

const Dialog = ({
  open,
  onClose,
  dialogContent,
  cancelButton,
  confirmButton,
  onConfirm,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={classes.dialogContent}>{dialogContent}</div>
      <div className={classes.dialogButtons}>
        {cancelButton && (
          <div>
            {React.cloneElement(cancelButton, {
              onClick: onClose,
            })}
          </div>
        )}
        {confirmButton && (
          <div>{React.cloneElement(confirmButton, { onClick: onConfirm })}</div>
        )}
      </div>
    </Modal>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dialogContent: PropTypes.node,
  cancelButton: PropTypes.node,
  confirmButton: PropTypes.node,
  onConfirm: PropTypes.func,
};

export default Dialog;
