import React from "react";
import {
  BooleanInput,
  choices,
  FormDataConsumer,
  required,
  useTranslate,
} from "react-admin";
import { useForm } from "react-final-form";
import { password_complexity } from "../../../utils/password";

import { PasswordInput } from "../../../components/inputs";

const PasswordInputs = ({ isCreate }) => {
  const form = useForm();
  const translate = useTranslate();

  const handleChangePasswordToggle = (checked, form) => {
    if (!checked) {
      form.change("plainPassword", null);
      form.change("repeatPlainPassword", null);
    }
  };

  if (isCreate) {
    return (
      <>
        <PasswordInput
          source="plainPassword"
          label={"login.password"}
          validate={[required(), password_complexity()]}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <PasswordInput
              source="repeatPlainPassword"
              label={"login.repeat_password"}
              validate={[
                required(),
                choices([formData.plainPassword], "login.repeat_password_text"),
              ]}
            />
          )}
        </FormDataConsumer>
        <p style={{ fontSize: "0.8rem" }}>
          {translate("login.password_complexity")}
        </p>
      </>
    );
  } else {
    return (
      <>
        <BooleanInput
          label="resource.users.change_password"
          source="change_password"
          onChange={(checked) => handleChangePasswordToggle(checked, form)}
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.change_password && (
              <>
                <PasswordInput
                  source="plainPassword"
                  label={"login.password"}
                  validate={[required(), password_complexity()]}
                  autoComplete="new-password"
                />
                <PasswordInput
                  source="repeatPlainPassword"
                  label={"login.repeat_password"}
                  autoComplete="new-password"
                  validate={[
                    required(),
                    choices(
                      [formData.plainPassword],
                      "login.repeat_password_text"
                    ),
                  ]}
                />
                <p style={{ fontSize: "0.8rem" }}>
                  {translate("login.password_complexity")}
                </p>
              </>
            )
          }
        </FormDataConsumer>
      </>
    );
  }
};

export default PasswordInputs;
