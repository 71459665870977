import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    overlay: {
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: theme.zIndex.drawer,
        background: "rgba(0,0,0,0.5)",
        opacity: 0,
        transition: "opacity 0.3s",
      },
    },
    overlayActive: {
      [theme.breakpoints.between("sm", "md")]: {
        opacity: 1,
        height: "100%",
      },
    },
    container: {
      position: "fixed",
      bottom: 0,
      right: 0,
      zIndex: theme.zIndex.modal,
      background:
        theme.palette.type === "dark"
          ? theme.palette.grey[900]
          : theme.palette.common.white,
      color: theme.palette.text.primary,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "450px",
      },
      [theme.breakpoints.up("lg")]: {
        right: theme.spacing(4),
      },
    },
    collapsableContainer: {
      height: "100vh",
      maxHeight: `calc(100vh - 35px - ${theme.values.toolbar.height.xs}px)`,
      display: "flex",
      flexDirection: "column",
      "& > div": {
        "&:first-child": {
          flex: "1 1 auto",
          overflowY: "auto",
        },
      },
      [theme.breakpoints.up("sm")]: {
        maxHeight: "calc(100vh - 35px)",
      },
      [theme.breakpoints.up("lg")]: {
        height: "615px",
      },
    },
    bar: {
      padding: theme.spacing(),
      cursor: "pointer",
      fontWeight: 500,
      height: "35px",
      transition: "padding 0.3s, height 0.3s",
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& svg": {
        transition: "transform 0.3s",
      },
    },
    barOpen: {
      "& svg": {
        transform: "rotate(180deg)",
      },
    },
    topBar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      overflow: "hidden",
    },
    topBarHidden: {
      height: 0,
      padding: 0,
    },
    extra: {
      padding: theme.spacing(),
    },
    back: {
      display: "flex",
      alignItems: "center",
    },
  }),
  {
    name: "BottomPanel",
  }
);
