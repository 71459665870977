import makeStyles from "@material-ui/core/styles/makeStyles";

export const useMenuItemStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    minHeight: "unset",
  },
}));

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1rem",
    [theme.breakpoints.up("sm")]: {
      marginTop: "2rem",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 16px",
    cursor: "pointer",
    "& svg": {
      transition: "transform 0.3s",
    },
  },
  headerOpen: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  menuItem: {
    width: "100%",
    padding: "6px 16px",
  },
  logout: {
    color: theme.palette.error.main,
  },
}));
