import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useCallback, useState } from "react";
import {
  refreshView,
  useDataProvider,
  useNotify,
  useTranslate,
} from "react-admin";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Dialog from "../../../custom/Dialog";

const TrashButton = ({ record, resource, deleteResource }) => {
  if (!deleteResource) deleteResource = resource;

  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const notify = useNotify();
  const translate = useTranslate();

  const [isOpen, setOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback((e) => {
    setOpen(true);
    e.stopPropagation();
  }, []);

  const deleteItem = useCallback(
    (id) => {
      dataProvider
        .delete(deleteResource, {
          id,
        })
        .then(() => {
          dispatch(refreshView());
          notify(`resource.${resource}.notify.deleted`, "info");
        });
    },
    [dataProvider, dispatch, notify, deleteResource, resource]
  );

  const onConfirm = useCallback(() => {
    deleteItem(record.id);
    setOpen(false);
  }, [record.id, deleteItem]);

  return (
    <>
      <Button onClick={handleClick}>
        <DeleteIcon />
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleDialogClose}
        onConfirm={onConfirm}
        dialogContent={
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: translate(
                  `resource.${resource}.dialog_delete.counter_html`,
                  {
                    smart_count: 1,
                  }
                ),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: translate(
                  `resource.${resource}.dialog_delete.advice_html`
                ),
              }}
            ></p>
          </div>
        }
        cancelButton={<Button>{translate("commons.cancel")}</Button>}
        confirmButton={
          <Button variant="contained" color="primary">
            {translate("commons.delete")}
          </Button>
        }
      />
    </>
  );
};

TrashButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  deleteResource: PropTypes.string,
};

export default TrashButton;
