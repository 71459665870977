import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import React from "react";
import {
  useDataProvider,
  useLocale,
  useNotify,
  useSetLocale,
} from "react-admin";

import { getUserConfig, setUserConfig } from "../../../utils/userConfig";
import { useButtonStyles, useStyles } from "./styles";

const LocaleSwitch = ({ dark, localOnly }) => {
  const locale = useLocale();
  const setLocale = useSetLocale();

  const classes = useStyles({ dark });
  const buttonClasses = useButtonStyles({ dark });
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleChange = (language) => {
    setUserConfig(language, "language");
    setLocale(language);
    if (!localOnly) {
      dataProvider
        .update("user-profiles", {
          id: getUserConfig("id"),
          data: {
            language,
          },
        })
        .catch((err) => {
          console.log({ err });
          notify(err.message, "warning");
        });
    }
  };

  return (
    <div className={classes.root}>
      <Button
        classes={buttonClasses}
        size="small"
        disabled={locale === "es"}
        onClick={() => handleChange("es")}
      >
        ES
      </Button>
      <div className={classes.separator}></div>
      <Button
        classes={buttonClasses}
        size="small"
        disabled={locale === "en"}
        onClick={() => handleChange("en")}
      >
        EN
      </Button>
    </div>
  );
};

LocaleSwitch.propTypes = {
  dark: PropTypes.bool,
};

export default LocaleSwitch;
