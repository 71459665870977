import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { Notification, useLocale, useTranslate } from "react-admin";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import LocaleSwitch from "../../components/custom/LocaleSwitch";
import Modal from "../../components/custom/Modal";
import withTheme from "../../components/ra-components/pageDecorators/withTheme";
import registerLegalTerms from "../../config/translations/registerLegalTerms";
import { useStyles } from "./styles";
import RegisterForm from "./RegisterForm";

const Register = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      language={locale}
      useRecaptchaNet
      key={`register-${locale}`}
    >
      <div className={classes.mainContainer}>
        <div className={classes.localeContainer}>
          <LocaleSwitch localOnly />
        </div>
        <img
          className={classes.logo}
          src="/img/logos/laliga-color-h-2023.svg"
          alt="logo"
        />
        <div>
          <Card className={classes.card}>
            <CardHeader
              title={translate("register.title", {
                season_years: `${new Date().getFullYear()}/${(
                  new Date().getFullYear() + 1
                )
                  .toString()
                  .slice(-2)}`,
              })}
            />
            <CardContent>
              <RegisterForm setModalOpen={setModalOpen} />
            </CardContent>
          </Card>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        scroll="body"
        maxWidth="lg"
      >
        <div className={classes.termsContent}>{registerLegalTerms[locale]}</div>
        <div className={classes.termsModalClose}>
          <IconButton onClick={() => setModalOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
      </Modal>
      <Notification />
    </GoogleReCaptchaProvider>
  );
};

export default withTheme(Register, "light");
