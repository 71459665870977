import React from "react";

export default {
  en: (
    <>
      <div className="terms-modal-title">
        By continuing, you declare that you have read and accept the{" "}
        <a href='javascript:document.querySelector("#privacy-policy").scrollIntoView({behavior: "smooth"})'>
          Privacy Policy
        </a>
      </div>
      <p>
        <b>BASIC INFORMATION</b>
      </p>
      <p>
        <b>Data Controller:</b>
        <span>
          will be the legal entity from LALIGA Organization <sup>1</sup> with
          which you have established your contractual/employment/professional
          relationship.
        </span>
      </p>
      <p>
        <b>Data Processing Purpose: </b>
        <span>
          the managing of your registration and membership in the multimedia
          tool. The legal basis for the aforementioned purpose is that the
          processing is necessary for the performance of a professional and/or
          employment contract in which the interested party is a party by virtue
          of the provisions of art. 6.1 b) of the General Data Protection
          Regulation. Furthermore, your personal data will be processing to
          register the connexions to the tool in order to track its use. The
          legal basis for the aforementioned purpose is that the processing is
          necessary to satisfy the legitimate interest of LALIGA by virtue of
          the provisions of art. 6.1 f) of the General Data Protection
          Regulation, consisting in ensuring a proper use of the tool.
        </span>
      </p>
      <p>
        <b>To which recipients will your personal data be communicated? </b>
        <span>
          Your personal data will not be communicated to any third party.
        </span>
      </p>
      <p>
        <b>What are my rights? </b>
        <span>
          You can exercise your rights of access, rectification, erasure,
          objection, limitation of processing and portability by sending an
          email to <a href="mailto:lopd@laliga.es">lopd@laliga.es</a>.
        </span>
      </p>
      <p>
        At any time, you can consult our{" "}
        <a href='javascript:document.querySelector("#privacy-policy").scrollIntoView({behavior: "smooth"})'>
          Privacy Policy
        </a>{" "}
        to obtain more information about the processing of your data.
      </p>
      <p>
        <small>
          <sup>1</sup>Entities which are part of LALIGA: Liga Nacional De Fútbol
          Profesional, with TAX I.D.: G-78069762, Sociedad Española del Fútbol
          Profesional, S.A.U., with TAX I.D.: A-80945298, Fundación LALIGA, with
          TAX I.D.: G-80525439, LALIGA Servicios Digitales S.L.U. with TAX I.D.:
          B-88113477, LALIGA Group International, S.L. with TAX I.D.:
          B-84521269, LALIGA Entertainment, S.L. with TAX I.D.: B-42958694,
          LALIGA Content Protection, S.L., with TAX I.D.: B-01789593, La Liga
          (USA) Inc., La Liga South Africa Propietary Limited, Beijing Spanish
          Football League Consulting Co., Ltd, La Liga Nacional de Fútbol
          Profesional FZE, LALIGA LFP MEX y LALIGA Singapore Pte. Ltd.
        </small>
      </p>
      <div className="terms-modal-title" id="privacy-policy">
        PRIVACY POLICY – MULTIMEDIA
      </div>
      <p>
        In accordance with the provisions of current personal data protection
        regulation, (hereafter, the “<b>Policy</b>”) LALIGA informs you about
        the personal data processing that the legal entity with which you
        maintain a contractual/employment/professional relationship carry out
        through the Multimedia tool (hereafter, "<b>Multimedia</b>”).{" "}
      </p>
      <p>
        <b>Who are the data controllers?</b>
      </p>
      <p>
        For the purposes of this Policy, the entities listed below with which
        you mantain a contractual/ employment/ professional relationship shall
        be understood as forming part of LALIGA:
      </p>
      <ol type="I">
        <li>LALIGA Group International, S.L., with tax I.D.: B-05421805.</li>
        <li>Liga Nacional De Fútbol Profesional, with tax I.D.: G-78069762.</li>
        <li>
          Sociedad Española del Fútbol Profesional, S.A.U., with tax I.D.:
          A-80945298.
        </li>
        <li>Fundación LALIGA, with tax I.D.: G-80525439.</li>
        <li>LALIGA Entertainment, S.L. with tax I.D.: B-42958694.</li>
        <li>LALIGA Content Protection, S.L., with tax I.D.: B-01789593.</li>
        <li>LALIGA Servicios Digitales, S.L. with tax I.D.: B-88113477.</li>
        <p>
          The above-mentioned five entities have their registered address at 60
          Calle Torrelaguna, 28043 Madrid.
        </p>
        <li>
          La Liga (USA) Inc., a company legally incorporated under the laws of
          the United States. Registered address at 3 Columbus Circle, 16th
          floor, Suite 1621, New York, NY 10019.
        </li>
        <li>
          La Liga South Africa Propietary Limited, a company legally
          incorporated under the laws of South Africa. Registered address at
          1001 Sunset Towers, 2 Short Road Sahnnon Lane, Morningside 2196.
        </li>
        <li>
          Beijing Spanish Football League Consulting Co., Ltd, a company legally
          incorporated under the laws of China. Registered address at Building,
          No.8 Wangjing Street, Chaoyang District, Beijing.
        </li>
        <li>
          La Liga Nacional de Fútbol Profesional FZE, sa company legally
          incorporated under the laws of Dubai. Registered address at Dubai
          Airport Free Zone, Dubai.
        </li>
        <li>
          LALIGA LFP MEX, a company legally incorporated under the laws of
          Mexico. Registered address at Montes Urales 424, Lomas-Virreyes, Lomas
          de Chapultepec V Sección, 11000, Mexico City, México.
        </li>
        <li>
          LALIGA Singapore Pte. Ltd. A company legally incorporated under the
          laws of Singapore. Registered address at 1 Kim Seng Promenade #17-01
          Great World City, Singapore 237994.
        </li>
      </ol>
      <p>
        Data Protection Officer contact:{" "}
        <a href="mailto:dpo@laliga.es">dpo@laliga.es</a>
      </p>
      <b>Where does your data come from?</b>
      <p>
        LALIGA may process the personal data you provide us as a result of your
        commercial, professional or employment relationship to complete the
        registration form (email address).{" "}
      </p>
      <b>Why do we process your personal data?</b>
      <ul>
        <li>
          <b>Registration and membership:</b> we inform you that the personal
          data you provide us through the registration form may be processed for
          the purposes of managing of your registration and membership in the
          multimedia tool.
        </li>
        <p>
          The legal basis for the aforementioned purpose is that the processing
          is necessary for the execution of a professional and/or employment
          contract in which the interested party is a party by virtue of the
          provisions of art. 6.1 b) of the General Data Protection Regulation.{" "}
        </p>
        <li>
          <b>Connection Log Registry:</b> we inform you that the personal data
          obtained from the user´s activity may be processed for the purposed of
          registering the conexions to the tool in order to track its use.
        </li>
      </ul>
      <p>
        The legal basis for the aforementioned purpose is that the processing is
        necessary to satisfy the legitimate interest of LALIGA by virtue of the
        provisions of art. 6.1 f) of the General Data Protection Regulation,
        consisting in ensuring the correct use of the tool. Before that, we
        would like to inform you that LALIGA has analysed the proportionality
        between its legitimate interest and its users’ rights and freedoms. You
        can request the conclusions of this analysis through{" "}
        <a href="mailto:lopd@laliga.es">lopd@laliga.es</a>.
      </p>
      <b>How long will we keep your personal data for?</b>
      <p>
        The personal data which we process will be stored for such time that
        your professional/employment relationship with LALIGA continue, and
        whenever the purposes which motivated their collection persist and/or
        you do not exercise your rights of erasure, objection or restriction of
        processing.
      </p>
      <p>
        Likewise, LALIGA will keep your personal data, duly blocked, for the
        following period of time for the sole purpose of addressing any kinds of
        responsibilities that could arise:
      </p>
      <ul>
        <li>
          <b>Registration and membership: </b> the personal data provided for
          the registration and membership will be kept as long as you do not
          request the deletion of your data, this purpose persists and, once it
          has ended, during the limitation period of legal actions that may
          arise from it.
        </li>
        <li>
          <b>Connection Log Registry: </b> personal data obtained from the
          user´s activity will be kept as long as you do not request the
          deletion of your data, this purpose persists, you do not object their
          processing and, once this purpose it has ended, during the limitation
          period of the legal actions provisions that may arise from it.
        </li>
      </ul>
      <p>
        Said personal data will be deleted when such period of time expires.
      </p>
      <b>To which recipients will your personal data be communicated? </b>
      <p>
        Your personal data will not be communicated to any third party.
        Likewise, LALIGA may provide personal data and any other user
        information when required to do so by public authorities exercising
        responsibilities that have been lawfully granted to them and in
        accordance with applicable regulations.
      </p>
      <p>
        Similarly, some entities subcontracted by LALIGA could access the
        personal data and information as data processors or sub-processors in
        order to provide LALIGA with a required service. These service providers
        sign a contract that requires them to use your personal information
        solely for the provision of the services communicated in this policy.
      </p>
      <b>What are your rights when you provide us your personal data?</b>
      <p>
        We hereby inform you that you can exercise your rights of access,
        rectification, objection, erasure, right to not be subject to an
        automated decision, portability and limitation to processing with LALIGA
        at any time.
      </p>
      <u>What do your rights as a user consist of?</u>
      <ul>
        <li>
          Right of access: you have the right to obtain confirmation as to
          whether LALIGA process your personal data, as well as to access such
          data.
        </li>
        <li>
          Right of rectification: you can request either entity to modify or
          rectify personal data that is inaccurate.
        </li>
        <li>
          Right of erasure: you can request either entity to delete your
          personal data.
        </li>
        <li>
          Right of limitation to processing: In certain circumstances, you can
          request the processing of your data to be limited, in which case, it
          will only be kept for the exercise or defence of claims.
        </li>
        <li>
          Right of objection: you can object to the processing of your data for
          the purpose you specify. LALIGA will cease to process the data, except
          for compelling legitimate grounds, or for the exercise or defence of
          possible claims.
        </li>
        <li>
          Right not to be subject to a decision based solely on automated
          processing, including profiling, which may cause you legal effects or
          similarly significantly affects you.
        </li>
        <li>
          Right of portability: if the platform allows for it, you can exercise
          the right of portability of your data, in a structured, commonly used
          and machine-readable manner, and to transfer the data to another data
          controller.
        </li>
      </ul>
      <u>How do I exercise my rights?</u>
      <p>
        By sending an email to:{" "}
        <a href="mailto:lopd@laliga.es">lopd@laliga.es</a>. Exercising your
        rights is completely free of charge. Likewise, you can object to the
        data processing based on legitimate interest at any time by sending an
        email to: <a href="mailto:lopd@laliga.es">lopd@laliga.es</a>. The
        objection to a particular purpose will not affect your use of the tool
        or the legality of the processing prior to the objection.
      </p>
      Furthermore, if you believe that you have not been able to exercise your
      rights to your satisfaction, or that your data has not been processed as
      established in the applicable regulations, you may contact the data
      protection delegate at LALIGA at  
      <a href="mailto:lopd@laliga.es">lopd@laliga.es</a> or file a claim before
      the Spanish Data Protection Agency at its website,
      <a href="http://www.aepd.es" target="_blank" rel="noopener noreferrer">
        www.aepd.es
      </a>
    </>
  ),
  es: (
    <>
      <div className="terms-modal-title">
        Al continuar declaras haber leído la{" "}
        <a href='javascript:document.querySelector("#privacy-policy").scrollIntoView({behavior: "smooth"})'>
          Política de Privacidad
        </a>
      </div>
      <p>
        <u>Información básica sobre protección de datos</u>
      </p>
      <p>
        <b>El responsable de tus datos será la entidad de la</b>
        <span>
          Organización LALIGA<sup>1</sup> con la que tengas establecida tu
          relación contractual/laboral/profesional.
        </span>
      </p>
      <p>
        <b>¿Para qué utilizaremos tus datos? </b>
        <span>
          Con la creación de tu cuenta, LALIGA tratará tus datos personales con
          la finalidad de gestionar tu registro y alta en la herramienta
          <span style={{ color: "red" }}> Multimedia</span>. La base legal para
          esta finalidad es la ejecución del contrato laboral y/o profesional
          formalizado con LALIGA. Por otro lado, LALIGA tratará tus datos para
          efectuar un registro de conexiones a la herramienta con el fin de
          realizar un seguimiento de la misma. La base legal para esta finalidad
          es el interés legítimo de LALIGA.
        </span>
      </p>
      <p>
        <b>¿A qué destinatarios se comunicarán tus datos personales? </b>
        <span>Tus datos personales no serán comunicados a terceros.</span>
      </p>
      <p>
        <b>¿Cuáles son mis derechos? </b>
        <span>
          Puedes ejercer tus derechos de acceso, rectificación, supresión,
          oposición, limitación del tratamiento y portabilidad enviando un
          correo electrónico a{" "}
          <a href="mailto:lopd@laliga.es">lopd@laliga.es</a>.
        </span>
      </p>
      <p>
        En cualquier momento, puedes consultar nuestra{" "}
        <a href='javascript:document.querySelector("#privacy-policy").scrollIntoView({behavior: "smooth"})'>
          Política de Privacidad
        </a>{" "}
        para obtener más información sobre tus derechos y el tratamiento de tus
        datos.
      </p>
      <p>
        <small>
          <sup>1</sup>Entidades que forman parte de la Organización LALIGA: Liga
          Nacional De Fútbol Profesional, con C.I.F. G-78069762, Sociedad
          Española del Fútbol Profesional, S.A.U., con C.I.F. A-80945298,
          Fundación LALIGA, con CIF G-80525439, LALIGA Servicios Digitales
          S.L.U. con C.I.F.: B-88113477, LALIGA Group International, S.L. con
          CIF: B-84521269, LALIGA Entertainment, S.L. con CIF: B-42958694,
          LALIGA Content Protection, S.L., con CIF: B-01789593, La Liga (USA)
          Inc., La Liga South Africa Propietary Limited, Beijing Spanish
          Football League Consulting Co., Ltd, La Liga Nacional de Fútbol
          Profesional FZE, LALIGA LFP MEX y LALIGA Singapore Pte. Ltd.
        </small>
      </p>
      <div className="terms-modal-title" id="privacy-policy">
        POLÍTICA DE PRIVACIDAD – MULTIMEDIA
      </div>
      <p>
        La Presente Política de Privacidad (en adelante, la “<b>Política</b>”)
        tiene por objeto regular los actos de tratamiento de datos personales
        que realiza la entidad de la Organización LALIGA con la que mantienes
        una relación laboral/profesional/contractual (en adelante, “LALIGA”) a
        través de la herramienta Multimedia de LALIGA (en adelante, “
        <b>Multimedia</b>”).
      </p>
      <b>¿Quién es el responsable del tratamiento de tus datos personales?</b>
      <p>
        A efectos de la presente Política de Privacidad, se entenderán
        comprendidas dentro de la Organización LALIGA las entidades abajo
        relacionadas, a saber:
      </p>
      <ol type="I">
        <li>LALIGA Group International, S.L., con C.I.F. B-05421805.</li>
        <li>Liga Nacional De Fútbol Profesional, con C.I.F. G-78069762.</li>
        <li>
          Sociedad Española del Fútbol Profesional, S.A.U., con C.I.F.
          A-80945298.
        </li>
        <li>Fundación LALIGA, con CIF G-80525439.</li>
        <li>LALIGA Entertainment, S.L. con CIF: B-42958694.</li>
        <li>LALIGA Content Protection, S.L., con CIF: B-01789593.</li>
        <li>LALIGA Servicios Digitales, S.L. con CIF: B-88113477.</li>
        <p>
          Las entidades arriba indicadas tienen su domicilio social en la Calle
          Torrelaguna 60, 28043 Madrid.
        </p>
        <li>
          La Liga (USA) Inc., sociedad legalmente constituida bajo las leyes de
          Estados Unidos. Domicilio en 3 Columbus Circle, 16th floor, Suite
          1621, New York, NY 10019.
        </li>
        <li>
          La Liga South Africa Propietary Limited, sociedad legalmente
          constituida bajo las leyes de Sudáfrica. Domicilio en 1001 Sunset
          Towers, 2 Short Road Sahnnon Lane, Morningside 2196.
        </li>
        <li>
          Beijing Spanish Football League Consulting Co., Ltd, Sociedad
          legalmente constituida bajo las leyes de China. Domicilio en Building,
          No.8 Wangjing Street, Chaoyang District, Beijing.
        </li>
        <li>
          La Liga Nacional de Fútbol Profesional FZE, sociedad legalmente
          constituida bajo las leyes de Dubai. Domicilio en Dubai Airport Free
          Zone, Dubai.
        </li>
        <li>
          LALIGA LFP MEX, sociedad legalmente constituida bajo las leyes de
          Méjico. Domicilio en Montes Urales 424, Lomas-Virreyes, Lomas de
          Chapultepec V Sección, 11000, Mexico City, México.
        </li>
        <li>
          LALIGA Singapore Pte. Ltd. Sociedad legalmente constituida bajo las
          leyes de Singapur. Domicilio en 1 Kim Seng Promenade #17-01 Great
          World City, Singapore 237994.
        </li>
      </ol>
      <p>
        Contacto con el Delegado de Protección de Datos de LALIGA:{" "}
        <a href="mailto:dpo@laliga.es">dpo@laliga.es</a>
      </p>
      <b>¿De dónde obtenemos tus datos personales?</b>
      <p>
        LALIGA tratará los datos que nos hayas proporcionado con motivo de tu
        relación comercial, profesional o laboral para tu registro (correo
        electrónico).{" "}
      </p>
      <b>¿Con qué finalidad tratamos tus datos personales?</b>
      <ul>
        <li>
          <b>Registro y alta en la herramienta Multimedia:</b> Te informamos de
          que los datos personales que facilites para tu registro serán tratados
          por LALIGA a los efectos de gestionar tu registro y alta en la
          herramienta.
        </li>
        <p>
          La base legal para la finalidad mencionada anteriormente es que el
          tratamiento es necesario para la ejecución de un contrato en el que el
          interesado es parte en virtud de lo establecido en el art. 6.1 b) del
          Reglamento General de Protección de Datos, en particular, la ejecución
          del contrato laboral y/o profesional formalizado entre el empleado y/o
          el tercero con alguna de las entidades de la Organización LALIGA.{" "}
        </p>
        <li>
          <b>Registro de conexión a la herramienta Multimedia:</b> Te informamos
          de que los datos relativos a tu usuario serán utilizados por LALIGA
          con la finalidad de realizar un seguimiento del uso de la herramienta
          por la organización
        </li>
      </ul>
      <p>
        La base legal para la finalidad mencionada anteriormente es que el
        tratamiento es necesario para la satisfacción del interés legítimo de
        LALIGA en virtud de lo establecido en el art. 6.1 f) del Reglamento
        General de Protección de Datos, consistente en el control y seguimiento
        de la herramienta por parte de LALIGA para asegurar el buen
        funcionamiento de la misma. Antes de ello, os informamos de que LALIGA
        ha llevado a cabo un análisis de proporcionalidad entre su interés
        legítimo y los derechos y libertades de sus usuarios. Puedes solicitar
        las conclusiones de este análisis a través de{" "}
        <a href="mailto:lopd@laliga.es">lopd@laliga.es</a>.
      </p>
      <b>¿Por cuánto tiempo conservaremos tus datos personales? </b>
      <p>
        Los datos personales que tratamos se conservarán mientras se mantenga tu
        relación contractual laboral/profesional con LALIGA, siempre que
        persistan las finalidades que motivaron su recogida y/o no ejerzas tus
        derechos de supresión, oposición o limitación del tratamiento.
      </p>
      <p>
        En este sentido, LALIGA mantendrá tus datos personales debidamente
        bloqueados por los siguientes periodos de conservación con el único fin
        de atender las responsabilidades de cualquier índole que pudieran
        surgir:
      </p>
      <ul>
        <li>
          <b>Registro y alta en la herramienta Multimedia: </b> los datos
          facilitados para el registro y alta en la herramienta se conservarán
          mientras que no solicites la supresión de tus datos, persista esta
          finalidad y, una vez finalizada la misma, durante el plazo de
          prescripción de las acciones legales que se puedan derivar de la
          misma.
        </li>
        <li>
          <b>Registro de conexión a la herramienta Multimedia: </b>los datos
          facilitados para el registro de conexión se conservarán mientras que
          no solicites la supresión de tus datos, persista esta finalidad, no te
          opongas al tratamiento y, una vez finalizada la misma, durante el
          plazo de prescripción de las acciones legales que se puedan derivar de
          la misma.
        </li>
      </ul>
      <p>
        Dichos datos personales serán suprimidos cuando prescriban los plazos
        indicados.
      </p>
      <b>¿A qué destinatarios se comunicarán tus datos personales?</b>
      <p>
        Tus datos personales no serán comunicados a terceros. No obstante,
        LALIGA podrá revelar los datos personales y cualquier otra información
        del usuario cuando le sea requerida por autoridades públicas en el
        ejercicio de las funciones que tienen legítimamente atribuidas y
        conforme a las disposiciones que resulten aplicables.
      </p>
      <p>
        Del mismo modo, algunas entidades subcontratadas por LALIGA podrían
        acceder a los datos personales e información como encargados o sub
        encargados del tratamiento para prestar a LALIGA un servicio necesario,
        tal y como se ha indicado en cada una de las finalidades de la sección
        <b>
          <em>“¿Con qué finalidad tratamos tus datos personales?”</em>
        </b>
        . Estos proveedores de servicios firman un contrato que les obliga a
        utilizar tu información personal únicamente para la prestación de los
        servicios informados en esta política.
      </p>
      <b>¿Cuáles son tus derechos cuando nos facilitas tus datos personales?</b>
      <p>
        Te informamos que, en cualquier momento, puedes ejercer tus derechos de
        acceso, rectificación, oposición, supresión y limitación del tratamiento
        ante LALIGA:
      </p>
      <u>¿En qué consisten tus derechos como usuario?</u>
      <ul>
        <li>
          Derecho de acceso: tienes derecho a obtener confirmación sobre si
          LALIGA tratan tus datos personales, así como a acceder a los mismos.
        </li>
        <li>
          Derecho de rectificación: podrás solicitar la modificación o
          rectificación de datos personales inexactos.
        </li>
        <li>
          Derecho de supresión: podrás solicitar la supresión de tus datos
          personales.
        </li>
        <li>
          Derecho de limitación del tratamiento: En determinadas circunstancias,
          podrás solicitar la limitación del tratamiento de sus datos, en cuyo
          caso únicamente se conservarán para el ejercicio o la defensa de
          reclamaciones.
        </li>
        <li>
          Derecho de oposición: podrás oponerte al tratamiento de tus datos para
          la finalidad que especifiques. LALIGA cesará en el tratamiento de los
          datos, salvo por motivos legítimos imperiosos, o el ejercicio o la
          defensa de posibles reclamaciones.
        </li>
      </ul>
      <u>¿Cómo ejerzo mis derechos?</u>
      <p>
        Enviando un correo electrónico a 
        <a href="mailto:lopd@laliga.es">lopd@laliga.es</a>. El ejercicio de tus
        derechos será totalmente gratuito. Asimismo, podrás oponerte a una
        determinada finalidad; enviando un correo electrónico a la dirección:{" "}
        <a href="mailto:lopd@laliga.es">lopd@laliga.es</a>. La oposición a una
        determinada finalidad no afectará a la utilización que puedas hacer de
        la aplicación ni a la licitud del tratamiento de manera previa a la
        oposición.
      </p>
      Asimismo, si consideras que no has obtenido satisfacción en el ejercicio
      de sus derechos o que el tratamiento que ha realizado LALIGA de tus datos
      personales no ha sido acorde con la normativa de protección de datos,
      puedes contactar con el Delegado de Protección de Datos de LALIGA en la
      dirección de correo electrónico {" "}
      <a href="mailto:lopd@laliga.es">lopd@laliga.es</a> o presentar una
      reclamación ante la Agencia Española de Protección de Datos a través de su
      página web 
      <a href="http://www.aepd.es" target="_blank" rel="noopener noreferrer">
        www.aepd.es
      </a>
    </>
  ),
};
