import React from "react";
import PropsTypes from "prop-types";
import _get from "lodash/get";
import { Chip, makeStyles } from "@material-ui/core";
import Sield from "../../../components/custom/Shield";

export const useStyles = makeStyles((theme) => ({
  avatar: {
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Tag = ({ record, source, onDelete }) => {
  const classes = useStyles();

  const Avatar = () => {
    const type = record["@type"];

    switch (type) {
      case "Team":
        return (
          <div className={classes.avatar}>
            <Sield idTeam={record.id} size="xs" />
          </div>
        );
      default:
        return false;
    }
  };

  return (
    <Chip
      avatar={<Avatar />}
      label={_get(record, source)}
      onDelete={onDelete}
    />
  );
};

Tag.propTypes = {
  record: PropsTypes.object,
  source: PropsTypes.string,
  onDelete: PropsTypes.func,
};

export default Tag;
