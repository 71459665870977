import { makeStyles } from "@material-ui/core/styles";

export const useFooterStyles = makeStyles({
  root: {
    background: (theme) => theme.palette.background.footer,
    color: (theme) => theme.palette.common.white,
    textAlign: "center",
    paddingTop: (theme) => theme.spacing(2.5),
    paddingBottom: (theme) => theme.spacing(1.5),
  },
  footerTop: {
    fontSize: "14px",
    lineHeight: "14px",
    fontWeight: "bold",
  },
  footerBottom: {
    fontSize: "12px",
    lineHeight: "12px",
    marginTop: (theme) => theme.spacing(1.5),
    "& a": {
      color: "inherit",
      textDecoration: "none",
      transition: "opacity 0.3s",
      "&:hover": {
        opacity: 0.7,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  },
});
