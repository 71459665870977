import {
  AppBar as MuiAppBar,
  IconButton,
  LinearProgress,
  makeStyles,
  Toolbar,
  Tooltip,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { setSidebarVisibility, toggleSidebar, useTranslate } from "react-admin";
import { useDispatch, useSelector } from "react-redux";

import LocaleSwitch from "../../../custom/LocaleSwitch";
import DownloadNotificationButton from "../../../custom/DownloadImages/DownloadNotificationButton";
import CustomUserMenu from "./UserMenu";
import { getUserConfig } from "../../../../utils/userConfig";

const useStyles = makeStyles(
  (theme) => {
    return {
      appBar: {
        backgroundColor: theme.palette.grey[900],
        overflow: "hidden",
        paddingRight: "0px!important",
      },
      toolbar: {
        justifyContent: "space-between",
        padding: theme.spacing(0, 2),
        minHeight:
          theme.values.toolbar.height.xs - theme.values.toolbar.lineHeight.xs,
        [theme.breakpoints.up("sm")]: {
          minHeight:
            theme.values.toolbar.height.sm - theme.values.toolbar.lineHeight.sm,
        },
        [theme.breakpoints.up("lg")]: {
          padding: theme.spacing(0, 5),
          minHeight:
            theme.values.toolbar.height.lg - theme.values.toolbar.lineHeight.lg,
        },
      },
      centerElement: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      leftElement: {},
      rightElement: {
        display: "flex",
        alignItems: "center",
      },
      menuButton: {
        marginRight: "0.5em",
        color: theme.palette.common.white,
      },
      logo: {
        height: 30,
        verticalAlign: "middle",
        [theme.breakpoints.up("lg")]: {
          height: 34,
        },
      },
      colorLine: {
        height: theme.values.toolbar.lineHeight.xs,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up("lg")]: {
          height: theme.values.toolbar.lineHeight.lg,
        },
      },
      email: {
        color: theme.palette.common.white,
      },
    };
  },
  { name: "CustomRaAppBar" }
);

const useLinearProgressStyles = makeStyles((theme) => ({
  root: {
    height: theme.values.toolbar.lineHeight.xs,
    [theme.breakpoints.up("lg")]: {
      height: theme.values.toolbar.lineHeight.lg,
    },
  },
}));

const AppBar = (props) => {
  const { logout, open } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const translate = useTranslate();
  const userEmail = getUserConfig("email");
  const linearProgressClasses = useLinearProgressStyles();
  const loading = useSelector((state) => state.admin.loading);

  useEffect(() => {
    dispatch(setSidebarVisibility(isDesktop));
  }, [isDesktop, dispatch]);

  return (
    <MuiAppBar className={classes.appBar}>
      <Toolbar disableGutters variant="regular" className={classes.toolbar}>
        <div className={classes.centerElement}>
          {(isMobile || isTablet) && (
            <img
              src="/img/logos/laliga-monocromatic-negative-h-2023.svg"
              alt="LaLiga Logo"
              title="LaLiga Logo"
              className={classes.logo}
            />
          )}
        </div>
        <div className={classes.leftElement}>
          {isDesktop && (
            <img
              src="/img/logos/laliga-monocromatic-negative-h-2023.svg"
              alt="LaLiga Logo"
              title="LaLiga Logo"
              className={classes.logo}
            />
          )}
          {(isMobile || isTablet) && (
            <Tooltip
              title={translate(
                open ? "ra.action.close_menu" : "ra.action.open_menu",
                { _: "Open/Close menu" }
              )}
              enterDelay={500}
            >
              <IconButton
                color="inherit"
                onClick={() => dispatch(toggleSidebar())}
                className={classNames(classes.menuButton)}
                size="small"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.rightElement}>
          {isDesktop && (
            <>
              <Typography className={classes.email}>{userEmail}</Typography>
              <CustomUserMenu logout={logout} />
            </>
          )}
          <DownloadNotificationButton />
          {(isTablet || isDesktop) && <LocaleSwitch dark />}
        </div>
      </Toolbar>
      {loading ? (
        <LinearProgress classes={linearProgressClasses} />
      ) : (
        <div className={classes.colorLine}></div>
      )}
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  logout: PropTypes.element,
  open: PropTypes.bool,
};

export default AppBar;
