import React from "react";
import { SaveButton, Toolbar, useTranslate } from "react-admin";

import BackButton from "./BackButton";

const CustomToolbar = ({ edit, create, ...rest }) => {
  const translate = useTranslate();

  return (
    <Toolbar {...rest}>
      <BackButton />
      <SaveButton
        label={translate(
          `resource.${rest.resource}.${
            create ? "create_button" : "edit_button"
          }`,
          { _: translate("commons.save") }
        )}
      />
    </Toolbar>
  );
};

export default CustomToolbar;
