import Button from "@material-ui/core/Button";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import {
  choices,
  FormDataConsumer,
  required,
  useNotify,
  useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { LOGIN_FORM } from "..";
import { TextInput } from "../../../components/inputs";
import PasswordInput from "../../../components/inputs/PasswordInput";
import { sendChangePassword } from "../../../providers/authProviderToken";
import { password_complexity } from "../../../utils/password";
import { useStyles } from "../styles";

const ChangePasswordForm = ({ setPageView, setLoading }) => {
  const [token, setToken] = useState();
  const classes = useStyles();

  const notify = useNotify();
  const translate = useTranslate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const refreshToken = useCallback(() => {
    if (executeRecaptcha) {
      executeRecaptcha("change_password_form").then((result) => {
        setToken(result);
      });
    }
  }, [executeRecaptcha]);

  const handleFormSubmit = useCallback(
    (formData) => {
      setLoading(true);

      if (formData.reCaptcha) {
        sendChangePassword(formData)
          .then(() => {
            setLoading(false);
            setPageView(LOGIN_FORM);
            notify("login.password_changed", "info");
          })
          .catch((err) => {
            notify(err.message, "warning");
            setLoading(false);
            refreshToken();
          });
      } else {
        notify("recaptcha error", "warning");
        setLoading(false);
        refreshToken();
      }
    },
    [setPageView, notify, setLoading, refreshToken]
  );

  return (
    <Form onSubmit={handleFormSubmit}>
      {({ handleSubmit, form, values }) => {
        if (!values.reCaptcha || values.reCaptcha !== token) {
          if (!token) {
            refreshToken();
          }
          form.change("reCaptcha", token);
        }

        return (
          <form onSubmit={handleSubmit}>
            <p>{translate("login.check_email")}</p>
            <TextInput
              source="profile.recoverToken"
              label={translate("login.recoverToken")}
              validate={required()}
              autoComplete="new-password"
            />
            <PasswordInput
              source="plainPassword"
              label={translate("login.password")}
              validate={[required(), password_complexity()]}
              autoComplete="new-password"
            />
            <FormDataConsumer>
              {({ formData }) => (
                <PasswordInput
                  source="repeat_plainPassword"
                  label={translate("login.repeat_password")}
                  validate={[
                    required(),
                    choices(
                      [formData.plainPassword],
                      translate("login.repeat_password_text")
                    ),
                  ]}
                  autoComplete="new-password"
                />
              )}
            </FormDataConsumer>
            <p style={{ fontSize: "0.8rem" }}>
              {translate("login.password_complexity")}
            </p>
            <div className={classNames(classes.buttons, classes.actionWrapper)}>
              <Button
                onClick={() => {
                  setPageView(LOGIN_FORM);
                }}
              >
                {translate("commons.cancel")}
              </Button>
              <Button variant="contained" type="submit" color="primary">
                {translate("login.change_password")}
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

export default ChangePasswordForm;
