import list from "./list";
import create from "./create";
import edit from "./edit";

export default {
  list,
  create,
  edit,
  options: { label: "resource.users.menu" },
};
