import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTranslate } from "react-admin";

import {
  AutoCompleteInput,
  DateRangeInput,
  TextInput,
} from "../../../../components/inputs";
import { getUserConfig } from "../../../../utils/userConfig";

const Filters = () => {
  const translate = useTranslate();
  const myRoles = getUserConfig("roles");

  return (
    <Grid container key="grid" spacing={2}>
      {myRoles.includes("ROLE_ADMIN") && (
        <Grid item xs={12} lg={4}>
          <AutoCompleteInput
            multiple
            label="commons.user"
            source="user"
            searchResource="users"
            searchProperty="email"
            optionText="email"
          />
        </Grid>
      )}
      <Grid item xs={12} lg={4}>
        <TextInput label="commons.name" source="name" />
      </Grid>
      <Grid item xs={12} lg={4}>
        <DateRangeInput
          source="createdAt"
          label={translate("commons.date_range")}
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
