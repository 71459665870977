import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useChoices, useInput, useTranslate } from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    formControl: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(0.5),
    },
    input: {
      minWidth: theme.spacing(20),
    },
  }),
  { name: "WBOSelectInput" }
);

const SelectInput = (props) => {
  const {
    label,
    choices,
    optionText,
    optionValue,
    allowEmpty,
    emptyValue,
    emptyText,
    translateChoice,
    disableValue,
    className,
    native,
    disabled,
    fullWidth,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput(props);

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  const renderEmptyItemOption = useCallback(() => {
    return React.isValidElement(emptyText)
      ? React.cloneElement(emptyText)
      : emptyText === ""
      ? " " // em space, forces the display of an empty line of normal height
      : translate(emptyText, { _: emptyText });
  }, [emptyText, translate]);

  const renderMenuItemOption = useCallback((choice) => getChoiceText(choice), [
    getChoiceText,
  ]);

  const handleChange = (e) => {
    let value = "";
    if (e.target.value) {
      if (!isNaN(parseInt(e.target.value))) {
        value = parseInt(e.target.value);
      } else {
        value = e.target.value;
      }
    }
    input.onChange({ target: { value } });
  };

  const inputError = touched && error;

  return (
    <FormControl
      error={!!inputError}
      disabled={disabled}
      required={isRequired}
      fullWidth={fullWidth}
      className={classes.formControl}
    >
      {label && (
        <InputLabel htmlFor={input.name}>
          {translate(label, { _: label })}
        </InputLabel>
      )}
      <Select
        {...input}
        value={
          typeof input.value == "object"
            ? getChoiceValue(input.value)
            : input.value
        }
        id={id}
        className={`${classes.input} ${className}`}
        native={native}
        onChange={handleChange}
        margin="dense"
        disableUnderline
        fullWidth
      >
        {allowEmpty ? (
          <option
            value={emptyValue}
            key={emptyValue || "null"}
            aria-label={translate("ra.action.clear_input_value")}
            title={translate("ra.action.clear_input_value")}
          >
            {renderEmptyItemOption()}
          </option>
        ) : null}
        {choices.map((choice) => (
          <option
            key={getChoiceValue(choice)}
            value={getChoiceValue(choice)}
            disabled={get(choice, disableValue)}
          >
            {renderMenuItemOption(choice)}
          </option>
        ))}
      </Select>
      {inputError && <FormHelperText>{translate(inputError)}</FormHelperText>}
    </FormControl>
  );
};

SelectInput.propTypes = {
  allowEmpty: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  translateChoice: PropTypes.bool,
  emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  emptyValue: PropTypes.any,
  choices: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  disableValue: PropTypes.string,
  native: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

SelectInput.defaultProps = {
  optionValue: "id",
  optionText: "name",
  choices: [],
  emptyText: "",
  emptyValue: "",
  translateChoice: false,
  disableValue: "disabled",
  native: true,
  fullWidth: true,
};

export default SelectInput;
