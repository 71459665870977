import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { MenuItemLink, useLogout, useTranslate } from "react-admin";

import userMenuItems from "../../../../../config/userMenu";
import Modal from "../../../../custom/Modal";
import { useMenuItemStyles, useStyles } from "./styles";

const CustomUserMenu = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const menuItemClasses = useMenuItemStyles();
  const logout = useLogout();

  const [anchorEl, setAnchorEl] = useState(null);
  const isUserMenuOpen = Boolean(!!anchorEl);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const handleModalOpen = useCallback((content) => {
    handleMenuClose();
    setModalContent(content);
    setModalOpen(true);
  }, []);
  const handleModalClose = useCallback(() => {
    handleMenuClose();
    setModalOpen(false);
  }, []);

  return (
    <>
      <Modal open={modalOpen} onClose={handleModalClose}>
        {modalContent &&
          React.cloneElement(modalContent, { onClose: handleModalClose })}
      </Modal>
      <Tooltip title={translate("commons.profile")}>
        <IconButton
          aria-label={translate("commons.profile")}
          aria-owns={isUserMenuOpen ? "menu-appbar" : null}
          aria-haspopup={true}
          onClick={handleMenuOpen}
          className={classes.buttonRoot}
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isUserMenuOpen}
        onClose={handleMenuClose}
        disableScrollLock
      >
        {userMenuItems.map(({ path, primaryText, onClick, ...rest }) =>
          path ? (
            <MenuItemLink
              key={primaryText}
              to={path}
              primaryText={translate(primaryText)}
              {...rest}
            />
          ) : (
            <MenuItem key={primaryText} classes={menuItemClasses}>
              <div
                className={classes.menuItem}
                onClick={() => onClick(handleModalOpen)}
              >
                {translate(primaryText)}
              </div>
            </MenuItem>
          )
        )}
        <MenuItem classes={menuItemClasses}>
          <div
            className={classNames(classes.menuItem, classes.logout)}
            onClick={() => logout()}
          >
            {translate("userMenu.logout")}
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CustomUserMenu;
