import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FieldTitle, InputHelperText, useChoices, useInput } from "react-admin";

import { IS } from "../../../utils";
import { useStyles } from "./styles";

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  alwaysOn,
  basePath,
  choices,
  classNamInputWithOptionsPropse,
  componenInputWithOptionsPropst,
  crudGetMInputWithOptionsPropsatching,
  crudGetOInputWithOptionsPropsne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  loaded,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}) => rest;

const SelectArrayInput = (props) => {
  const {
    choices = [],
    classes: classesOverride,
    className,
    format,
    helperText,
    label,
    margin = "dense",
    onBlur,
    onChange,
    onFocus,
    options,
    optionText,
    optionValue,
    parse,
    resource,
    source,
    translateChoice,
    validate,
    variant = "filled",
    object = false,
    ...rest
  } = props;
  const classes = useStyles(props);
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });
  const {
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const formatValue = useCallback(
    (values) => {
      if (object) {
        if (Array.isArray(values)) {
          let newValues = [];
          for (let i = 0; i < values.length; i++) {
            let value = values[i];

            if (IS.object(value)) {
              newValues.push(getChoiceValue(value));
            } else {
              newValues.push(value);
            }
          }
          return newValues;
        }

        return [];
      } else {
        return values || [];
      }
    },
    [getChoiceValue, object]
  );

  const handleOnChange = useCallback(
    (e) => {
      const value = e.target.value || [];
      if (object) {
        const newValue = [];

        for (let i = 0; i < value.length; i++) {
          newValue.push(
            choices.find((choice) => {
              return getChoiceValue(choice) === value[i];
            })
          );
        }

        input.onChange(newValue);
      } else {
        input.onChange(value);
      }
    },
    [input, choices, getChoiceValue, object]
  );

  const renderMenuItemOption = useCallback((choice) => getChoiceText(choice), [
    getChoiceText,
  ]);

  const renderMenuItem = useCallback(
    (choice) => {
      return choice ? (
        <MenuItem key={getChoiceValue(choice)} value={getChoiceValue(choice)}>
          {renderMenuItemOption(choice)}
        </MenuItem>
      ) : null;
    },
    [getChoiceValue, renderMenuItemOption]
  );
  return (
    <FormControl
      margin={margin}
      className={classnames(classes.root, className)}
      error={touched && !!error}
      variant={variant}
      {...sanitizeRestProps(rest)}
    >
      <InputLabel
        ref={inputLabel}
        id={`${label}-outlined-label`}
        error={touched && !!error}
      >
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </InputLabel>
      <Select
        autoWidth
        labelId={`${label}-outlined-label`}
        multiple
        error={!!(touched && error)}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected
              .map((item) =>
                choices.find((choice) => getChoiceValue(choice) === item)
              )
              .map((item) => (
                <Chip
                  key={getChoiceValue(item)}
                  label={renderMenuItemOption(item)}
                  className={classes.chip}
                />
              ))}
          </div>
        )}
        data-testid="selectArray"
        {...input}
        value={formatValue(input.value)}
        onChange={handleOnChange}
        {...options}
        labelWidth={labelWidth}
      >
        {choices.map(renderMenuItem)}
      </Select>
      <FormHelperText error={touched && !!error}>
        <InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />
      </FormHelperText>
    </FormControl>
  );
};

SelectArrayInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translateChoice: PropTypes.bool,
};

SelectArrayInput.defaultProps = {
  options: {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
};

export default SelectArrayInput;
