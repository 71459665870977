import { List } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import { sanitizeListRestProps, useListContext } from "react-admin";
import React from "react";

import ListItem from "./ListItem";
import { useStyles } from "./styles";

const MobileList = (props) => {
  const {
    className,
    classes: classesOverride,
    hasBulkActions,
    linkType = false,
    content,
    actions,
    ...rest
  } = props;
  const {
    basePath,
    data,
    ids,
    total,
    filterValues,
    selectedIds,
    onToggleItem,
  } = useListContext(props);
  const classes = useStyles();

  return (
    <>
      {total > 0 && (
        <List
          className={classNames(classes.root, { [className]: !!className })}
          {...sanitizeListRestProps(rest)}
        >
          {ids.map((id) => (
            <ListItem
              key={id}
              hasBulkActions={hasBulkActions}
              linkType={linkType}
              basePath={basePath}
              id={id}
              data={data}
              content={content}
              actions={actions}
              selectedIds={selectedIds}
              onToggleItem={onToggleItem}
              filterValues={filterValues}
            />
          ))}
        </List>
      )}
    </>
  );
};

MobileList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  hasBulkActions: PropTypes.bool,
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  content: PropTypes.func,
  actions: PropTypes.array,
  selectedIds: PropTypes.array,
  onToggleItem: PropTypes.func,
};

export default MobileList;
