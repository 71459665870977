import React from "react";
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useRedirect, useTranslate } from "react-admin";

import { useStyles } from "./styles";
import LocaleSwitch from "../../components/custom/LocaleSwitch";
import withTheme from "../../components/ra-components/pageDecorators/withTheme";

const LoginFailure = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.localeContainer}>
        <LocaleSwitch localOnly />
      </div>
      <img
        className={classes.logo}
        src="/img/logos/laliga-color-h-2023.svg"
        alt="logo"
      />
      <div>
        <Card className={classes.card}>
          <CardHeader title="Error" />
          <CardContent>
            <Typography>{translate("login.login_page_failure")}</Typography>
            <div className={classNames(classes.buttons, classes.loginButtons)}>
              <Button
                onClick={() => redirect("/login")}
                variant="contained"
                color="primary"
                fullWidth
              >
                {translate("commons.go_back")}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default withTheme(LoginFailure, "light");
