import makeStyles from "@material-ui/core/styles/makeStyles";

export const useMenuItemStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
}));

export const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: "100%",
    padding: "6px 16px",
    textAlign: "right",
  },
  logout: {
    color: theme.palette.error.main,
  },
  buttonRoot: {
    color: theme.palette.common.white,
  },
}));
