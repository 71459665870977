import { IconButton } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import React from "react";
import { Create as RaCreate, SimpleForm, useTranslate } from "react-admin";
import { Link } from "react-router-dom";

import DefaultToolbar from "../toolbars/DefaultToolbar";
import { useStyles } from "./styles";

const CreateTitle = ({ basePath, resource }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <h1 className={classes.title}>
      <IconButton component={Link} to={basePath}>
        <NavigateBeforeIcon />
      </IconButton>
      {translate(`resource.${resource}.create_title`, {
        _: translate("commons.create"),
      })}
    </h1>
  );
};

const Create = ({ children, ...props }) => (
  <RaCreate {...props}>
    <SimpleForm toolbar={<DefaultToolbar edit />}>
      <CreateTitle />
      {React.cloneElement(children, { isCreate: true })}
    </SimpleForm>
  </RaCreate>
);

export default Create;
