import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import React from "react";
import { useCallback } from "react";
import {
  FormDataConsumer,
  FormWithRedirect,
  useNotify,
  useTranslate,
  ReferenceInput,
} from "react-admin";
import { useDispatch, useSelector } from "react-redux";

import PhotoUploadPanel from "../../components/custom/PhotoUploadPanel";
import SectionTitle from "../../components/custom/SectionTitle";
import {
  EventInput,
  MatchInput,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import { getUserConfig } from "../../utils/userConfig";
import { CURRENT_SEASON } from "../../config/constants";
import { add_new_uploader_images } from "../../redux/actions/uploader";
import { ReactComponent as ImagePlaceholder } from "../../static/img/ImagePlaceholder.svg";
import FileInput from "./FileInput";
import { useStyles } from "./styles";

const UploaderPage = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const notify = useNotify();

  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const queueImages = useSelector((state) => state.uploader.queueImages);
  const readyImages = useSelector((state) => state.uploader.readyImages);
  const processingImages = useSelector(
    (state) => state.uploader.processingImages
  );
  const failedImages = useSelector((state) => state.uploader.failedImages);

  const handleOnDrop = useCallback(
    (droppedImages, formData) => {
      if (droppedImages && droppedImages.length) {
        dispatch(add_new_uploader_images(droppedImages, formData));
      } else {
        notify("resource.uploader.duplicate_images", "warning");
      }
    },
    [dispatch, notify]
  );

  const handleOnError = useCallback(
    (file, errors = {}) => {
      if (errors.invalidType) {
        notify("resource.uploader.invalid_type_error", "error", {
          file,
        });
      } else if (errors.maxSize) {
        notify("resource.uploader.file_size_error", "error", {
          file,
        });
      } else if (errors.maxFiles) {
        notify("resource.uploader.file_number_error", "error", {
          limit: -1,
        });
      }
    },
    [notify]
  );

  const getFileInputContent = (disabled) => {
    if (isDesktop) {
      if (disabled) {
        return (
          <div className={classes.disabledInput}>
            {translate("resource.uploader.match_act_advise")}
          </div>
        );
      } else {
        return (
          <>
            <div className={classes.imagePlaceholder}>
              <ImagePlaceholder />
            </div>
            <p>{translate("resource.uploader.drag_and_drop")}</p>
            <p>
              {translate("commons.or")}{" "}
              <span className={classes.fileUploadLink}>
                {translate("resource.uploader.select_file")}
              </span>
            </p>
          </>
        );
      }
    } else {
      return (
        <Button color="primary" variant="contained" disabled={disabled}>
          {disabled
            ? translate("resource.uploader.match_act_advise")
            : translate("resource.uploader.select_file_mobile")}
        </Button>
      );
    }
  };

  return (
    <div className={classes.container}>
      <SectionTitle>{translate("resource.uploader.menu")}</SectionTitle>
      <Card className={classes.card}>
        <FormWithRedirect
          resource="uploader"
          initialValues={{ type: "match" }}
          render={({ form }) => {
            const resetValues = () => {
              form.change("championship", null);
              form.change("gameweek", null);
              form.change("match", null);
              form.change("event", null);
              form.change("act", null);
            };
            return (
              <FormDataConsumer>
                {({ formData }) => {
                  const disabled = !formData.match && !formData.act;
                  return (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextInput
                            fullWidth
                            label="commons.title"
                            source="title"
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <SelectInput
                            source="type"
                            label="commons.type"
                            fullWidth
                            choices={[
                              {
                                id: "match",
                                name: "commons.match",
                              },
                              {
                                id: "event",
                                name: "commons.event",
                              },
                            ]}
                            translateChoice
                            onChange={resetValues}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <ReferenceInput
                            label="commons.season"
                            source="season"
                            reference="seasons"
                          >
                            <SelectInput
                              defaultValue={
                                getUserConfig("season_recent_id") ||
                                CURRENT_SEASON
                              }
                            />
                          </ReferenceInput>
                        </Grid>
                      </Grid>
                      {formData.type === "match" && (
                        <MatchInput
                          sourceSeason="season"
                          sourceChampionship="championship"
                          sourceGameweek="gameweek"
                          sourceMatch="match"
                        />
                      )}
                      {formData.type === "event" && (
                        <EventInput
                          sourceSeason="season"
                          sourceEvent="event"
                          sourceAct="act"
                        />
                      )}
                      <div
                        className={classNames(classes.uploader, {
                          [classes.uploaderDesktop]: isDesktop,
                        })}
                      >
                        <FileInput
                          maxFiles={-1}
                          onDrop={(droppedImages) =>
                            handleOnDrop(droppedImages, formData)
                          }
                          onError={handleOnError}
                          disabled={disabled}
                          files={[
                            ...queueImages,
                            ...readyImages,
                            ...processingImages,
                            ...failedImages,
                          ]}
                        >
                          {getFileInputContent(disabled)}
                        </FileInput>
                      </div>
                    </>
                  );
                }}
              </FormDataConsumer>
            );
          }}
        />
      </Card>
      <PhotoUploadPanel />
    </div>
  );
};

export default UploaderPage;
