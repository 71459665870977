import "moment/locale/es";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconClose from "@material-ui/icons/Close";
import IconCalendar from "@material-ui/icons/DateRange";
import classnames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useInput, useLocale, useTranslate } from "react-admin";
import { DayPickerRangeController } from "react-dates";

import styles from "./styles";

const useStyles = makeStyles(styles, { name: "WBODateRangeInput" });

const START_DATE = "startDate";
const END_DATE = "endDate";

const DateRangeInput = ({
  label,
  variant,
  disabled,
  className,
  source,
  name,
}) => {
  const lang = useLocale();
  moment.locale(lang);

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({ label, variant, disabled, className, source, name });
  const classes = useStyles();
  const translate = useTranslate();
  const [focusedInput, setFocusedInput] = useState(null);

  const valueStartDate =
    input.value && input.value.after ? moment(input.value.after) : null;
  const valueEndDate =
    input.value && input.value.before ? moment(input.value.before) : null;

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [valueDates, setValueDates] = useState({
    after: valueStartDate,
    before: valueEndDate,
  });

  const inputRef = useRef();

  useEffect(() => {
    if (!showDatePicker && focusedInput) {
      setFocusedInput(false);
    } else if (!focusedInput && showDatePicker) {
      setFocusedInput(true);
    }
  }, [showDatePicker, focusedInput]);

  useEffect(() => {
    if (focusedInput && document.activeElement !== inputRef.current) {
      inputRef.current.focus();
    } else if (!focusedInput && document.activeElement === inputRef.current) {
      inputRef.current.blur();
    }
  });

  useEffect(() => {
    let startDate = valueDates?.after;
    let endDate = valueDates?.before;
    if (
      !compareDateValue(startDate, valueStartDate) ||
      !compareDateValue(endDate, valueEndDate)
    ) {
      setValueDates({ after: valueStartDate, before: valueEndDate });
    }
    // eslint-disable-next-line
  }, [input.value]);

  const compareDateValue = (date1, date2) => {
    if (moment.isMoment(date1) && moment.isMoment(date2)) {
      return date1.format("YYYY-MM-DD") === date2.format("YYYY-MM-DD");
    } else {
      return date1 === date2;
    }
  };

  const resetValues = (e) => {
    e.preventDefault();
    setValueDates(null);
    setFocusedInput(null);
    input.onChange(null);
    if (focusedInput) {
      setShowDatePicker(false);
    }
  };

  const textStartDate = valueDates?.after
    ? valueDates.after.format("DD/MM/YYYY")
    : translate("components.inputDateRange.from");
  const textEndDate = valueDates?.before
    ? valueDates.before.format("DD/MM/YYYY")
    : translate("components.inputDateRange.to");

  const inputError = touched && error;

  return (
    <div
      className={classnames(classes.inputBox, {
        focused: !!focusedInput,
        [className]: !!className,
      })}
    >
      <TextField
        inputRef={inputRef}
        className={classes.margin}
        id={id}
        label={isRequired ? `*${label}` : label}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconCalendar />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={resetValues}>
                <IconClose />
              </IconButton>
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        helperText={inputError ? translate(inputError) : ""}
        variant={variant}
        margin="dense"
        size="medium"
        inputProps={{ readOnly: true }}
      />
      <div
        className={classes.textValues}
        onClick={() => {
          setShowDatePicker(!showDatePicker);
          setFocusedInput(START_DATE);
        }}
      >
        <span className={classnames({ focused: focusedInput === START_DATE })}>
          {textStartDate}
        </span>
        {" - "}
        <span className={classnames({ focused: focusedInput === END_DATE })}>
          {textEndDate}
        </span>
      </div>
      {showDatePicker && (
        <div className={classes.boxDateRangeInput}>
          <DayPickerRangeController
            numberOfMonths={1}
            startDate={valueDates?.after}
            endDate={valueDates?.before}
            onDatesChange={({ startDate, endDate }) => {
              setValueDates({
                after: startDate,
                before: endDate,
              });
              if (startDate && endDate) {
                input.onChange({
                  after: startDate.format("YYYY-MM-DD 00:00:00"),
                  before: endDate.format("YYYY-MM-DD 23:59:59"),
                });
              }
              if (valueDates?.before !== endDate) {
                setShowDatePicker(false);
              }
            }}
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focus) => setFocusedInput(focus)}
            onOutsideClick={() => setShowDatePicker(false)}
            hideKeyboardShortcutsPanel={true}
          />
        </div>
      )}
    </div>
  );
};

DateRangeInput.defaultProps = {
  alwaysOn: false,
  variant: "standard",
  disabled: false,
};

DateRangeInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default DateRangeInput;
