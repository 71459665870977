import { Button, Menu, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useListContext, useTranslate } from "react-admin";

const BulkActionsMenuButton = ({ actions, selectAll, deselectAll }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const translate = useTranslate();

  const { selectedIds, filterValues, ids } = useListContext();
  const isTrash = !!filterValues.trashed;
  const disabled = selectedIds.length === 0;

  const isAllSelected = useMemo(() => {
    let bool = true;

    for (let i = 0; i < ids.length; i++) {
      if (!selectedIds.includes(ids[i])) {
        bool = false;
        break;
      }
    }

    return bool;
  }, [selectedIds, ids]);

  return actions ? (
    <>
      <div>
        <Button
          aria-controls="actions-button-menu"
          aria-haspopup="true"
          onClick={(e) => setMenuAnchor(e.currentTarget)}
          variant="outlined"
          color="primary"
          size="small"
        >
          {translate("commons.bulk_actions")}
        </Button>
      </div>
      <Menu
        id="actions-button-menu"
        keepMounted
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem
          onClick={() => {
            setMenuAnchor(null);
            if (isAllSelected) {
              deselectAll();
            } else {
              selectAll();
            }
          }}
        >
          {isAllSelected
            ? translate("commons.deselect_all")
            : translate("commons.select_all")}
        </MenuItem>
        {!disabled &&
          actions.map((action, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setMenuAnchor(null);
                action.onClick(selectedIds, isTrash);
              }}
            >
              {typeof action.label === "function"
                ? action.label(selectedIds, isTrash)
                : action.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  ) : (
    <div />
  );
};

BulkActionsMenuButton.propTypes = {
  actions: PropTypes.array,
};

export default BulkActionsMenuButton;
