import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => {
    return {
      mainContainer: {
        flex: "1 0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "50px 20px",
        [theme.breakpoints.up("sm")]: {
          "& form": {
            padding: theme.spacing(0, 2),
          },
        },
      },
      card: {
        position: "relative",
        width: "90vw",
        maxWidth: "1024px",
        borderLeft: `${theme.spacing()}px solid ${theme.palette.primary.main}`,
        textAlign: "center",
      },
      cardDescription: {
        maxWidth: "800px",
        margin: "0 auto",
      },
      localeContainer: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: 10,
      },
      logo: {
        height: 36,
        margin: theme.spacing(2),
      },
      buttons: {
        margin: "0 auto",
        textAlign: "center",
        paddingTop: theme.spacing(2),
        "& + &": {
          paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.up("sm")]: {
          "& .MuiButton-root": {
            fontSize: "16px",
          },
        },
        "& > button": {
          minWidth: "120px",
          margin: theme.spacing(0, 0.5),
        },
      },
      checkbox: {
        "& .MuiTypography-body1": {
          fontSize: "12px",
        },
        [theme.breakpoints.up("sm")]: {
          "& .MuiTypography-body1": {
            fontSize: "16px",
          },
        },
      },
      termsLink: {
        color: theme.palette.primary.main,
        "&:hover": {
          textDecoration: "underline",
        },
      },
      termsContent: {
        lineHeight: 1.5,
        "& li": {
          marginTop: theme.spacing(2),
        },
        "& > .terms-modal-title": {
          padding: theme.spacing(0, 4, 2),
          fontSize: 24,
          textAlign: "center",
        },
      },
      termsModalClose: {
        position: "absolute",
        top: 0,
        right: 0,
      },
      loading: {
        display: "none",
        background: "rgba(0,0,0,0.2)",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      },
      showLoading: {
        display: "flex",
      },
    };
  },
  { name: "WBORegister" }
);
