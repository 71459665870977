import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    maxHeight: "94px",
    padding: `${theme.spacing(2)}px`,
    fontSize: "14px",
    transition: "background 0.3s",
    "&:hover": {
      background: theme.palette.background.bottomPanelItemHover,
    },
  },
  loading: {
    padding: `${theme.spacing(2)}px`,
  },
  image: {
    flex: "0 0 110px",
    "& > img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    marginRight: `${theme.spacing(2)}px`,
  },
  text: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: "0px",
  },
  button: {
    cursor: "pointer",
    flex: "0 0 auto",
    padding: `${theme.spacing()}px`,
    marginRight: `-${theme.spacing(2)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.palette.type === "dark"
        ? theme.palette.grey[100]
        : theme.palette.grey[600],
  },
}));
