import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { useTranslate } from "react-admin";
import { useSelector } from "react-redux";

import BottomPanel from "../../../components/custom/BottomPanel";
import { useWrapperDesktopStyles } from "./styles";

const AdvancedTagger = ({ children }) => {
  const translate = useTranslate();
  const desktopClasses = useWrapperDesktopStyles();

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const selectedIds = useSelector(
    (state) => state.admin.resources.tagger.list.selectedIds
  );

  if (isDesktop) {
    return (
      <div className={desktopClasses.root}>
        <div className={desktopClasses.body}>{children}</div>
      </div>
    );
  } else {
    return (
      <BottomPanel
        title={translate("resource.tagger.advancedTagger.title")}
        hidden={selectedIds.length === 0}
      >
        {children}
      </BottomPanel>
    );
  }
};

export default AdvancedTagger;
