import React from "react";
import { CreateButton, useListContext } from "react-admin";

import ToggleTrashButton from "../../../../components/buttons/ToggleTrashButton";
import { useStyles } from "./styles";

const Actions = () => {
  const classes = useStyles();
  const { basePath, resource } = useListContext();

  return (
    <div className={classes.buttons}>
      <ToggleTrashButton />
      <CreateButton
        basePath={basePath}
        variant="contained"
        label={`resource.${resource}.action_create`}
      />
    </div>
  );
};

export default Actions;
