export const userActiveValors = [
  {
    value: 0,
    name: "inactive",
    translate: "commons.user_active.inactive",
  },
  {
    value: 1,
    name: "active",
    translate: "commons.user_active.active",
  },
  {
    value: 2,
    name: "pending",
    translate: "commons.user_active.pending",
  },
];
