import list from "./list";
import edit from "./edit";
import create from "./create";

export default {
  list,
  edit,
  create,
  options: { label: "resource.groups.menu" },
};
