import Grid from "@material-ui/core/Grid";
import React from "react";
import { useQueryWithStore } from "react-admin";

import { SelectInput, TextInput } from "../../../../components/inputs";
import { userActiveValors } from "../../../../resources/users/constants";

const Filters = () => {
  const {
    loaded: loadedPartnerships,
    data: dataPartnerships,
  } = useQueryWithStore({
    type: "getList",
    resource: "partnerships",
    payload: {},
  });
  return (
    <Grid container key="grid" spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <TextInput label="resource.users.email" source="email" />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        {loadedPartnerships && (
          <SelectInput
            allowEmpty={true}
            source="partnership"
            choices={dataPartnerships}
            label="resource.users.typeOfpartnership"
            optionValue="@id"
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SelectInput
          label="commons.state"
          source="active"
          choices={userActiveValors}
          optionText="translate"
          optionValue="value"
          translateChoice
          allowEmpty
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
