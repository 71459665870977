import React from "react";
import { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { setSidebarVisibility } from "ra-core";

export const DRAWER_WIDTH = 200;
export const CLOSED_DRAWER_WIDTH = 55;

const useStyles = makeStyles(
  (theme) => ({
    drawerPaper: {
      backgroundColor: theme.palette.background.sidebar,
      position: "relative",
      width: "200px",
      height: "auto",
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      borderRight: "none",
      [theme.breakpoints.down("md")]: {
        marginTop: 0,
        height: "100vh",
        position: "inherit",
      },
      [theme.breakpoints.up("lg")]: {
        top: theme.values.toolbar.height.lg,
        position: "sticky",
        border: "none",
        maxHeight: `calc(100vh - ${theme.values.toolbar.height.lg}px)`,
        height: "100%",
      },
      zIndex: "inherit",
    },
  }),
  { name: "WBOSidebar" }
);

const Sidebar = (props) => {
  const {
    children,
    closedSize,
    size,
    classes: classesOverride,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.locale); // force redraw on locale change
  const handleClose = () => dispatch(setSidebarVisibility(false));
  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
  const classes = useStyles({ ...props, open });

  return isLarge ? (
    <Drawer
      variant="temporary"
      open={open}
      PaperProps={{
        className: classes.drawerPaper,
      }}
      onClose={toggleSidebar}
      {...rest}
    >
      {cloneElement(Children.only(children), {
        onMenuClick: handleClose,
      })}
    </Drawer>
  ) : (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        className: classes.drawerPaper,
      }}
      onClose={toggleSidebar}
      {...rest}
    >
      {children}
    </Drawer>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sidebar;
