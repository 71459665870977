import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import {
  BooleanInput,
  FormDataConsumer,
  maxLength,
  required,
  useQueryWithStore,
  useTranslate,
} from "react-admin";
import { useForm } from "react-final-form";

import {
  SelectArrayInput,
  SelectInput,
  TextInput,
} from "../../../components/inputs";
import PasswordInputs from "./passwordInputs";
import { userActiveValors } from "../constants";

const useStyles = makeStyles((theme) => ({
  rowSwitches: {
    display: "flex",
    flexWrap: "wrap",
    "& > div": {
      marginRight: theme.spacing(2),
    },
  },
  dowloadPermissionTitle: {
    marginTop: theme.spacing(2),
  },
}));

export default ({ isCreate }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const form = useForm();
  const {
    loaded: loadedPartnerships,
    data: dataPartnerships,
  } = useQueryWithStore({
    type: "getList",
    resource: "partnerships",
    payload: {},
  });
  const { loaded: loadedRoles, data: dataRoles } = useQueryWithStore({
    type: "getList",
    resource: "roles",
    payload: {},
  });
  const { loaded: loadedGroups, data: dataGroups } = useQueryWithStore({
    type: "getList",
    resource: "groups",
    payload: {
      pagination: {
        page: 1,
        perPage: 50,
      },
      sort: {
        field: "name",
        order: "ASC",
      },
    },
  });

  const formatChoicesRoles = (data) => {
    return data.map((item) => ({ id: item, name: `resource.roles.${item}` }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="active"
          choices={userActiveValors}
          label="commons.state"
          optionValue="value"
          optionText="translate"
          validate={[required()]}
          translateChoice
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="commons.name" source="name" />
        <TextInput
          disabled={!isCreate}
          label="commons.email"
          source="email"
          validate={[required()]}
        />
        <PasswordInputs isCreate={isCreate} />
      </Grid>
      <Grid item xs={12} md={6}>
        {loadedPartnerships && (
          <SelectInput
            allowEmpty={true}
            emptyValue=""
            emptyText="commons.not_selected"
            source="partnership"
            choices={dataPartnerships}
            label="resource.users.typeOfpartnership"
            optionValue="@id"
            validate={[required()]}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          label="resource.users.partnership"
          source="partnershipDescription"
          validate={[
            required(
              "resource.users.validations.partnershipDescription.required"
            ),
            maxLength(255),
          ]}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        {loadedRoles && (
          <SelectArrayInput
            label="resource.users.roles"
            source="roles"
            choices={formatChoicesRoles(dataRoles)}
            variant="standard"
            onChange={(roles) => {
              if (!roles.includes("ROLE_USER")) {
                form.change("groups", []);
              }
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormDataConsumer>
          {({ formData }) =>
            formData.roles &&
            formData.roles.includes("ROLE_USER") &&
            loadedGroups && (
              <SelectArrayInput
                variant="standard"
                optionValue="@id"
                choices={dataGroups}
                label="resource.users.groups"
                source="groups"
                validate={[
                  required("resource.users.validations.userGrops.required"),
                ]}
                allowEmpty
                object
              />
            )
          }
        </FormDataConsumer>
      </Grid>
      {/* <Grid item xs={12}>
        <Divider />
      </Grid>       */}
      <Grid item xs={12}>
        <Divider />
        <Typography
          variant="subtitle1"
          display="block"
          className={classes.dowloadPermissionTitle}
        >
          {translate("resource.users.dowloadPermissionTitle")}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.rowSwitches}>
        <BooleanInput
          label="resource.users.downloadOriginal"
          source="downloadOriginal"
        />
        <BooleanInput
          label="resource.users.internalEventPermission"
          source="internalEventPermission"
        />
        <BooleanInput
          label="resource.users.downloadAppPlayer"
          source="downloadAppPlayer"
        />
      </Grid>
    </Grid>
  );
};
