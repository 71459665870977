import { Grid } from "@material-ui/core";
import _get from "lodash/get";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";
import { FormDataConsumer, ReferenceInput, useDataProvider } from "react-admin";
import { useForm } from "react-final-form";

import { SelectInput } from "../../inputs";

const Input = ({
  idSeason,
  sourceChampionship,
  sourceGameweek,
  sourceMatch,
}) => {
  const dataProvider = useDataProvider();
  const form = useForm();
  const idSeasonPrev = useRef(idSeason);

  const selectCurrentGameweek = useCallback(
    (idChampionship) => {
      dataProvider
        .getOne("championships", { id: idChampionship })
        .then(({ data }) => {
          const idCurrentGameweek = _get(data, "currentGameweek.id");
          if (idCurrentGameweek) {
            form.change(sourceGameweek, idCurrentGameweek);
          }
        });
    },
    [dataProvider, form, sourceGameweek]
  );

  const handleChange = useCallback(
    (type, value) => {
      switch (type) {
        case "season":
          form.change(sourceChampionship, null);
          form.change(sourceGameweek, null);
          form.change(sourceMatch, null);
          break;
        case "championships":
          form.change(sourceGameweek, null);
          form.change(sourceMatch, null);
          if (value) {
            selectCurrentGameweek(value);
          }
          break;
        case "gameweeks":
          form.change(sourceMatch, null);
          break;
        default:
          break;
      }
    },
    [
      form,
      sourceChampionship,
      sourceGameweek,
      sourceMatch,
      selectCurrentGameweek,
    ]
  );

  useEffect(() => {
    if (idSeasonPrev.current !== idSeason) {
      idSeasonPrev.current = idSeason;
      handleChange("season");
    }
  }, [idSeason, handleChange]);

  const optionTextMatch = (choice) => {
    const homeTeam = _get(choice, "homeTeam.nickname");
    const awayTeam = _get(choice, "awayTeam.nickname");
    return `${homeTeam} - ${awayTeam}`;
  };

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              {idSeason ? (
                <ReferenceInput
                  source={sourceChampionship}
                  reference="championships"
                  label="commons.competitions"
                  filter={{ season: idSeason }}
                  sort={{ field: "id", order: "ASC" }}
                  onChange={(e) =>
                    handleChange("championships", e.target.value)
                  }
                  allowEmpty
                >
                  <SelectInput optionText="competition.name" />
                </ReferenceInput>
              ) : (
                <SelectInput
                  label="commons.competitions"
                  source={sourceChampionship}
                  disabled
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {sourceGameweek &&
              idSeason &&
              _get(formData, sourceChampionship) ? (
                <ReferenceInput
                  source={sourceGameweek}
                  reference="gameweeks"
                  label="commons.gameweeks"
                  filter={{ championship: _get(formData, sourceChampionship) }}
                  onChange={(e) => handleChange("gameweeks", e.target.value)}
                  allowEmpty
                >
                  <SelectInput />
                </ReferenceInput>
              ) : (
                <SelectInput
                  label="commons.gameweeks"
                  source={sourceGameweek}
                  disabled
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {sourceGameweek &&
              idSeason &&
              _get(formData, sourceChampionship) &&
              _get(formData, sourceGameweek) ? (
                <ReferenceInput
                  source={sourceMatch}
                  reference="matches"
                  label="commons.matches"
                  filter={{ gameweek: _get(formData, sourceGameweek) }}
                  allowEmpty
                >
                  <SelectInput optionText={optionTextMatch} />
                </ReferenceInput>
              ) : (
                <SelectInput
                  label="commons.matches"
                  source={sourceMatch}
                  disabled
                />
              )}
            </Grid>
          </Grid>
        );
      }}
    </FormDataConsumer>
  );
};

Input.propTypes = {
  idSeason: PropTypes.number,
  sourceChampionship: PropTypes.string,
  sourceGameweek: PropTypes.string,
  sourceMatch: PropTypes.string,
};

export default Input;
