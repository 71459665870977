import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { refreshView, useDataProvider, useListContext } from "react-admin";
import { useDispatch } from "react-redux";

import { useButtonStyle, useStyles } from "./styled";

const TagButton = ({ label, values, disabled, color, type }) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const classesButton = useButtonStyle({ color: color || false });
  const { selectedIds, data } = useListContext();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    let images = selectedIds.map((imageId) => ({ ...data[imageId] }));
    let actionAdd = false;

    for (let i = 0; i < images.length; i++) {
      for (let j = 0; j < values.length; j++) {
        const iHaveMetadata = images[i].metadatas.some(
          (element) =>
            element.value === values[j].value &&
            element.metadataType.name === values[j].type
        );
        if (!iHaveMetadata) {
          actionAdd = true;
          break;
        }
      }
      if (actionAdd) {
        break;
      }
    }

    images.forEach((image) => {
      values.forEach((value) => {
        if (actionAdd) {
          const iHaveMetadata = image.metadatas.some(
            (element) =>
              element.value === value.value &&
              element.metadataType.name === value.type
          );
          if (!iHaveMetadata) {
            image.metadatas.push(value);
          }
        } else {
          const index = image.metadatas.findIndex(
            (element) =>
              element.value === value.value &&
              element.metadataType.name === value.type
          );
          if (index !== -1) {
            image.metadatas.splice(index, 1);
          }
        }
      });

      return { id: image.id, metadatas: image.metadatas };
    });

    if (images) {
      setLoading(true);
      dataProvider
        .updateManyCustom("multi-images-metadatas", { data: images })
        .then((data) => {
          dispatch(refreshView());
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const myDisabled = disabled || selectedIds.length === 0 || loading;
  return (
    <Button
      classes={classesButton}
      className={classNames(classes.root, {
        [classes.buttonTypeTeam]: type === "team",
      })}
      disabled={myDisabled}
      startIcon={type === "default" ? <LocalOfferOutlinedIcon /> : false}
      color="primary"
      variant="outlined"
      onClick={handleClick}
    >
      {typeof label === "string" ? (
        <span className={classes.label}>{label}</span>
      ) : (
        label
      )}
      {loading && (
        <div className={classes.loading}>
          <CircularProgress size="24px" />
        </div>
      )}
    </Button>
  );
};

TagButton.defaultProps = {
  disabled: false,
  type: "default",
  color: false,
};

TagButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  type: PropTypes.oneOf(["default", "team"]),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([
        "season",
        "competition",
        "championship",
        "gameweek",
        "match",
        "team",
        "player",
        "other",
        "event",
        "act",
      ]),
      value: PropTypes.number,
    })
  ).isRequired,
  disabled: PropTypes.bool,
};

export default TagButton;
