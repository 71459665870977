import { Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import {
  Datagrid,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
  BooleanField,
} from "react-admin";

import RecoverTrashButton from "../../../components/buttons/RecoverTrashButton";
import MobileList from "../../../components/datagrid/MobileList";
import List from "../../../components/ra-components/List";
import Actions from "./Actions";
import BulkActions from "./BulkActions";
import Filters from "./Filters";
import { useStyles } from "./styles";

export default (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const resource = "groups";

  return (
    <List
      filters={<Filters />}
      bulkActions={<BulkActions />}
      actions={<Actions />}
      {...props}
    >
      {isDesktop ? (
        <Datagrid rowClick="edit">
          <TextField
            label="Name"
            source="name"
            cellClassName={classes.nameField}
          />
          <BooleanField source="default" />
          <RecoverTrashButton />
        </Datagrid>
      ) : (
        <MobileList
          linkType="edit"
          content={(record) => (
            <div>
              <Typography>
                {translate("commons.name")}: {record.name}
              </Typography>
              <Typography>
                Default:{" "}
                {translate(record.default ? "commons.yes" : "commons.no")}
              </Typography>
            </div>
          )}
          actions={[
            {
              label: (record, isTrash) =>
                isTrash
                  ? translate("commons.recover")
                  : translate("commons.delete"),
              onClick: (record, isTrash) => {
                dataProvider
                  .delete(resource, {
                    id: record.id,
                    data: { trashed: !isTrash },
                  })
                  .then(() => {
                    notify(
                      isTrash
                        ? `resource.${resource}.notify.recovered`
                        : `resource.${resource}.notify.deleted`,
                      "info"
                    );
                    refresh();
                  });
              },
            },
          ]}
        />
      )}
    </List>
  );
};
