import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  cellImageField: {
    width: "250px",
    height: "100px",
  },
  cellTextField: {
    width: "100%",
    maxWidth: "13vw",
    overflow: "hidden",
    "& > span": {
      display: "block",
    },
  },
  mobileListImage: {
    height: "100px",
    width: "200px",
    marginRight: theme.spacing(2),
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
}));
