export default (theme) => ({
  rowPagination: {
    padding: theme.spacing(2, 1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "space-between",
      padding: theme.spacing(2, 0),
    },
  },
  rowTop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  controls: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  noResultsRoot: {
    padding: theme.spacing(2),
    fontSize: 26,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
});
