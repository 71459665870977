import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: `${theme.spacing(2) + 48}px`,
    paddingRight: `${theme.spacing(2) + 48}px`,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    fontSize: theme.spacing(2.5),
    position: "relative",
    "& a": {
      position: "absolute",
      top: "50%",
      left: theme.spacing(2),
      transform: "translateY(-50%)",
    },
  },
}));
