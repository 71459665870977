import { makeStyles } from "@material-ui/core";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";

import Shield from "../../../components/custom/Shield";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "32px",
  },
  rowOther: {
    // display: "flex",
  },
  metaOther: {
    display: "inline-block",
    height: "14px",
    width: "14px",
    borderRadius: "8px",
    border: "1px solid",
    marginRight: theme.spacing(0.5),
  },
  rowTeams: {
    display: "flex",
    alignItems: "center",
  },
}));

const MetadataField = ({
  source,
  sourceTagged,
  other,
  teams,
  record,
  ...rest
}) => {
  const classes = useStyles();
  const metadatas = _get(record, source);

  return (
    <div className={classes.root}>
      <div className={classes.rowOther}>
        {other.map((each) => {
          const isChecket = metadatas.some(
            (element) =>
              element.value === each.id &&
              element?.metadataType?.name === "other"
          );
          let metaStyle = { borderColor: each.color };
          if (isChecket) {
            metaStyle.backgroundColor = each.color;
          }

          return (
            <span
              className={classes.metaOther}
              style={metaStyle}
              key={each.id}
            />
          );
        })}
      </div>
      <div className={classes.rowTeams}>
        {teams.map((each) => {
          const isChecket = metadatas.some(
            (element) =>
              element.value === each.id &&
              element?.metadataType?.name === "team"
          );
          if (isChecket) {
            return <Shield idTeam={each.id} size="xs" key={each.id} />;
          } else {
            return false;
          }
        })}
        {sourceTagged && _get(record, sourceTagged) && (
          <CheckCircleSharpIcon color="primary" fontSize="small" />
        )}
      </div>
    </div>
  );
};

MetadataField.defaultProps = {
  other: [],
  teams: [],
};

MetadataField.propTypes = {
  source: PropTypes.string.isRequired,
  sourceTagged: PropTypes.string,
  other: PropTypes.arrayOf(PropTypes.object),
  teams: PropTypes.arrayOf(PropTypes.object),
};

export default MetadataField;
