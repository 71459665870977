import Button from "@material-ui/core/Button";
import React, { useCallback } from "react";
import { useListContext, useTranslate } from "react-admin";

const ToggleTrashButton = () => {
  const { setFilters, filterValues, selectedIds, onSelect } = useListContext();
  const translate = useTranslate();
  const toggleTrash = useCallback(() => {
    if (filterValues.trashed) {
      delete filterValues.trashed;
    } else {
      filterValues.trashed = true;
    }
    setFilters(filterValues);

    if (selectedIds.length > 0) onSelect([]);
  }, [filterValues, setFilters, selectedIds.length, onSelect]);

  return (
    <Button
      size="small"
      variant="outlined"
      color="primary"
      onClick={toggleTrash}
    >
      {filterValues.trashed
        ? translate("commons.show_list")
        : translate("commons.show_trash")}
    </Button>
  );
};

export default ToggleTrashButton;
