import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2),
    },
  },
  card: {
    padding: theme.spacing(),
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      background: theme.palette.background.darkPaper,
      borderLeft: `${theme.spacing()}px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2),
    },
  },
  uploader: {
    marginTop: `${theme.spacing(2)}px`,
    padding: `${theme.spacing(2)}px`,
  },
  uploaderDesktop: {
    border: `${theme.spacing(0.5)}px dashed ${
      theme.palette.type === "dark" ? "#828282" : "#f2f2f2"
    }`,
    borderRadius: `${theme.spacing(3)}px`,
    "&, & > div": {
      minHeight: `${theme.spacing(40)}px`,
    },
  },
  disabledInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  imagePlaceholder: {
    "& rect": {
      fill: theme.palette.type === "dark" ? "#828282" : "#F2F2F2",
    },
    "& circle, & path": {
      fill: theme.palette.type === "dark" ? "#4F4F4F" : "#E0E0E0",
    },
  },
  fileUploadLink: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    transition: "opacity 0.3s",
    "&:hover": {
      opacity: 0.7,
    },
  },
}));
