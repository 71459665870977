import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    uploadFilesButtonContainer: {
      flex: "0 0 auto",
      padding: theme.spacing(2),
    },
    uploadFilesButtonContainerExtra: {
      padding: theme.spacing(),
    },
    bar: {
      padding: theme.spacing(),
      cursor: "pointer",
      fontWeight: 500,
      height: "35px",
      transition: "padding 0.3s, height 0.3s",
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& svg:last-child": {
        transition: "transform 0.3s",
      },
    },
    barOpen: {
      "& svg:last-child": {
        transform: "rotate(180deg)",
      },
    },
    topBar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    topBarDisabled: {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
      cursor: "initial",
    },
    topBarHidden: {
      height: 0,
      padding: 0,
    },
    greyBar: {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.common.black
          : theme.palette.grey[300],
      color:
        theme.palette.type === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    greenBar: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
    redBar: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  }),
  {
    name: "PhotoUploadPanel",
  }
);
