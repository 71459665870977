import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { useTranslate } from "react-admin";
import { useDispatch, useSelector } from "react-redux";

import { start_upload_uploader_images } from "../../../redux/actions/uploader";
import withTheme from "../../ra-components/pageDecorators/withTheme";
import BottomPanel from "../BottomPanel";
import PhotoList from "./PhotoList";
import { useStyles } from "./styles";
import { getHeaderTitle, getLengthImages } from "./utils";

const PhotoUploadPanel = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const queueImages = useSelector((state) => state.uploader.queueImages);
  const processingImages = useSelector(
    (state) => state.uploader.processingImages
  );
  const readyImages = useSelector((state) => state.uploader.readyImages);
  const failedImages = useSelector((state) => state.uploader.failedImages);

  const classes = useStyles();
  const { total, processingLength } = getLengthImages({
    processingImages,
    queueImages,
    failedImages,
    readyImages,
  });

  function getExtraContent() {
    if (processingLength > 0 && total > 1) {
      return (
        <>
          <CircularProgress size={16} />{" "}
          {translate("resource.uploader.uploading")}:{" "}
          {processingImages[0]?.name}
        </>
      );
    }

    if (queueImages.length > 0 || processingImages.length > 0) {
      return (
        <div className={classes.uploadFilesButtonContainerExtra}>
          <Button
            onClick={() => dispatch(start_upload_uploader_images())}
            color="primary"
            fullWidth
            variant="contained"
            disabled={processingImages.length > 0}
          >
            {translate("resource.uploader.upload_photos")}
          </Button>
        </div>
      );
    }

    return false;
  }

  return (
    <BottomPanel
      title={getHeaderTitle(
        { processingImages, queueImages, failedImages, readyImages },
        translate
      )}
      hidden={total === 0}
      extraContent={getExtraContent()}
      extraShow={total > 0}
    >
      <>
        <div>
          <PhotoList
            label={translate("resource.uploader.in_process")}
            classes={classes}
            imagesKey="processingImages"
            variant="greyBar"
          />
          <PhotoList
            label={translate("resource.uploader.in_queue")}
            classes={classes}
            imagesKey="queueImages"
            variant="greyBar"
          />
          <PhotoList
            label={translate("resource.uploader.failed")}
            classes={classes}
            imagesKey="failedImages"
            variant="redBar"
          />
          <PhotoList
            label={translate("resource.uploader.ready")}
            classes={classes}
            imagesKey="readyImages"
            variant="greenBar"
          />
        </div>
        {(queueImages.length > 0 || processingImages.length > 0) && (
          <div className={classes.uploadFilesButtonContainer}>
            <Button
              onClick={() => dispatch(start_upload_uploader_images())}
              color="primary"
              fullWidth
              variant="contained"
              disabled={processingImages.length > 0}
            >
              {translate("resource.uploader.upload_photos")}
            </Button>
          </div>
        )}
      </>
    </BottomPanel>
  );
};

export default withTheme(PhotoUploadPanel);
