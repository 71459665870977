import FormControlLabel from "@material-ui/core/FormControlLabel";

import Switch from "@material-ui/core/Switch";
import React from "react";
import { useTranslate, useDataProvider, useNotify } from "react-admin";
import { useDispatch, useSelector } from "react-redux";

import { change_theme } from "../../../config/theme";
import {
  useThemeSelectorStyles,
  useSwitchStyles,
  useFormControlLabelStyles,
} from "./styles";
import { getUserConfig } from "../../../utils/userConfig";

const ThemeSelector = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const themeKey = useSelector((state) => state.theme);
  const classes = useThemeSelectorStyles();
  const switchClasses = useSwitchStyles();
  const formControlLabelClasses = useFormControlLabelStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleChange = (event) => {
    const { checked } = event.target;

    let theme = checked ? "light" : "dark";
    dispatch(change_theme(theme));
    dataProvider
      .update("user-profiles", {
        id: getUserConfig("id"),
        data: {
          theme,
        },
      })
      .catch((err) => {
        console.log({ err });
        notify(err.message, "warning");
      });
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        classes={formControlLabelClasses}
        control={
          <Switch
            checked={themeKey === "light"}
            onChange={handleChange}
            name="checkedA"
            color="primary"
            classes={switchClasses}
          />
        }
        label={translate("commons.theme_mode", {
          theme: translate(`commons.${themeKey}`),
        })}
        labelPlacement="end"
      />
    </div>
  );
};

export default ThemeSelector;
