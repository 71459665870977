import { Grid } from "@material-ui/core";
import React from "react";
import { BooleanInput } from "react-admin";

import { TextInput } from "../../../components/inputs";
import ChecksPermisions from "./ChecksPermissions";
import ListPermissions from "./ListPermissions";

export default (props) => {
  return (
    <Grid container spacing={2} alignItems="flex-end" justify="flex-end">
      <Grid item xs={12} md={2} sm={3}>
        <BooleanInput label="Default" source="default" />
      </Grid>
      <Grid item xs={12} md={10} sm={9}>
        <TextInput label="commons.name" source="name" />
      </Grid>
      <Grid item xs={12} md={12}>
        <ListPermissions
          label="resource.groups.assigned-permissions"
          source="permissions"
        />
      </Grid>
      <Grid item xs={12}>
        <ChecksPermisions />
      </Grid>
    </Grid>
  );
};
