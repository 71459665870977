import PropTypes from "prop-types";
import React from "react";
import Dropzone from "react-dropzone";

import { useStyles } from "./styles";

function includes(fileArray, targetFile) {
  for (let file of fileArray) {
    if (
      file?.name === targetFile?.name &&
      file?.image?.size === targetFile?.size
    ) {
      return true;
    }
  }
  return false;
}

const FileInput = ({
  maxFiles,
  maxSize,
  files,
  onDrop,
  onError,
  disabled,
  acceptTypes,
  children,
}) => {
  const classes = useStyles();

  const handleDropOk = (droppedFiles) => {
    if (droppedFiles.length > 0) {
      const acceptedFiles = [];

      let acceptedAmount = files.length;
      for (let file of droppedFiles) {
        if (includes(files, file)) continue;

        if (acceptedAmount < maxFiles || maxFiles === -1) {
          acceptedFiles.push({
            name: file.name,
            preview: URL.createObjectURL(file),
            image: file,
          });
          acceptedAmount++;
        } else {
          onError(file.name, { maxFiles: true });
        }
      }

      onDrop(acceptedFiles);
    }
  };

  const handleDropRejected = (rejectedFiles) => {
    rejectedFiles.forEach((file) => {
      if (!acceptTypes.includes(file.type)) {
        onError(file.name, { invalidType: true });
      } else if (file.size > maxSize) {
        onError(file.name, { maxSize: true });
      } else {
        onError(file.name, { fileRejected: true });
      }
    });
  };

  return (
    <Dropzone
      onDrop={handleDropOk}
      onDropRejected={handleDropRejected}
      maxSize={maxSize}
      disabled={disabled}
      accept={acceptTypes}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={classes.container}>
          <div>
            <input {...getInputProps()} />
            {children}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

FileInput.propTypes = {
  acceptTypes: PropTypes.array,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  files: PropTypes.array,
  onDrop: PropTypes.func,
  onError: PropTypes.func,
};

FileInput.defaultProps = {
  acceptTypes: ["image/jpeg", "image/png"],
  maxSize: 8388608,
  maxFiles: 20,
  files: [],
  onDrop: () => {},
  onError: () => {},
};

export default FileInput;
