import {
  Button,
  Checkbox,
  ListItem as MuiListItem,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import React, { useState } from "react";

import LinkOrNot from "../LInkOrNot";
import { useLitItemStyles, useStyles } from "./styles";

const ListItem = ({
  hasBulkActions,
  linkType,
  basePath,
  id,
  data,
  content,
  actions,
  filterValues,
  selectedIds,
  onToggleItem,
}) => {
  const selected = selectedIds.includes(id);
  const classes = useStyles({ hasBulkActions, actions });
  const listItemClasses = useLitItemStyles({ selected });
  const [menuAnchor, setMenuAnchor] = useState(null);

  const isTrash = !!filterValues.trashed;

  return (
    <MuiListItem classes={listItemClasses}>
      <div className={classes.container}>
        {hasBulkActions && (
          <div className={classes.bulkActions}>
            <Checkbox
              color="primary"
              checked={selected}
              onChange={() => onToggleItem(id)}
            />
          </div>
        )}
        <div className={classes.content}>
          <LinkOrNot
            linkType={linkType}
            basePath={basePath}
            id={id}
            key={id}
            record={data[id]}
          >
            {content(data[id], id)}
          </LinkOrNot>
        </div>
        {actions && (
          <div className={classes.actions}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => setMenuAnchor(e.currentTarget)}
            >
              <MoreVertIcon />
            </Button>
          </div>
        )}
        {actions && (
          <Menu
            id="simple-menu"
            keepMounted
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}
          >
            {actions.map((action, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  action.onClick(data[id], isTrash);
                  setTimeout(() => {
                    setMenuAnchor(null);
                  }, 300);
                }}
              >
                {typeof action.label === "function"
                  ? action.label(data[id], isTrash)
                  : action.label}
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
    </MuiListItem>
  );
};

ListItem.propTypes = {
  hasBulkActions: PropTypes.bool,
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  basePath: PropTypes.string,
  id: PropTypes.number,
  data: PropTypes.object,
  content: PropTypes.func,
  actions: PropTypes.array,
  selectedIds: PropTypes.array,
  onToggleItem: PropTypes.func,
};

export default ListItem;
