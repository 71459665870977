import React from "react";
import Select from "@material-ui/core/Select";
import { useTranslate } from "react-admin";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { ITEMS_PER_PAGE } from "../../../../../config/constants";

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    borderRadius: "2px",
    border: `1px solid ${theme.palette.grey[400]}`,
    background: theme.palette.background.sidebar,
    color: theme.palette.text.primary,
    "& > select": {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
}));

const PerPageSelect = (props) => {
  const { text, value, setPerPage } = props;
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div>
      <span>{text || translate("commons.elements_per_page")}</span>
      <Select
        className={classes.selectRoot}
        native
        value={value}
        onChange={(e) => setPerPage(e.target.value)}
      >
        {ITEMS_PER_PAGE.map((value) => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default PerPageSelect;
