import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& > li": {
      padding: theme.spacing(),
      "&:nth-child(even)": {
        background: theme.palette.background.even,
      },
    },
    "&.MuiList-padding": {
      padding: 0,
    },
  },
}));
