import {
  Badge,
  Button,
  CircularProgress,
  IconButton,
  Popover,
} from "@material-ui/core";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import classnames from "classnames";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslate } from "react-admin";
import { Link } from "react-router-dom";
import { useDownloadStatusItemClasses, useStyles } from "./styles";
import { useDownloadsData, useDownloadsUpdate } from "./downloadsHooks";
import DownloadButton from "./DownloadButton";

const DownloadStatusItem = (props) => {
  const {
    statusData: { id, status, total, createdAt },
    onDownloadClick,
  } = props;
  const classes = useDownloadStatusItemClasses();
  const translate = useTranslate();

  return (
    <div className={classnames(classes.root)}>
      <div className={classes.content}>
        <div className={classes.infoRow}>
          <div className={classes.downloadTitle}>{`${translate(
            "resource.downloads.download_singular"
          )} ${id}`}</div>
          <div
            className={classnames(classes.downloadStatus, {
              [classes.textQueued]: status === "queued",
              [classes.textReady]: status === "ready",
            })}
          >
            {status === "queued" ? (
              <>
                <CircularProgress
                  size={14}
                  className={classes.circularProgressRoot}
                />
                <span>{translate("resource.gallery.compressing_zip")}</span>
              </>
            ) : (
              <span>{translate("resource.gallery.completed")}</span>
            )}
          </div>
        </div>
        <div className={classes.infoRow}>
          <div className={classes.downloadDate}>
            {format(new Date(createdAt), "dd/MM/yyyy HH:mm")}
          </div>
          <div className={classes.totalPhotos}>{total} fotos</div>
        </div>
      </div>
      {status === "ready" && (
        <DownloadButton
          record={props.statusData}
          iconButton
          onClick={onDownloadClick}
        />
      )}
    </div>
  );
};

DownloadStatusItem.propTypes = {
  statusData: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    total: PropTypes.number,
    createdAt: PropTypes.string,
    hash: PropTypes.string,
  }),
};

const DownloadNotificationButton = () => {
  const data = useDownloadsData();
  const update = useDownloadsUpdate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const downloadButtonRef = useRef();
  const classes = useStyles();
  const translate = useTranslate();

  const { totalCount, queuedCount, readyCount } = useMemo(() => {
    if (!data) return {};

    return {
      totalCount: data.queued.length + data.ready.length,
      queuedCount: data.queued.length,
      readyCount: data.ready.length,
    };
  }, [data]);

  useEffect(() => {
    if (!queuedCount) return;

    const interval = setInterval(() => {
      update();
    }, 5000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [queuedCount, update]);

  const getItems = useCallback(
    (status) => {
      if (data) {
        const downloadData = data[status];

        if (!downloadData || !downloadData.length) return false;

        return downloadData.map((itemData) => (
          <DownloadStatusItem
            key={itemData.id}
            statusData={itemData}
            onDownloadClick={() => setIsMenuOpen(false)}
          />
        ));
      }
    },
    [data]
  );

  if (!totalCount) return false;

  return (
    <>
      <IconButton
        ref={downloadButtonRef}
        className={classes.iconButton}
        onClick={() => setIsMenuOpen(true)}
      >
        <Badge max={9} badgeContent={readyCount} color="primary">
          <GetAppOutlinedIcon />
        </Badge>
      </IconButton>
      <Popover
        PaperProps={{ className: classes.menuRoot }}
        open={isMenuOpen}
        anchorEl={downloadButtonRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setIsMenuOpen(false)}
        disableScrollLock
      >
        <div className={classes.menuTitleBar}>
          <GetAppOutlinedIcon className={classes.titleIconAlign} />
          <span>{translate("resource.gallery.downloads")}</span>
        </div>
        {getItems("queued")}
        {getItems("ready")}
        <Link
          to={"/downloads"}
          component={ButtonWrap}
          color="primary"
          onClick={() => setIsMenuOpen(false)}
          fullWidth
        >
          {translate("resource.downloads.go_to_downloads")}
        </Link>
      </Popover>
    </>
  );
};

const ButtonWrap = ({ navigate, ...rest }) => <Button {...rest} />;

export default DownloadNotificationButton;
