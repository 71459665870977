import React from "react";

import Edit from "../../components/ra-components/Edit";
import Form from "./form";

export default (props) => (
  <Edit {...props}>
    <Form {...props} />
  </Edit>
);
