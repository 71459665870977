import {
  FormControl,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Typography,
} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import Downshift from "downshift";
import PropTypes from "prop-types";
import React, { useCallback, useRef, useState } from "react";
import { useTranslate } from "react-admin";

import { useStyles } from "./styles";

const AutoCompleteGroupInput = ({
  optionText,
  optionValue,
  searchResource,
  searchProperty,
  translateChoice,
  choices,
  selectedValues,
  onSelect,
  ...rest
}) => {
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);

  const classes = useStyles();
  const translate = useTranslate();

  const inputRef = useRef();

  let { disabled, label, name, source } = rest;
  name = name || source;
  label =
    (label && translate(label, { _: label })) ||
    (name && translate(name, { _: name }));

  const getChoiceText = useCallback(
    (item, groupOptionText = optionText) => {
      let text =
        typeof groupOptionText === "function"
          ? groupOptionText(item)
          : item[groupOptionText];

      if (translateChoice) {
        text = translate(text, { _: text });
      }

      return text;
    },
    [optionText, translate, translateChoice]
  );

  const getChoiceValue = useCallback(
    (item, groupOptionValue = optionValue) => {
      return item[groupOptionValue];
    },
    [optionValue]
  );

  const filterChoices = useCallback(
    (choices, groupOptionValue = optionValue, groupOptionText = optionText) => {
      return choices.filter((item) => {
        if (selectedValues.includes(getChoiceValue(item, groupOptionValue)))
          return false;
        if (!value) return true;

        const text = getChoiceText(item, groupOptionText);
        if (text && value) {
          return text.toLowerCase().includes(value.toLowerCase());
        }

        return false;
      });
    },
    [
      value,
      selectedValues,
      getChoiceValue,
      getChoiceText,
      optionText,
      optionValue,
    ]
  );

  let itemsIndex = [];
  const printChoices = useCallback(
    ({
      filteredChoices,
      getItemProps,
      groupOptionValue = optionValue,
      groupOptionText = optionText,
      groupIndex = 0,
      highlightedIndex,
    }) => {
      return filteredChoices.map((item, index) => {
        const label = getChoiceText(item, groupOptionText);
        const itemIndex = `${groupIndex}:${index}`;
        if (!itemsIndex.includes(itemIndex)) {
          itemsIndex.push(itemIndex);
        }
        return (
          <ListItem
            key={`${getChoiceText(item, groupOptionText)}-${getChoiceValue(
              item,
              groupOptionValue
            )}`}
            className={classes.item}
            title={label}
            {...getItemProps({
              index: itemIndex,
              item,
              style: {
                cursor: "pointer",
                backgroundColor:
                  highlightedIndex === itemIndex && "rgba(0, 0, 0, 0.08)",
              },
            })}
          >
            <Typography noWrap>{label}</Typography>
          </ListItem>
        );
      });
    },
    [
      classes.item,
      optionValue,
      optionText,
      getChoiceText,
      getChoiceValue,
      itemsIndex,
    ]
  );

  const printChoicesGroup = useCallback(
    ({ groupData, title, getItemProps, groupIndex, highlightedIndex }) => {
      const {
        optionValue: groupOptionValue = optionValue,
        optionText: groupOptionText = optionText,
        choices,
      } = groupData;
      let filteredChoices = filterChoices(
        choices,
        groupOptionValue,
        groupOptionText
      );
      if (filteredChoices.length > 0) {
        return [
          <ListSubheader style={{ color: "#757575" }} key={title}>
            {translate(title, { _: title })}
          </ListSubheader>,
          ...printChoices({
            filteredChoices,
            getItemProps,
            groupOptionValue,
            groupOptionText,
            groupIndex,
            highlightedIndex,
          }),
        ];
      } else {
        return [];
      }
    },
    [printChoices, translate, optionText, optionValue, filterChoices]
  );

  const getChoices = useCallback(
    ({ getItemProps, highlightedIndex }) => {
      if (Array.isArray(choices)) {
        let filteredChoices = filterChoices(choices);
        if (filteredChoices.length > 0) {
          return printChoices({
            filteredChoices,
            getItemProps,
            highlightedIndex,
          });
        }
      } else {
        let groupChoices = [];
        let groupIndex = 0;
        for (const [title, groupData] of Object.entries(choices)) {
          groupChoices.push(
            ...printChoicesGroup({
              groupData,
              title,
              getItemProps,
              groupIndex: groupIndex++,
              highlightedIndex,
            })
          );
        }

        if (groupChoices.length > 0) {
          return groupChoices;
        }
      }

      return (
        <ListItem className={classes.item}>
          {translate("commons.no_coincidences")}
        </ListItem>
      );
    },
    [
      choices,
      printChoices,
      printChoicesGroup,
      filterChoices,
      classes.item,
      translate,
    ]
  );

  const checkChoices = useCallback(() => {
    if (Array.isArray(choices)) {
      return choices.length > 0;
    } else {
      return Object.keys(choices).length > 0;
    }
  }, [choices]);

  const handleSelect = useCallback(
    (selectedItem) => {
      onSelect(getChoiceValue(selectedItem));
    },
    [onSelect, getChoiceValue]
  );

  const onInputValueChange = useCallback(
    (newValue) => {
      if (value !== newValue) {
        setValue(newValue);
      }
    },
    [value]
  );

  const onFocus = useCallback(() => {
    if (!focused) {
      setFocused(true);
    }
  }, [focused]);

  const onBlur = useCallback(() => {
    if (focused) {
      setFocused(false);
    }
  }, [focused]);

  const onKeyDown = useCallback(
    ({ e, highlightedIndex, setHighlightedIndex }) => {
      if (Array.isArray(itemsIndex) && itemsIndex.length > 0) {
        if (e.key === "ArrowDown") {
          let newHighlightedIndex = itemsIndex[0];

          if (highlightedIndex) {
            const oldIndex = itemsIndex.indexOf(highlightedIndex);
            const maxIndex = itemsIndex.length - 1;

            if (oldIndex + 1 <= maxIndex && itemsIndex[oldIndex + 1]) {
              newHighlightedIndex = itemsIndex[oldIndex + 1];
            }
          }

          setHighlightedIndex(newHighlightedIndex);
        } else if (e.key === "ArrowUp") {
          let newHighlightedIndex = itemsIndex[itemsIndex.length - 1];

          if (highlightedIndex) {
            const oldIndex = itemsIndex.indexOf(highlightedIndex);
            const minIndex = 0;

            if (oldIndex - 1 >= minIndex && itemsIndex[oldIndex - 1]) {
              newHighlightedIndex = itemsIndex[oldIndex - 1];
            }
          }

          setHighlightedIndex(newHighlightedIndex);
        }
      }

      if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
        if (value) {
          e.stopPropagation();
        }
      }
    },
    [itemsIndex, value]
  );

  return (
    <Downshift
      onChange={handleSelect}
      onInputValueChange={onInputValueChange}
      inputValue={value}
      itemToString={() => ""}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        setHighlightedIndex,
        getRootProps,
      }) => (
        <div className={classes.inputBox}>
          <div>
            <FormControl
              {...getRootProps(
                {
                  className: classes.inputWrapper,
                  disabled,
                },
                { suppressRefError: true }
              )}
            >
              {label && (
                <InputLabel htmlFor={name}>
                  {translate(label, { _: label })}
                </InputLabel>
              )}
              <TextField
                {...getInputProps({
                  label,
                  name,
                  disabled,
                  style: { width: "100%" },
                  inputRef,
                  onFocus,
                  onBlur,
                  onKeyDown: (e) =>
                    onKeyDown({ e, highlightedIndex, setHighlightedIndex }),
                  InputProps: {
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  },
                })}
              />
            </FormControl>
          </div>
          {(isOpen || focused) && checkChoices() && (
            <List className={classes.itemsList} {...getMenuProps()}>
              {getChoices({ getItemProps, highlightedIndex })}
            </List>
          )}
        </div>
      )}
    </Downshift>
  );
};

AutoCompleteGroupInput.defaultProps = {
  onSelect: () => {},
  onChange: () => {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
  value: "",
  choices: [],
  selectedValues: [],
};

AutoCompleteGroupInput.propTypes = {
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
  searchResource: PropTypes.string,
  searchProperty: PropTypes.string,
  translateChoice: PropTypes.bool,
  choices: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedValues: PropTypes.array,
};

export default AutoCompleteGroupInput;
