import makeStyles from "@material-ui/core/styles/makeStyles";

export const useLitItemStyles = makeStyles((theme) => ({
  root: {
    transition: "border 0.3s",
    borderLeft: ({ selected }) =>
      `${selected ? `${theme.spacing()}px` : "0px"} solid ${
        theme.palette.primary.main
      }`,
  },
}));

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
  },
  content: {
    flex: ({ hasBulkActions, actions }) => {
      if (!hasBulkActions && !actions) {
        return "1 1 100%";
      } else {
        if (hasBulkActions && actions) {
          return "0 1 calc(100% - 82px)";
        } else if (hasBulkActions) {
          return "0 1 calc(100% - 42px)";
        } else {
          return "0 1 calc(100% - 40px)";
        }
      }
    },
  },
  actions: {
    flex: "0 0 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > button": {
      minWidth: "unset",
    },
  },
  bulkActions: {
    flex: "0 0 42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
