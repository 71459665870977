import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import React, { useState, useCallback } from "react";
import { MenuItemLink, useLogout, useTranslate } from "react-admin";

import userMenuItems from "../../../../../../config/userMenu";
import { getUserConfig } from "../../../../../../utils/userConfig";
import Modal from "../../../../../custom/Modal";
import { useMenuItemStyles, useStyles } from "./styles";

const UserMenuMobile = () => {
  const menuItemClasses = useMenuItemStyles();
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const translate = useTranslate();
  const logout = useLogout();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const handleModalOpen = useCallback((content) => {
    setModalContent(content);
    setModalOpen(true);
  }, []);
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <div className={classes.container}>
      <Modal open={modalOpen} onClose={handleModalClose}>
        {modalContent &&
          React.cloneElement(modalContent, { onClose: handleModalClose })}
      </Modal>
      <div>
        <div
          className={classNames(classes.header, {
            [classes.headerOpen]: isOpen,
          })}
          onClick={() => setOpen(!isOpen)}
        >
          <Typography noWrap>{getUserConfig("email")}</Typography>
          <ExpandMore />
        </div>
        <Collapse in={isOpen}>
          {userMenuItems.map(({ path, primaryText, onClick, ...rest }) =>
            path ? (
              <MenuItemLink
                key={primaryText}
                to={path}
                primaryText={translate(primaryText)}
                classes={menuItemClasses}
                {...rest}
              />
            ) : (
              <MenuItem key={primaryText} classes={menuItemClasses}>
                <div
                  className={classes.menuItem}
                  onClick={() => onClick(handleModalOpen)}
                >
                  {translate(primaryText)}
                </div>
              </MenuItem>
            )
          )}
          <MenuItem classes={menuItemClasses}>
            <div
              className={classNames(classes.menuItem, classes.logout)}
              onClick={() => logout()}
            >
              {translate("userMenu.logout")}
            </div>
          </MenuItem>
        </Collapse>
      </div>
    </div>
  );
};

export default UserMenuMobile;
