import {
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import { Close, GetAppOutlined } from "@material-ui/icons";
import React, { useCallback } from "react";
import { useDataProvider, useNotify, useTranslate } from "react-admin";

import { BulkActionsMenuButton } from "../../../components/buttons";
import BottomPanel from "../../../components/custom/BottomPanel";
import { getImageResize } from "../../../utils/";
import { getUserConfig } from "../../../utils/userConfig";
import { useBulkActionsClasses } from "./styles";
import { useDownloadsUpdate } from "../../../components/custom/DownloadImages/downloadsHooks";

const BulkActions = (props) => {
  const { selectedIds, data, deselectAll, onToggleItem, selectAll } = props;
  const classes = useBulkActionsClasses();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const selectedCount = selectedIds.length;
  const notify = useNotify();
  const downloadOriginals = getUserConfig("downloadOriginal");
  const downloadAppPlayer = getUserConfig("downloadAppPlayer");
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const updateDownloads = useDownloadsUpdate();

  const handleDownload = useCallback(
    (type) => {
      const IRIArray = selectedIds.map((id) => data[id]["@id"]);
      dataProvider
        .create("zips", { data: { images: IRIArray, source: type } })
        .then((r) => {
          updateDownloads();
          deselectAll();
          notify("resource.gallery.notify_download_start", "info");
        });
    },
    [selectedIds, dataProvider, data, deselectAll, notify, updateDownloads]
  );

  const renderGroupButtons = () => (
    <ButtonGroup variant="contained" color="primary" size="small">
      {downloadOriginals && (
        <Button
          startIcon={<GetAppOutlined />}
          onClick={() => handleDownload("original")}
        >
          {translate("resource.gallery.download_originals")}
        </Button>
      )}
      <Button
        startIcon={<GetAppOutlined />}
        onClick={() => handleDownload("watermark")}
      >
        {translate("resource.gallery.download_reduced")}
      </Button>
      {downloadAppPlayer && (
        <Button
          startIcon={<GetAppOutlined />}
          onClick={() => handleDownload("app_player")}
        >
          App Player
        </Button>
      )}
    </ButtonGroup>
  );

  return (
    <>
      {isDesktop ? (
        <ButtonGroup variant="contained" color="primary" size="small">
          <Button onClick={selectAll} variant="contained" color="primary">
            {translate("commons.select_all")}
          </Button>
          <Button
            onClick={deselectAll}
            disabled={selectedIds.length === 0}
            color="primary"
          >
            {translate("commons.deselect_count", {
              count: selectedIds.length,
            })}
          </Button>
        </ButtonGroup>
      ) : (
        <BulkActionsMenuButton
          actions={[]}
          selectAll={selectAll}
          deselectAll={deselectAll}
        />
      )}
      <BottomPanel
        title={
          selectedCount
            ? translate("resource.gallery.selected_images", {
                smart_count: selectedCount,
              })
            : ""
        }
        hidden={!selectedCount}
        extraClassName={classes.extraClass}
        extraContent={renderGroupButtons()}
        extraShow={!!selectedCount}
        extraHeight={46}
      >
        <div className={classes.container}>
          <div className={classes.itemsRoot}>
            {selectedIds.map((id, index) => {
              const { name, publicImageResizes } = data[id];
              const url = getImageResize(publicImageResizes, "small");

              return (
                <React.Fragment key={id}>
                  <div className={classes.itemRoot}>
                    <div className={classes.imageContainer}>
                      <div className={classes.imageRatio}>
                        <img className={classes.image} src={url} alt="" />
                      </div>
                    </div>
                    <div className={classes.infoContainer}>
                      <Typography className={classes.imageTitle} noWrap>
                        {name}
                      </Typography>
                    </div>
                    <div className={classes.deselectItemContainer}>
                      <IconButton
                        size="small"
                        edge="end"
                        onClick={() => onToggleItem(id)}
                      >
                        <Close />
                      </IconButton>
                    </div>
                  </div>
                  {index !== selectedIds.length - 1 && (
                    <Divider variant="middle" />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <Paper className={classes.actionsRoot}>{renderGroupButtons()}</Paper>
      </BottomPanel>
    </>
  );
};

export default BulkActions;
