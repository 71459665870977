import { Paper, useMediaQuery } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "react-admin";

import Portal from "../Portal";
import { useStyles } from "./styles";

const BottomPanel = ({
  children,
  title,
  hidden,
  extraContent,
  extraClassName,
  extraShow,
  extraHeight,
  containerClassName,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ extraHeight });
  const isTabletOrDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const containerRef = useRef();
  const translate = useTranslate();

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    if (hidden && open) {
      setOpen(false);
    }
  }, [hidden, open]);

  useEffect(() => {
    if (isTabletOrDown && open) {
      disableBodyScroll(containerRef.current);
    } else {
      enableBodyScroll(containerRef.current);
    }
  }, [isTabletOrDown, open]);

  return (
    <Portal>
      <div
        onClick={() => setOpen(false)}
        className={classNames(classes.overlay, {
          [classes.overlayActive]: !!open,
        })}
      ></div>
      <Paper className={classes.container} ref={containerRef}>
        <div
          className={classNames(classes.bar, classes.topBar, {
            [classes.topBarHidden]: !!hidden,
            [classes.barOpen]: !!open,
          })}
          onClick={() => setOpen(!open)}
        >
          {title}
          <div className={classes.back}>
            {isMobile && open && translate("commons.back")}
            <ExpandLess />
          </div>
        </div>
        <Collapse in={!open && extraShow}>
          {extraContent && (
            <div className={classNames(extraClassName, classes.extra)}>
              {extraContent}
            </div>
          )}
        </Collapse>
        <Collapse in={open}>
          <div
            className={classNames(
              classes.collapsableContainer,
              containerClassName
            )}
          >
            {children}
          </div>
        </Collapse>
      </Paper>
    </Portal>
  );
};

BottomPanel.defaultProps = {
  extraShow: true,
  hidden: false,
  extraHeight: 35,
};

BottomPanel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hidden: PropTypes.bool,
  extraContent: PropTypes.node,
  extraShow: PropTypes.bool,
  extraHeight: PropTypes.number,
  containerClassName: PropTypes.string,
};

export default BottomPanel;
