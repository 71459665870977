import { makeStyles } from "@material-ui/core/styles";

export const useThemeSelectorStyles = makeStyles((theme) => ({
  root: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const useSwitchStyles = makeStyles((theme) => ({
  thumb: {
    background: theme.palette.common.white,
  },
  track: {
    background: theme.palette.primary.main,
    opacity: 1,
  },
  checked: {
    "&& + .MuiSwitch-track": {
      opacity: 1,
    },
  },
}));

export const useFormControlLabelStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
  label: {
    fontSize: "14px",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "12px",
    minWidth: "100px",
  },
}));
