export default (theme) => ({
  boxControls: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  previusItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: "1 1 auto",
    [theme.breakpoints.up("sm")]: {
      minWidth: "64px",
    },
  },
  nextItems: {
    display: "flex",
    alignItems: "center",
    flex: "1 1 auto",
    [theme.breakpoints.up("sm")]: {
      minWidth: "96px",
    },
  },
  pageItem: {
    display: "inline-block",
    minWidht: "10px",
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  inputPage: {
    borderRadius: "2px",
    border: `1px solid ${theme.palette.grey[400]}`,
    "& > input": {
      padding: theme.spacing(0.5),
      textAlign: "center",
    },
    background: theme.palette.background.sidebar,
    color: theme.palette.text.primary,
  },
  inputError: {
    borderRadius: "2px",
    border: `1px solid ${theme.palette.error.main}`,
    background: theme.palette.error.light,
  },
  btnPag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
    "&.disabled": {
      cursor: "default",
      opacity: 0.4,
    },
  },
});
