import React from "react";

import MediaCard from "../../../components/datagrid/datagridComponents/MediaCard";
import MediaGrid from "../../../components/datagrid/MediaGrid";
import CustomList from "../../../components/ra-components/List";
import { CURRENT_SEASON } from "../../../config/constants";
import { getUserConfig } from "../../../utils/userConfig";
import BulkActions from "./BulkActions";
import Filters from "./Filters";

export default (props) => (
  <CustomList
    filterDefaultValues={{
      season: getUserConfig("season_recent_id") || CURRENT_SEASON,
    }}
    filters={<Filters />}
    bulkActions={<BulkActions />}
    selectLimit={40}
    {...props}
  >
    <MediaGrid
      selectLimit={40}
      component={
        <MediaCard
          imageSource="publicImageResizes"
          imageSize="small"
          imageModalSize="large"
          titleSource="name"
          moreInfo
        />
      }
    />
  </CustomList>
);
