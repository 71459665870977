import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  downloadButtonHeader: {
    width: 145,
  },
}));

export const useMobileStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nameField: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  dateText: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
  downloadField: {
    marginLeft: theme.spacing(1),
  },
}));

export const useStatusFieldClasses = makeStyles((theme) => ({
  queued: {
    color: theme.palette.warning.main,
  },
  ready: {
    color: theme.palette.success.main,
  },
  downloaded: {
    color: theme.palette.success.main,
  },
  expired: {
    color: theme.palette.text.secondary,
  },
  error: {
    color: theme.palette.error.main,
  },
}));
